import { Divider, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material'
import ServiceWithReservationIcon from '@mui/icons-material/Event'
import ServiceIcon from '@mui/icons-material/ShoppingBasket'
import DeleteIcon from '@mui/icons-material/Delete'
import { AdditionalServiceListItemProps, DragItem } from './types'
import type { Identifier } from 'dnd-core'
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import { useRef } from 'react'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

function AdditionalServiceListItem({ serviceId, service, onChange, value }: AdditionalServiceListItemProps) {
  const dragRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'additionalServiceList',
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() }
    },
    hover(item: DragItem) {
      if (!dragRef.current) {
        return
      }

      const dragId = item.serviceId
      const hoverId = serviceId

      if (dragId === hoverId) {
        return
      }

      const firstIndex = value.findIndex((el) => el === dragId)
      const secondIndex = value.findIndex((el) => el === hoverId)
      const [removed] = value.splice(firstIndex, 1)
      value.splice(secondIndex, 0, removed)

      const items = value.map((v) => ({ v, order: 0 })).map((item, i) => ({ ...item, order: i }))

      onChange(items.sort((a, b) => a.order - b.order).map((item) => item.v))
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'additionalServiceList',
    item: () => ({ serviceId }),
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() })
  })

  preview(drop(dragRef))

  return (
    <>
      <div style={{ padding: '0.25rem 0', opacity: isDragging ? 0 : 1 }} ref={dragRef}>
        <ListItem>
          <div
            ref={drag}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '1rem' }}
          >
            <DragIndicatorIcon style={{ opacity: '0.2', cursor: 'move' }} data-handler-id={handlerId} />
          </div>
          {!!service && (
            <ListItemIcon>
              {service.withReservation ? (
                <ServiceWithReservationIcon fontSize="small" />
              ) : (
                <ServiceIcon fontSize="small" />
              )}
            </ListItemIcon>
          )}
          <ListItemText
            primaryTypographyProps={{ fontWeight: 500 }}
            primary={!service ? 'Услуга недоступна или была удалена' : service.name}
          />
          <ListItemSecondaryAction>
            <IconButton onClick={() => onChange(value.filter((id) => id !== serviceId))}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
      <Divider />
    </>
  )
}

export { AdditionalServiceListItem }
