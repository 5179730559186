import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { adminService } from 'shared/api'
import { AddressCreateModel, AddressModel } from 'shared/models'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { addressList } from 'pages/nav'
import { AddressEditForm } from './address-edit-form'

function AddressEdit() {
  const { addressId } = useParams<{ addressId?: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data, isFetching } = useQuery(
    ['address', addressId],
    ({ signal }) => adminService.address.getAddress(Number(addressId), { signal }),
    { enabled: !!addressId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { mutateAsync: createAddress, isLoading: isCreateLoading } = useMutation((data: AddressCreateModel) =>
    adminService.address.createAddress(data)
  )
  const { mutateAsync: updateAddress, isLoading: isUpdateLoading } = useMutation((data: AddressModel) =>
    adminService.address.updateAddress(data.id, data)
  )
  const { mutateAsync: deleteAddress, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.address.deleteAddress(id)
  )

  async function handleSubmit(data: AddressCreateModel | AddressModel) {
    try {
      if ('id' in data) {
        await updateAddress(data)
      } else {
        await createAddress(data)
      }

      dispatch(showMessage({ text: 'Адрес успешно сохранен', type: MessageTypes.Success }))
      navigate(addressList() + '?term=' + data.address)
    } catch (e) {
      console.log(e)
    }
  }

  async function handleDelete() {
    if (!data?.data) {
      return
    }

    try {
      await deleteAddress(data.data.id)

      dispatch(showMessage({ text: 'Адрес успешно удален', type: MessageTypes.Success }))
      navigate(addressList())
    } catch (e) {
      console.log(e)
    }
  }

  const isEdit = !!addressId

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, minHeight: 40 }}>
        <Typography variant="h6">{isEdit ? 'Редактирование адреса' : 'Создание адреса'}</Typography>
      </Box>
      {!!isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && (
        <AddressEditForm
          defaultData={data?.data}
          isEdit={isEdit}
          onSubmit={handleSubmit}
          isProcess={isCreateLoading || isUpdateLoading || isDeleteLoading}
          onDelete={handleDelete}
        />
      )}
    </Container>
  )
}

export { AddressEdit }
