import { Box, Button, Typography } from '@mui/material'
import { ServiceViewProps } from './types'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { adminService } from 'shared/api'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { useAppDispatch } from 'store'

function ServiceView({ data, onEdit, addressList }: ServiceViewProps) {
  const address = addressList?.find?.((address) => address.id === data.addressId)

  const dispatch = useAppDispatch()

  const [isTimeSlotGenerate, setIsTimeSlotGenerate] = useState(false)

  const { isLoading, mutateAsync: generateTimeslots } = useMutation(['generateTimeslots', data.id], () =>
    adminService.service.generateTimeslots(data.id)
  )

  function onGenerate() {
    setIsTimeSlotGenerate(true)
  }

  function cancelTimeSlotGenerage() {
    setIsTimeSlotGenerate(false)
  }

  async function onGenerateConfirm() {
    await generateTimeslots()
    setIsTimeSlotGenerate(false)
    dispatch(showMessage({ text: 'Генерация успешно запущена', type: MessageTypes.Success }))
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Box>
        <Typography variant="caption">Название</Typography>
        <Typography>{data.name}</Typography>
      </Box>
      {!!address && (
        <Box>
          <Typography variant="caption">Адрес</Typography>
          <Typography>{[address.city, address.address].filter(Boolean).join(', ')}</Typography>
        </Box>
      )}
      <Box>
        <Typography variant="caption">Подарочный сертификат</Typography>
        <Typography>{data.isGiftCertificate ? 'Да' : 'Нет'}</Typography>
      </Box>
      <Box>
        <Typography variant="caption">С возможностью бронирования</Typography>
        <Typography>{data.withReservation ? 'Да' : 'Нет'}</Typography>
      </Box>
      {!!data.withReservation && (
        <Box>
          <Typography variant="caption">Деактивировать таймслоты для записи с сайта</Typography>
          <Typography>{data.disableTimeSlotsForSiteReservation ? 'Да' : 'Нет'}</Typography>
        </Box>
      )}
      {!isTimeSlotGenerate && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem', flexWrap: 'wrap' }}>
          <Button type="submit" variant="outlined" onClick={onGenerate}>
            Перегенерировать таймслоты
          </Button>
          <Button type="submit" variant="outlined" onClick={onEdit}>
            Редактировать
          </Button>
        </Box>
      )}
      {isTimeSlotGenerate && (
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem', flexWrap: 'wrap' }}
        >
          <div>
            <Typography>Вы действительно хотите запустить пересчет временных интервалов?</Typography>
            <Typography variant="body2" style={{ opacity: 0.72 }}>
              Это может занять несколько минут.
            </Typography>
          </div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button onClick={cancelTimeSlotGenerage} disabled={isLoading}>
              Отмена
            </Button>
            <Button variant="outlined" color="error" disabled={isLoading} onClick={onGenerateConfirm}>
              Подтвердить
            </Button>
          </div>
        </Box>
      )}
    </Box>
  )
}

export { ServiceView }
