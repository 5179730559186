import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { OrderJournalDialogProps } from './types'
import { useQuery } from 'react-query'
import { adminService } from 'shared/api'
import { OrderJournalTableItem } from './order-journal-dialog-item'

function OrderJournalDialog({ open, onClose, orderId }: OrderJournalDialogProps) {
  const { data, isLoading } = useQuery(
    ['orderJournal', orderId],
    ({ signal }) => adminService.order.getOrderJournal(orderId, { signal }),
    { enabled: open }
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Журнал событий</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        {isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem' }}>
            <CircularProgress />
          </div>
        )}
        {data?.data && (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="left">Дата и время</TableCell>
                <TableCell align="left">Событие</TableCell>
                <TableCell align="left">Канал</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>{data.data.map?.((el) => <OrderJournalTableItem key={el.id} item={el} />)}</TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { OrderJournalDialog }
