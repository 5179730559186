import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { adminService } from 'shared/api'
import { ServiceEmailTemplate } from 'shared/models/email-templates'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidations } from './validation'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import { editorConfig, emailTypeNames } from './utils'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ClassicEditor } from 'ckeditor5'
import { ServiceEmailTemplatesEditProps } from './types'

function ServiceEmailTemplatesEdit({
  id,
  serviceId,
  availableTypes,
  onCancel,
  onSave,
  disabled,
  onDelete
}: ServiceEmailTemplatesEditProps) {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const { data, isFetching } = useQuery(
    ['emailTemplate', id],
    ({ signal }) => adminService.emailTemplates.getEmailTemplate(id as number, { signal }),
    { enabled: !!id, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { control, handleSubmit, reset, watch } = useForm<ServiceEmailTemplate | Omit<ServiceEmailTemplate, 'id'>>({
    defaultValues: { serviceId },
    disabled,
    resolver: yupResolver<ServiceEmailTemplate | Omit<ServiceEmailTemplate, 'id'>>(getValidations())
  })

  useEffect(() => {
    if (data?.data) {
      reset(data.data)
    }
  }, [data])

  function handleDelete() {
    setDeleteConfirmation(true)
  }

  function cancelDelete() {
    setDeleteConfirmation(false)
  }

  if (isFetching) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSave)}>
      {!!id && watch('type') && (
        <FormControl fullWidth variant="standard" margin="dense" style={{ marginBottom: '1rem' }}>
          <Typography variant="caption">Тип сообщения</Typography>
          <Typography variant="body1">{emailTypeNames[watch('type')]}</Typography>
        </FormControl>
      )}
      {!id && (
        <Controller
          control={control}
          name="type"
          render={({ field, fieldState }) => (
            <FormControl fullWidth variant="standard" margin="dense">
              <InputLabel shrink>Тип сообщения</InputLabel>
              <Select
                value={field.value || ''}
                onChange={(e) => {
                  field.onChange(e)
                }}
                disabled={disabled}
                error={!!fieldState.error}
                fullWidth
              >
                {availableTypes?.map?.((type) => (
                  <MenuItem key={type} value={type}>
                    {emailTypeNames[type]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      )}
      <Controller
        control={control}
        name="subject"
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            label="Тема письма"
            disabled={disabled}
            fullWidth
            autoComplete="off"
            placeholder="Тема письма"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            ref={field.ref}
            margin="none"
          />
        )}
      />
      <Controller
        control={control}
        name="body"
        defaultValue=""
        render={({ field, fieldState }) => (
          <FormControl fullWidth variant="standard" margin="dense" style={{ marginBottom: '1rem' }}>
            <Typography variant="caption">Тело сообщения</Typography>
            <CKEditor
              editor={ClassicEditor}
              disabled={disabled}
              onChange={(_, editor) => {
                const s = editor?.getData?.()
                field.onChange(s || '')
              }}
              data={field.value || ''}
              config={editorConfig}
            />
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          </FormControl>
        )}
      />
      {!deleteConfirmation && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', mt: 3 }}>
          {!!id && (
            <Button
              variant="text"
              type="button"
              color="error"
              disabled={disabled}
              disableElevation
              onClick={handleDelete}
            >
              Удалить
            </Button>
          )}
          <Button variant="text" type="button" disabled={disabled} disableElevation onClick={onCancel}>
            Отмена
          </Button>
          <Button type="submit" variant="outlined" disabled={disabled} disableElevation>
            Сохранить
          </Button>
        </Box>
      )}
      {!!id && deleteConfirmation && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1rem', mt: 3 }}>
          Вы действительно хотите удалить шаблон?
          <Button variant="text" type="button" onClick={cancelDelete} disabled={disabled}>
            Отмена
          </Button>
          <Button variant="outlined" type="button" color="error" disabled={disabled} onClick={() => onDelete(id)}>
            Удалить
          </Button>
        </Box>
      )}
    </form>
  )
}

export { ServiceEmailTemplatesEdit }
