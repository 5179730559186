import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Typography,
  Link as MuiLink,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Tooltip
} from '@mui/material'
import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { adminService } from 'shared/api'
import { orderStatusBackground, orderStatusColor, orderStatusText, paymentStatusText } from './status-utils'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import EditIcon from '@mui/icons-material/Edit'
import JournalIcon from '@mui/icons-material/EventNote'
import { pluralizationRu } from 'shared/utils/pluralization-ru'
import { useState } from 'react'
import { ReservationWrapper } from './styled'
import { OrderEditForm } from './order-edit-form'
import { EditOrderStatusDialog } from './edit-status-dialog'
import { EditStaffCommentDialog } from './edit-staff-comment-dialog'
import { GettingPaymentLinkDialog } from 'features/getting-payment-link-dialog'
import { OrderJournalDialog } from 'features/order-journal'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { useAppDispatch } from 'store'
import CopyIcon from '@mui/icons-material/FileCopy'
import DuplicateIcon from '@mui/icons-material/PersonAddAlt'
import { useMediaQuery } from 'react-responsive'
import { jounalInitiatorText } from 'features/order-journal/utils'

function OrderView() {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  const { orderId } = useParams<{ orderId: string }>()

  const [isDeleting, setIsDeleting] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [editStatusDialogOpen, setEditStatusDialogOpen] = useState(false)
  const [editStaffCommentDialogOpen, setEditStaffCommentDialogOpen] = useState(false)
  const [editOrderDialogOpen, setEditOrderDialogOpen] = useState(false)
  const [gettingPaymentLinkDialogOpen, setGettingPaymentLinkDialogOpen] = useState(false)
  const [journalIsOpened, setJournalIsOpened] = useState(false)
  const [orderCreateDialogOpen, setOrderCreateDialogOpen] = useState(false)

  const dispatch = useAppDispatch()
  const client = useQueryClient()

  const { data, isLoading, isFetching, refetch } = useQuery(['order', orderId], ({ signal }) =>
    adminService.order.getOrder(orderId || '', { signal })
  )

  function cancelDelete() {
    setIsDeleting(false)
  }

  function handleDelete() {
    setIsDeleting(true)
  }

  async function onDelete() {
    setIsDeleting(false)
    setProcessing(true)

    try {
      await adminService.order.cancelOrder(orderId || '')
      client.invalidateQueries(['order', orderId])
    } finally {
      setProcessing(false)
    }
  }

  function handleJournalOpen() {
    setJournalIsOpened(true)
  }

  function closeJournalDialog() {
    setJournalIsOpened(false)
  }

  function handleStatusChange() {
    setEditStatusDialogOpen(true)
  }

  function closeEditStatusDialog() {
    setEditStatusDialogOpen(false)
  }

  function handleStaffCommentChange() {
    setEditStaffCommentDialogOpen(true)
  }

  function closeEditStaffCommentDialog() {
    setEditStaffCommentDialogOpen(false)
  }

  function handleEditOrder() {
    setEditOrderDialogOpen(true)
  }

  function closeEditOrderDialog() {
    setEditOrderDialogOpen(false)
    client.invalidateQueries(['order', orderId])
  }

  function handlePaymentLinkClick() {
    setGettingPaymentLinkDialogOpen(true)
  }

  function closeGettingPaymentLinkDialog() {
    setGettingPaymentLinkDialogOpen(false)
  }

  function handleLinkCopy(paymentLink: string) {
    return () => {
      navigator.clipboard.writeText(paymentLink)
      dispatch(showMessage({ text: 'Ссылка скопирована в буфер обмена', type: MessageTypes.Success }))
    }
  }

  function handleCreate() {
    setOrderCreateDialogOpen(true)
  }

  function closeOrderCreateDialog() {
    setOrderCreateDialogOpen(false)
    refetch()
  }

  const isPrevOrder =
    data?.data.mainService.reservation?.startTime &&
    new Date(data?.data.mainService.reservation.startTime).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)

  return (
    <Container maxWidth="lg" style={{ padding: isMobile ? 0 : undefined }}>
      {!!isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && !!data?.data && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '0.5rem', justifyContent: 'space-between', mb: 3, minHeight: 40 }}>
            <Typography variant="h6">{`Заказ от ${Intl.DateTimeFormat('ru', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            }).format(data.data.dateTime)}`}</Typography>
            {!!data?.data.creationInitiaton && (
              <Chip label={jounalInitiatorText[data?.data.creationInitiaton]} variant='outlined' />
            )}
          </Box>
          <Card elevation={1}>
            <Box>
              <div style={{ display: 'flex', gap: '0 1rem', alignItems: 'center', flexWrap: 'wrap' }}>
                <div style={{ padding: '1rem', display: 'flex', gap: '1rem', alignItems: 'center', flex: 1 }}>
                  {!!data.data?.customer && (
                    <>
                      <div style={{ padding: '0.5rem', display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <PersonIcon />
                      </div>
                      <div>
                        <Typography variant="body1" fontWeight={500}>
                          {data.data.customer.name}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            gap: '0.25rem 1rem',
                            alignItems: 'center',
                            marginTop: '2px',
                            flexWrap: 'wrap'
                          }}
                        >
                          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                            <PhoneIcon fontSize="small" style={{ opacity: 0.72, width: '16px', height: '16px' }} />
                            <MuiLink style={{ fontSize: '13px' }} href={`tel:${data.data.customer?.phoneNumber}`}>
                              {data.data.customer.phoneNumber}
                            </MuiLink>
                          </div>
                          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                            <EmailIcon fontSize="small" style={{ opacity: 0.72, width: '16px', height: '16px' }} />
                            <MuiLink style={{ fontSize: '13px' }} href={`mailto:${data.data.customer?.email}`}>
                              {data.data.customer.email}
                            </MuiLink>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <Box display={'flex'} gap={'0.5rem'} style={{ padding: '1rem' }}>
                  <Tooltip title="Создать новый заказ для данного клиента">
                    <IconButton onClick={handleCreate}>
                      <DuplicateIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Журнал событий">
                    <IconButton onClick={handleJournalOpen}>
                      <JournalIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </div>
              <Divider />
              <div style={{ padding: '1rem', display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
                <Box sx={{ marginTop: '0.5rem' }}>
                  <Typography variant="caption">Комментарий к заказу</Typography>
                  <Typography style={{ opacity: data.data.comment ? 1 : 0.72 }}>
                    {data.data.comment || 'Не указан'}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">Статус</Typography>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    {!!data.data.status && (
                      <Chip
                        label={orderStatusText[data.data.status]}
                        size="small"
                        style={{
                          backgroundColor: orderStatusBackground[data.data.status],
                          color: orderStatusColor[data.data.status]
                        }}
                      />
                    )}
                    {data.data.status !== 'Cancelled' && (
                      <Tooltip title="Изменить статус заказа">
                        <IconButton size="small" onClick={handleStatusChange}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </Box>
                <Box>
                  <Typography variant="caption">Оплата</Typography>
                  <Typography>{paymentStatusText[data.data.paymentStatus] || 'Не запрашивалась'}</Typography>
                  {!!data.data.prePaymentAmount && (
                    <Typography variant="body2" color="#4e4e4e">
                      Внесено: <span style={{ color: '#5567f5' }}>{data.data.prePaymentAmount} ₽</span>
                    </Typography>
                  )}
                  <Typography
                    variant="caption"
                    color="#5567f5"
                    style={{ cursor: 'pointer' }}
                    onClick={handlePaymentLinkClick}
                  >
                    Получить ссылку на оплату
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">Комментарий сотрудника</Typography>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <Typography style={{ opacity: data.data.staffComment ? 1 : 0.72 }}>
                      {data.data.staffComment || 'Не указан'}
                    </Typography>
                    <Tooltip title="Изменить комментарий сотрудника к заказу">
                      <IconButton size="small" onClick={handleStaffCommentChange}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Box>
                {!!data.data.orderWebsiteLink && (
                  <Box>
                    <Typography variant="caption">Ссылка на заказ</Typography>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <Typography
                        component="a"
                        target="_blank"
                        href={data.data.orderWebsiteLink}
                        style={{ wordBreak: 'break-word' }}
                      >
                        {data.data.orderWebsiteLink}
                      </Typography>
                      <Tooltip title="Скопировать в буфер обмена">
                        <IconButton size="small" onClick={handleLinkCopy(data.data.orderWebsiteLink)}>
                          <CopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Box>
                )}
              </div>
              <Divider />
              <div style={{ padding: '1rem' }}>
                {data.data.mainService && (
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>{data.data.mainService.service.name}</div>
                      <div style={{ fontSize: '14px' }}>{data.data.mainService.price} ₽</div>
                    </div>
                    {!!data.data.mainService.reservation && (
                      <ReservationWrapper>
                        <Typography fontWeight={500}>Бронирование</Typography>
                        <div style={{ display: 'flex', gap: '0.5rem 2rem', flexWrap: 'wrap', marginTop: '0.5rem' }}>
                          <Box>
                            <Typography variant="caption">Дата</Typography>
                            <Typography>
                              {Intl.DateTimeFormat('ru', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(
                                data.data.mainService.reservation.startTime
                              )}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">Время</Typography>
                            <Typography>
                              {[
                                Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(
                                  data.data.mainService.reservation.startTime
                                ),
                                Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(
                                  data.data.mainService.reservation.endTime
                                )
                              ].join(' - ')}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">Кол-во участников</Typography>
                            <Typography>
                              {data.data.mainService.reservation.persons}{' '}
                              {pluralizationRu(data.data.mainService.reservation.persons || 1, [
                                'человек',
                                'человека',
                                'человек'
                              ])}
                            </Typography>
                          </Box>
                        </div>
                      </ReservationWrapper>
                    )}
                  </div>
                )}
                {data.data.additionalServices?.map((service) => (
                  <div key={service.id} style={{ display: 'flex', justifyContent: 'space-between', opacity: 0.64 }}>
                    <div>+ {service.service.name}</div>
                    <div style={{ fontSize: '14px' }}>{service.price} ₽</div>
                  </div>
                ))}
                <div style={{ marginTop: '1rem', fontSize: '18px', display: 'flex', justifyContent: 'flex-end' }}>
                  ИТОГО: {data.data.total} ₽
                </div>
              </div>
            </Box>
          </Card>
          {!isDeleting && data.data.status !== 'Cancelled' && !isPrevOrder && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: '1rem' }}>
              <Button
                type="submit"
                variant="outlined"
                color="error"
                onClick={handleDelete}
                disabled={processing || isFetching}
              >
                Отменить заказ
              </Button>
              <Button type="submit" variant="outlined" disabled={processing || isFetching} onClick={handleEditOrder}>
                Редактировать
              </Button>
            </Box>
          )}
          {!!isDeleting && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 3, gap: '1rem' }}>
              Вы действительно хотите отменить заказ?
              <Button variant="outlined" onClick={cancelDelete} disabled={processing || isFetching}>
                Отмена
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="error"
                disabled={processing || isFetching}
                onClick={onDelete}
              >
                Подтвердить
              </Button>
            </Box>
          )}
          <EditOrderStatusDialog
            orderId={data.data.id}
            initialStatus={data?.data.status}
            open={editStatusDialogOpen}
            onClose={closeEditStatusDialog}
          />
          <EditStaffCommentDialog
            orderId={data.data.id}
            initialStaffComment={data?.data.staffComment}
            open={editStaffCommentDialogOpen}
            onClose={closeEditStaffCommentDialog}
          />
          <Dialog open={editOrderDialogOpen} onClose={closeEditOrderDialog} fullWidth>
            <DialogTitle>Редактирование заказа</DialogTitle>
            <OrderEditForm orderId={orderId as string} onClose={closeEditOrderDialog} />
          </Dialog>
          <GettingPaymentLinkDialog
            open={gettingPaymentLinkDialogOpen}
            orderId={data.data.id}
            onClose={closeGettingPaymentLinkDialog}
          />
          <OrderJournalDialog open={journalIsOpened} orderId={data.data.id} onClose={closeJournalDialog} />
        </>
      )}
      <Dialog open={orderCreateDialogOpen} onClose={closeOrderCreateDialog} fullWidth>
        <DialogTitle>Создание заказа</DialogTitle>
        <OrderEditForm
          onClose={closeOrderCreateDialog}
          defaultValues={{
            name: data?.data.customer.name,
            phoneNumber: data?.data.customer.phoneNumber,
            email: data?.data.customer.email
          }}
        />
      </Dialog>
    </Container>
  )
}

export { OrderView }
