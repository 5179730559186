import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { pageList } from 'pages/nav'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { adminService } from 'shared/api'
import { PageCreateModel, PageModel } from 'shared/models'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { PageEditForm } from './page-edit-form'

function PageEdit() {
  const { pageId } = useParams<{ pageId?: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data, isFetching } = useQuery(
    ['page', pageId],
    ({ signal }) => adminService.page.getPage(Number(pageId), { signal }),
    { enabled: !!pageId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { data: serviceListData } = useQuery(['services'], ({ signal }) =>
    adminService.service.getServiceList({ signal })
  )

  const { mutateAsync: createPage, isLoading: isCreateLoading } = useMutation((data: PageCreateModel) =>
    adminService.page.createPage(data)
  )
  const { mutateAsync: updatePage, isLoading: isUpdateLoading } = useMutation((data: PageModel) =>
    adminService.page.updatePage(data.id, data)
  )
  const { mutateAsync: deletePage, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.page.deletePage(id)
  )

  async function handleSubmit(data: PageCreateModel | PageModel) {
    try {
      if ('id' in data && data.id) {
        await updatePage(data)
      } else {
        await createPage(data)
      }

      dispatch(showMessage({ text: 'Страница успешно сохранена', type: MessageTypes.Success }))
      navigate(pageList() + '?term=' + data.name)
    } catch (e) {
      console.log(e)
    }
  }

  async function handleDelete() {
    if (!data?.data) {
      return
    }

    try {
      await deletePage(data.data.id)

      dispatch(showMessage({ text: 'Квест успешно удален', type: MessageTypes.Success }))
      navigate(pageList())
    } catch (e) {
      console.log(e)
    }
  }

  const isEdit = !!pageId

  return (
    <Container maxWidth="lg">
      {!isFetching && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, minHeight: 40 }}>
          <Typography variant="h6">{isEdit ? `Редактирование страницы` : 'Создание страницы'}</Typography>
        </Box>
      )}
      {!!isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && (
        <PageEditForm
          defaultData={data?.data}
          isEdit={isEdit}
          onSubmit={handleSubmit}
          isProcess={isCreateLoading || isUpdateLoading || isDeleteLoading}
          onDelete={handleDelete}
          serviceList={serviceListData?.data || []}
        />
      )}
    </Container>
  )
}

export { PageEdit }
