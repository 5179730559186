import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material'
import { useQuery } from 'react-query'
import { adminService } from 'shared/api'
import EditIcon from '@mui/icons-material/Edit'
import CreateIcon from '@mui/icons-material/Add'
import { Link, useSearchParams } from 'react-router-dom'
import { questCategoryCreate, questCategoryEdit } from 'pages/nav'
import { useEffect, useState } from 'react'
import { SearchInput } from 'features/search-input'

function QuestCategoryList() {
  const { data, isLoading } = useQuery(['questCategories'], ({ signal }) =>
    adminService.caterory.getCategoryList({ signal })
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const [term, setTerm] = useState(searchParams.get('term') || '')

  useEffect(() => {
    const search = new URLSearchParams(searchParams)
    if (term) {
      search.set('term', term)
    } else {
      search.delete('term')
    }
    setSearchParams(search)
  }, [term])

  function handleTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTerm(e.target.value)
  }

  const filteredData = data?.data?.filter?.((category) => category.name.toLowerCase().includes(term.toLowerCase()))

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6">Список категорий</Typography>
        <Tooltip title="Добавить новую категорию">
          <Link to={questCategoryCreate()}>
            <IconButton>
              <CreateIcon />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
      <Box sx={{ margin: '0 0.5rem 1rem' }}>
        <SearchInput value={term} onChange={handleTermChange} onClear={() => setTerm('')} />
      </Box>
      <List>
        {!!isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading &&
          filteredData?.map?.((category) => (
            <ListItem key={category.id}>
              <ListItemText primary={category.name} />
              <ListItemSecondaryAction>
                <Tooltip title="Редактировать категорию">
                  <Link to={questCategoryEdit(String(category.id))}>
                    <IconButton>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </Container>
  )
}

export { QuestCategoryList }
