import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import { GettingPaymentLinkDialogProps } from './types'
import CopyIcon from '@mui/icons-material/FileCopy'
import { PaymentLinkWrapper } from './styled'
import { adminService } from 'shared/api'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'

function GettingPaymentLinkDialog(props: GettingPaymentLinkDialogProps) {
  const dispatch = useAppDispatch()

  const [processing, setProcessing] = useState(false)
  const [paymentLink, setPaymentLink] = useState<string>('')

  const [prePaymentAmount, setPrePaymentAmount] = useState<number>()

  useEffect(() => {
    setPaymentLink('')
    setProcessing(false)
    setPrePaymentAmount(undefined)
  }, [props.open])

  async function handleCreatePaymentLink() {
    if (!prePaymentAmount) {
      return
    }

    setProcessing(true)

    try {
      const data = await adminService.order.getPaymentLink({ orderId: props.orderId, amount: prePaymentAmount })
      if (data.data.paymentLink) {
        setPaymentLink(data.data.paymentLink)
      }
    } finally {
      setProcessing(false)
    }
  }

  function handleLinkCopy(paymentLink: string) {
    return () => {
      navigator.clipboard.writeText(paymentLink)
      dispatch(showMessage({ text: 'Ссылка скопирована в буфер обмена', type: MessageTypes.Success }))
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Получение ссылки на оплату заказа</DialogTitle>
      <DialogContent>
        <TextField
          label="Сумма оплаты"
          fullWidth
          autoComplete="off"
          placeholder="Сумма оплаты"
          variant="standard"
          InputLabelProps={{ shrink: true }}
          style={{ flex: 1, minWidth: '130px' }}
          value={prePaymentAmount === 0 ? prePaymentAmount : prePaymentAmount || ''}
          type="number"
          InputProps={{ startAdornment: <InputAdornment position="start">₽</InputAdornment> }}
          onChange={(ev) => {
            const minimumPrePaymentAmount = ev.target.value !== '' ? Number(ev.target.value) : undefined

            if (
              (typeof minimumPrePaymentAmount === 'number' && minimumPrePaymentAmount >= 0) ||
              minimumPrePaymentAmount === undefined
            ) {
              setPrePaymentAmount(minimumPrePaymentAmount)
            }
          }}
          disabled={processing || !!paymentLink}
          margin="dense"
        />
        <Button
          type="submit"
          variant="outlined"
          disabled={processing || !prePaymentAmount || !!paymentLink}
          fullWidth
          style={{ marginTop: '1rem' }}
          onClick={handleCreatePaymentLink}
        >
          Получить ссылку на оплату
        </Button>
        {!!paymentLink && (
          <PaymentLinkWrapper>
            <Typography
              component="a"
              target="_blank"
              href={paymentLink}
              style={{ marginTop: '1rem', wordBreak: 'break-word' }}
            >
              {paymentLink}
            </Typography>
            <Tooltip title="Скопировать в буфер обмена">
              <IconButton size="small" onClick={handleLinkCopy(paymentLink)}>
                <CopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </PaymentLinkWrapper>
        )}
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="outlined" disabled={processing} onClick={props.onClose}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { GettingPaymentLinkDialog }
