import { memo } from 'react'
import { CalendarHeader, CalendarHeaderContainer, CalendarLeftHeader } from '../styled'

function HeaderWeekDaysComponent(props: { dayCount: number; days: number[] }) {


  return (
    <CalendarHeaderContainer>
      <CalendarLeftHeader />
      <CalendarHeader style={{ gridTemplateColumns: `repeat(${props.dayCount}, minmax(50px, 1fr))` }}>
        {props.days?.map((weekday) => (
          <div key={weekday}>
            {Intl.DateTimeFormat('ru', { weekday: 'short', day: '2-digit', timeZone: 'UTC' }).format(weekday)}
          </div>
        ))}
      </CalendarHeader>
    </CalendarHeaderContainer>
  )
}

export const HeaderWeekDays = memo(HeaderWeekDaysComponent)
