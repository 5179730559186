import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import { Fragment, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { adminService } from 'shared/api'
import { EmailTemplateType, ServiceEmailTemplate } from 'shared/models/email-templates'
import { emailTypeColors, emailTypeNames } from './utils'
import { ServiceEmailTemplatesEdit } from './service-email-templates-edit'

function ServiceEmailTemplates({ serviceId }: { serviceId: number }) {
  const [editMode, setEditMode] = useState(false)
  const [templateId, setTemplateId] = useState<number>()

  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(
    ['emailTemplates', serviceId],
    ({ signal }) => adminService.emailTemplates.getEmailTemplates({ serviceId }, { signal }),
    { refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { mutateAsync: createTemplate, isLoading: isCreateLoading } = useMutation(
    (data: Omit<ServiceEmailTemplate, 'id'>) => adminService.emailTemplates.createEmailTemplate(data)
  )
  const { mutateAsync: updateTemplate, isLoading: isUpdateLoading } = useMutation((data: ServiceEmailTemplate) =>
    adminService.emailTemplates.updateEmailTemplate(data)
  )
  const { mutateAsync: deleteTemplate, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.emailTemplates.deleteEmailTemplate(id)
  )

  async function handleSave(data: ServiceEmailTemplate | Omit<ServiceEmailTemplate, 'id'>) {
    if ('id' in data && data.id) {
      await updateTemplate(data)
      queryClient.invalidateQueries(['emailTemplates', serviceId])
      setEditMode(false)
    } else {
      await createTemplate(data)
      queryClient.invalidateQueries(['emailTemplates', serviceId])
      setEditMode(false)
    }
  }

  function handleCancel() {
    setEditMode(false)
    queryClient.invalidateQueries(['emailTemplates', serviceId])
  }

  async function handleDelete(id: number) {
    await deleteTemplate(id)
    queryClient.invalidateQueries(['emailTemplates', serviceId])
    setEditMode(false)
  }

  const availableTypes = Object.values(EmailTemplateType).filter((t) => !(data?.data || []).find((el) => el.type === t))

  return (
    <>
      {!!isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
          <CircularProgress />
        </Box>
      )}
      {!editMode && !isLoading && (
        <>
          <List disablePadding>
            {data?.data?.map?.((template) => (
              <Fragment key={template.id}>
                <div style={{ marginBottom: '1rem' }}>
                  <ListItem>
                    <ListItemText
                      primaryTypographyProps={{ fontWeight: 500 }}
                      primary={template.subject}
                      secondaryTypographyProps={{ variant: 'body2', variantMapping: { body2: 'div' } }}
                      secondary={
                        <Typography variant="body2" color={emailTypeColors[template.type]}>
                          {emailTypeNames[template.type]}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          setTemplateId(template.id)
                          setEditMode(true)
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
                <Divider />
              </Fragment>
            ))}
            {availableTypes.length > 0 && (
              <Box style={{ display: 'flex', justifyContent: 'center', margin: '0.5rem 1rem' }}>
                <IconButton
                  onClick={() => {
                    setTemplateId(undefined)
                    setEditMode(true)
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            )}
          </List>
        </>
      )}
      {editMode && (
        <Box sx={{ padding: '1rem' }}>
          <ServiceEmailTemplatesEdit
            id={templateId}
            serviceId={serviceId}
            availableTypes={availableTypes}
            onCancel={handleCancel}
            disabled={isCreateLoading || isUpdateLoading || isDeleteLoading}
            onSave={handleSave}
            onDelete={handleDelete}
          />
        </Box>
      )}
    </>
  )
}

export { ServiceEmailTemplates }
