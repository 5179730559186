import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import Router from 'pages/router'
import { store } from 'store'
import { darkTheme, lightTheme } from './theme'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from './providers/theme-provider'
import { SnackbarMaterial } from 'features/notifer/snackbar-material'
import { PickersUtilsProvider } from './providers/pickers-utils-provider'
import './globals.css'

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false, refetchOnReconnect: false } }
})

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider darkTheme={darkTheme} lightTheme={lightTheme}>
        <PickersUtilsProvider>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <Router />
            <SnackbarMaterial />
          </QueryClientProvider>
        </PickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
