import { memo } from 'react'
import { CalendarLeftColumn, CalendarLeftEl } from '../styled'
import { CellTimeSlot } from '../types'

function LeftBarTimesComponent(props: {
  timeSlots: { date: number; dayTimes: CellTimeSlot[] }[]
  timeSlotHeight: number
}) {
  return (
    <CalendarLeftColumn style={{ gridAutoRows: `${props.timeSlotHeight}px` }}>
      {props.timeSlots[0]?.dayTimes?.map((el, index) => (
        <CalendarLeftEl key={index}>
          {Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(el.startTime)}
        </CalendarLeftEl>
      ))}
    </CalendarLeftColumn>
  )
}

export const LeftBarTimes = memo(LeftBarTimesComponent)
