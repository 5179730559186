import { validatePhone } from 'features/intl-phone-input'
import { AddressCreateModel, AddressModel } from 'shared/models'
import { isEmail } from 'shared/utils/string-test'
import * as Yup from 'yup'

function getValidations() {
  return Yup.object().shape({
    address: Yup.string().required('Поле обязательно для заполнения'),
    city: Yup.string().required('Поле обязательно для заполнения'),
    latitude: Yup.string()
      .required('Поле обязательно для заполнения')
      .matches(
        /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,9})?))$/,
        'Неверный формат координаты'
      ),
    longitude: Yup.string()
      .required('Поле обязательно для заполнения')
      .matches(
        /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,9})?))$/,
        'Неверный формат координаты'
      ),
    phone: Yup.string()
      .required('Поле обязательно для заполнения')
      .test({
        test: (value) => validatePhone(value),
        message: 'Неверный формат номера телефона'
      }),
    email: Yup.string().nullable().test({
      test: (value) => !value || isEmail(value || ''),
      message: 'Некорректный email'
    })
  }) as Yup.ObjectSchema<NonNullable<AddressModel | AddressCreateModel>, Yup.AnyObject>
}

export { getValidations }
