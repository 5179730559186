import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { adminService } from 'shared/api'
import { QuestCreateModel, QuestModel } from 'shared/models'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { questList } from 'pages/nav'
import { QuestEditForm } from './quest-edit-form'

function QuestEdit() {
  const { questId } = useParams<{ questId?: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data, isFetching } = useQuery(
    ['quest', questId],
    ({ signal }) => adminService.quest.getQuest(Number(questId), { signal }),
    { enabled: !!questId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { data: addressData, isLoading: addressIsLoading } = useQuery(['addressList'], ({ signal }) =>
    adminService.address.getAddressList({ signal })
  )

  const { data: categoriesData, isLoading: categoriesIsLoading } = useQuery(['categories'], ({ signal }) =>
    adminService.caterory.getCategoryList({ signal })
  )

  const { data: setviceListData } = useQuery(['services'], ({ signal }) =>
    adminService.service.getServiceList({ signal })
  )

  const { mutateAsync: createQuest, isLoading: isCreateLoading } = useMutation((data: QuestCreateModel) =>
    adminService.quest.createQuest(data)
  )
  const { mutateAsync: updateQuest, isLoading: isUpdateLoading } = useMutation((data: QuestModel) =>
    adminService.quest.updateQuest(data.id, data)
  )
  const { mutateAsync: deleteQuest, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.quest.deleteQuest(id)
  )

  async function handleSubmit(data: QuestCreateModel | QuestModel) {
    try {
      if ('id' in data && data.id) {
        await updateQuest(data)
      } else {
        await createQuest(data)
      }

      dispatch(showMessage({ text: 'Квест успешно сохранен', type: MessageTypes.Success }))
      navigate(questList() + '?term=' + data.name)
    } catch (e) {
      console.log(e)
    }
  }

  async function handleDelete() {
    if (!data?.data) {
      return
    }

    try {
      await deleteQuest(data.data.id)

      dispatch(showMessage({ text: 'Квест успешно удален', type: MessageTypes.Success }))
      navigate(questList())
    } catch (e) {
      console.log(e)
    }
  }

  const isEdit = !!questId

  return (
    <Container maxWidth="lg">
      {!isFetching && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, minHeight: 40 }}>
          <Typography variant="h6">
            {isEdit ? `Редактирование квеста "${data?.data.name}"` : 'Создание квеста'}
          </Typography>
        </Box>
      )}
      {(!!isFetching || !!addressIsLoading || !!categoriesIsLoading) && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && !addressIsLoading && !categoriesIsLoading && (
        <QuestEditForm
          defaultData={data?.data}
          isEdit={isEdit}
          onSubmit={handleSubmit}
          isProcess={isCreateLoading || isUpdateLoading || isDeleteLoading}
          addressList={addressData?.data || []}
          categories={categoriesData?.data || []}
          serviceList={setviceListData?.data || []}
          onDelete={handleDelete}
        />
      )}
    </Container>
  )
}

export { QuestEdit }
