import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState } from 'react'
import { ModeProvider } from './mode-context'
import { ThemeMode, ThemeProviderProps } from './types'

const storageKey = 'theme-mode'

function ThemeProvider({ children, lightTheme, darkTheme }: ThemeProviderProps) {
  const [mode, setMode] = useState<ThemeMode>((localStorage.getItem(storageKey) as ThemeMode) || 'system')

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  function handleModeChange(mode: ThemeMode) {
    localStorage.setItem(storageKey, mode)
    setMode(mode)
  }

  const theme =
    mode === 'system' ? (prefersDarkMode ? darkTheme : lightTheme) : mode === 'dark' ? darkTheme : lightTheme

  return (
    <ModeProvider value={{ mode, setMode: handleModeChange }}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ModeProvider>
  )
}

export { ThemeProvider }
