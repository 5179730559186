import { Menu } from '@mui/material'
import { ReactNode, memo } from 'react'
import { ColumnItem, SchedulePosition } from '../types'

function MoreAppointmentsComponent(props: {
  openPopover?: { target: HTMLElement; data: ColumnItem[] }
  onClose: () => void
  onPositionClick: (position: SchedulePosition) => void
  otherAppointmentRender: (position: SchedulePosition) => ReactNode
}) {
  function handlePositionClick(position: SchedulePosition) {
    return () => {
      props.onPositionClick(position)
    }
  }

  return (
    <Menu
      anchorEl={props.openPopover?.target}
      MenuListProps={{ disablePadding: true }}
      open={!!props.openPopover}
      onClose={props.onClose}
    >
      {props.openPopover?.data?.map((item) => (
        <div key={item.position.id} onClick={handlePositionClick(item.position)}>
          {props.otherAppointmentRender(item.position)}
        </div>
      ))}
    </Menu>
  )
}

export const MoreAppointment = memo(MoreAppointmentsComponent)
