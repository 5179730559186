import { Controller, useForm } from 'react-hook-form'
import { ScheduleEditFormProps, ScheduleFormData } from './types'
import { Box, Button, DialogActions, DialogContent, FormControlLabel, Switch, TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidations } from './validation'
import { WeekdaysScheduler } from 'features/weekdays-scheduler'
import { useState } from 'react'

function ScheduleEditForm({ defaultData, onSubmit, isProcess, serviceId, isEdit, onDelete }: ScheduleEditFormProps) {
  const [isDeleting, setIsDeleting] = useState(false)

  const { control, handleSubmit, watch } = useForm<ScheduleFormData>({
    defaultValues: {
      id: defaultData?.id,
      name: defaultData?.name || '',
      duration: defaultData?.duration,
      reservationIsAvailable: defaultData?.reservationIsAvailable ?? true,
      schedule: (defaultData?.schedule || []).map((el) => ({
        startTime: el.startTime,
        endTime: el.endTime,
        isSpecificDate: el.date ? true : false,
        date: el.date,
        weekdays: el.weekdays
      }))
    },
    disabled: isProcess,
    resolver: yupResolver<ScheduleFormData>(getValidations())
  })

  function handleDelete() {
    setIsDeleting(true)
  }

  function cancelDelete() {
    setIsDeleting(false)
  }

  function handleSaveForm(data: ScheduleFormData) {
    onSubmit({
      id: data.id,
      name: data.name,
      duration: data.duration,
      reservationIsAvailable: data.reservationIsAvailable,
      serviceId,
      schedule: data.schedule
        .map((s) => ({
          date: s.isSpecificDate ? s.date : null,
          weekdays: s.isSpecificDate ? [] : s.weekdays,
          endTime: s.endTime,
          startTime: s.startTime
        }))
        .filter((s) => !!s.date || !!s.weekdays.length)
    })
  }

  return (
    <>
      <DialogContent style={{ padding: 0 }}>
        <form onSubmit={handleSubmit(handleSaveForm)}>
          <DialogContent>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Название"
                  fullWidth
                  autoComplete="off"
                  placeholder="Название"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="none"
                />
              )}
            />
            {watch('reservationIsAvailable') && (
              <Controller
                control={control}
                name="duration"
                defaultValue={0}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Длительность услуги (мин.)"
                    fullWidth
                    autoComplete="off"
                    placeholder="Длительность услуги"
                    variant="standard"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    value={field.value || ''}
                    onChange={(ev) => field.onChange(ev.target.value ? Number(ev.target.value) : null)}
                    onBlur={field.onBlur}
                    disabled={isProcess}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    ref={field.ref}
                    margin="dense"
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="reservationIsAvailable"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  style={{ margin: '1.5rem 0 1rem', display: 'flex', justifyContent: 'space-between' }}
                  control={
                    <Switch
                      checked={field.value}
                      onChange={(_, val) => field.onChange(val)}
                      onBlur={field.onBlur}
                      disabled={isProcess}
                      size="small"
                    />
                  }
                  label="Бронирование доступно"
                  labelPlacement="start"
                />
              )}
            />
          </DialogContent>
          <Controller
            control={control}
            name="schedule"
            defaultValue={[]}
            render={({ field }) => <WeekdaysScheduler onChange={field.onChange} value={field.value} />}
          />
        </form>
      </DialogContent>
      <DialogActions>
        {!isDeleting && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
            {isEdit && (
              <Button onClick={handleDelete} disabled={isProcess}>
                Удалить
              </Button>
            )}
            <Button type="submit" variant="outlined" disabled={isProcess} onClick={handleSubmit(handleSaveForm)}>
              Сохранить
            </Button>
          </Box>
        )}
        {isDeleting && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1rem' }}>
            Вы действительно хотите удалить расписание?
            <Button onClick={cancelDelete} disabled={isProcess}>
              Отмена
            </Button>
            <Button variant="outlined" color="error" disabled={isProcess} onClick={onDelete}>
              Удалить
            </Button>
          </Box>
        )}
      </DialogActions>
    </>
  )
}

export { ScheduleEditForm }
