import { CustomerModel } from 'shared/models'

const fakeCustomers: CustomerModel[] = [
  { id: '1', name: 'Александр', email: 'admin@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '2', name: 'Павел', email: 'pavel.golub@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '3', name: 'Сергей', email: 'office1@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '4', name: 'Иван', email: 'office2@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '5', name: 'Александр', email: 'office3@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '6', name: 'Ольга', email: 'office4@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '7', name: 'Александр', email: 'office5@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '8', name: 'Семен', email: 'office6@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '9', name: 'Артур', email: 'office7@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '10', name: 'Анастасия', email: 'office8@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '11', name: 'Дарья', email: 'admin@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '12', name: 'Александр', email: 'admin@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '13', name: 'Семен', email: 'admin@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '14', name: 'Артур', email: 'admin@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '15', name: 'Григорий', email: 'admin@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '16', name: 'Анна', email: 'admin@insidequest.ru', phoneNumber: '+79007753434' },
  { id: '17', name: 'Настя', email: 'admin@insidequest.ru', phoneNumber: '+79007753434' }
]

export { fakeCustomers }
