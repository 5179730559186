import { http, HttpResponse, delay } from 'msw'
import { fakeUserProfile, fakeUsers } from '../fake-data/fake-users'
import { UserModel } from 'shared/models'

export const user = [
  http.post('/api/auth/login', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.post('/api/auth/logout', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.get('/api/auth/getCurrentUserProfile', async () => {
    await delay(600)

    return HttpResponse.json(fakeUserProfile)
  }),

  http.get('/api/users', async () => {
    await delay(900)

    return HttpResponse.json(fakeUsers)
  }),

  http.get('/api/users/:id', async ({ params }) => {
    await delay(300)

    const { id } = params

    const foundUser: UserModel | undefined = id ? fakeUsers.find((user) => user.id === Number(id)) : undefined

    if (!foundUser) {
      return HttpResponse.json(undefined, { status: 404 })
    } else {
      return HttpResponse.json(foundUser)
    }
  }),

  http.post('/api/users', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.put('/api/users/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.delete('/api/users/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  })
]
