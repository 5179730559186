import { Controller, useForm } from 'react-hook-form'
import { MainSettingsFormProps } from './types'
import { ConfigModel } from 'shared/models'
import { Box, Button, Card, Tab, TextField, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidations } from './validation'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { NavListDnd } from './nav-list-dnd'

function MainSettingsEditForm({ defaultData, onSubmit, isProcess }: MainSettingsFormProps) {
  const { control, handleSubmit } = useForm<ConfigModel>({
    defaultValues: defaultData,
    disabled: isProcess,
    resolver: yupResolver<ConfigModel>(getValidations())
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card elevation={1}>
        <TabContext value="main">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList>
              <Tab label="Основная информация" value="main" />
            </TabList>
          </Box>
          <TabPanel value="main">
            <Controller
              control={control}
              name="title"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Заголовок (главная страница)"
                  fullWidth
                  autoComplete="off"
                  placeholder="Заголовок"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Описание (главная страница)"
                  fullWidth
                  autoComplete="off"
                  placeholder="Описание"
                  multiline
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field, fieldState }) => (
                <TextField
                  label="Контактные номера телефона (разделенные ;)"
                  fullWidth
                  autoComplete="off"
                  placeholder="Заголовок"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            <Typography variant="h6" marginTop="2rem">
              Меню сайта
            </Typography>
            <Controller
              control={control}
              name="navPages"
              render={({ field }) => <NavListDnd navList={field.value} onChange={field.onChange} />}
            />
          </TabPanel>
        </TabContext>
      </Card>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: '1rem' }}>
        <Button type="submit" variant="outlined" disabled={isProcess}>
          Сохранить
        </Button>
      </Box>
    </form>
  )
}

export { MainSettingsEditForm }
