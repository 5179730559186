import { Box, Card, CircularProgress, Container, Tab, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { adminService } from 'shared/api'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { ServiceCreateModel, ServiceModel } from 'shared/models'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { ServiceEditForm } from './service-edit-form'
import { useState } from 'react'
import { serviceList } from 'pages/nav'
import { ServiceView } from './service-view'
import { ServicePriceList } from './service-price/service-price-list'
import { ScheduleList } from './service-booking/service-booking-list'
import { ServiceEmailTemplates } from './service-email-templates/service-email-templates'

function ServiceEdit() {
  const [tab, setTab] = useState<'main' | 'priceLists' | 'bookingSchedule' | 'emailTemplates'>('main')

  const [editMode, setEditMode] = useState(false)

  const queryClient = useQueryClient()

  const { serviceId } = useParams<{ serviceId?: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data, isFetching } = useQuery(
    ['service', serviceId],
    ({ signal }) => adminService.service.getService(Number(serviceId), { signal }),
    { enabled: !!serviceId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { data: addressData, isLoading: addressIsLoading } = useQuery(['addressList'], ({ signal }) =>
    adminService.address.getAddressList({ signal })
  )

  const { mutateAsync: createService, isLoading: isCreateLoading } = useMutation((data: ServiceCreateModel) =>
    adminService.service.createService(data)
  )
  const { mutateAsync: updateService, isLoading: isUpdateLoading } = useMutation((data: ServiceModel) =>
    adminService.service.updateService(data.id, data)
  )
  const { mutateAsync: deleteService, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.service.deleteService(id)
  )

  async function handleSubmit(data: ServiceModel | ServiceCreateModel) {
    try {
      if ('id' in data) {
        await updateService(data)
        queryClient.invalidateQueries(['service', serviceId])
      } else {
        await createService(data)
        navigate(serviceList() + '?term=' + data.name)
      }

      dispatch(showMessage({ text: 'Услуга успешно сохранена', type: MessageTypes.Success }))
      setEditMode(false)
    } catch (e) {
      console.log(e)
    }
  }

  async function handleDelete() {
    if (!data?.data) {
      return
    }

    try {
      await deleteService(data.data.id)

      dispatch(showMessage({ text: 'Услуга успешно удалена', type: MessageTypes.Success }))
      navigate(serviceList())
    } catch (e) {
      console.log(e)
    }
  }

  const isEdit = !!serviceId

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, minHeight: 40 }}>
        <Typography variant="h6">{isEdit ? data?.data?.name || '' : 'Создание услуги'}</Typography>
      </Box>
      {(!!isFetching || !!addressIsLoading) && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && !addressIsLoading && (
        <Card elevation={1}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={(_, value) => setTab(value)}>
                <Tab label="Основная информация" value="main" />
                {isEdit && <Tab label="Прайс листы" value="priceLists" />}
                {isEdit && !!data?.data.withReservation && (
                  <Tab label="Расписание бронирования" value="bookingSchedule" />
                )}
                {isEdit && <Tab label="Шаблоны EMAIL" value="emailTemplates" />}
              </TabList>
            </Box>
            <TabPanel value="main">
              {isEdit && !editMode && !!data?.data ? (
                <ServiceView data={data?.data} onEdit={() => setEditMode(true)} addressList={addressData?.data || []} />
              ) : (
                <ServiceEditForm
                  defaultData={data?.data}
                  isEdit={isEdit}
                  onSubmit={handleSubmit}
                  isProcess={isCreateLoading || isUpdateLoading || isDeleteLoading}
                  addressList={addressData?.data || []}
                  onDelete={handleDelete}
                />
              )}
            </TabPanel>
            {data?.data && (
              <TabPanel value="priceLists" style={{ padding: '0' }}>
                <ServicePriceList serviceId={data?.data?.id} />
              </TabPanel>
            )}
            {data?.data && (
              <TabPanel value="bookingSchedule" style={{ padding: '0' }}>
                <ScheduleList serviceId={data?.data?.id} />
              </TabPanel>
            )}
            {data?.data && (
              <TabPanel value="emailTemplates" style={{ padding: '0' }}>
                <ServiceEmailTemplates serviceId={data.data.id} />
              </TabPanel>
            )}
          </TabContext>
        </Card>
      )}
    </Container>
  )
}

export { ServiceEdit }
