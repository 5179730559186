import * as Yup from 'yup'
import { isEmail, isName, isPhone } from 'shared/utils/string-test'

function getValidations() {
  return Yup.object().shape({
    name: Yup.string()
      .test({
        test: (value) => !!value,
        message: 'Поле обязательно для заполнения'
      })
      .test({
        test: (value) => isName(value || ''),
        message: 'Имя ввденено некорректно'
      }),
    email: Yup.string()
      .test({
        test: (value) => !value || isEmail(value || ''),
        message: 'Адрес электронной почты введен некорректно'
      }),
    phoneNumber: Yup.string()
      .test({
        test: (value) => !!value,
        message: 'Поле обязательно для заполнения'
      })
      .test({
        test: (value) => isPhone(value || ''),
        message: 'Номер телефона введен некорректно'
      }),
    serviceId: Yup.number().required('Поле обязательно для заполнения'),
    date: Yup.number().required('Поле обязательно для заполнения'),
    time: Yup.number().required('Поле обязательно для заполнения')
  }) as Yup.AnyObjectSchema
}

export { getValidations }
