import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'
import { CustomerModel } from 'shared/models'

const customer = {
  getCustomerList(config?: AxiosRequestConfig) {
    return adminInstance.get<CustomerModel[]>('/customers', {
      ...config
    })
  },

  getExportDataLink(params: { term?: string }) {
    const query = new URLSearchParams()

    if (params.term) {
      query.append('term', params.term)
    }

    return process.env.APP_API + `/customers/exportCustomerListAsXlsx` + `?${query.toString()}`
  }
}

export { customer }
