import { ServiceEmailTemplate } from 'shared/models/email-templates'
import * as Yup from 'yup'

function getValidations() {
  return Yup.object().shape({
    type: Yup.string().required('Поле обязательно для заполнения'),
    subject: Yup.string().required('Поле обязательно для заполнения'),
    body: Yup.string().required('Поле обязательно для заполнения')
  }) as Yup.ObjectSchema<NonNullable<ServiceEmailTemplate | Omit<ServiceEmailTemplate, 'id'>>, Yup.AnyObject>
}

export { getValidations }
