import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import { NavListItemProps } from './types'
import React, { useRef } from 'react'
import type { Identifier, XYCoord } from 'dnd-core'
import { IconButton, TextField } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { ItemActions, ItemContainer, ItemInfo } from './styled'

interface DragItem {
  index: number
  id: string
}

function NavListItem({ handleRemove, handleChange, id, index, item, moveCard }: NavListItemProps) {
  const dragRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'nav-list',
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() }
    },
    hover(item: DragItem, monitor) {
      if (!dragRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = dragRef.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)

      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'nav-list',
    item: () => ({ id, index }),
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() })
  })

  function handleNameChange(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const newValue = { ...item }
    newValue.name = ev.target.value
    handleChange(newValue)
  }

  function handleLinkToChange(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const newValue = { ...item }
    newValue.linkTo = ev.target.value
    handleChange(newValue)
  }

  preview(drop(dragRef))

  return (
    <ItemContainer style={{ opacity: isDragging ? 0 : 1 }} ref={dragRef}>
      <ItemInfo>
        <TextField
          label="Название"
          placeholder="Название"
          value={item.name}
          onChange={handleNameChange}
          fullWidth
          variant="standard"
        />
        <TextField
          label="Ссылка"
          placeholder="Ссылка"
          value={item.linkTo}
          onChange={handleLinkToChange}
          fullWidth
          variant="standard"
        />
      </ItemInfo>
      <ItemActions>
        <div ref={drag} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <DragIndicatorIcon style={{ opacity: '0.2', cursor: 'move' }} data-handler-id={handlerId} />
        </div>
        <IconButton size="small" onClick={handleRemove}>
          <RemoveIcon fontSize="small" />
        </IconButton>
      </ItemActions>
    </ItemContainer>
  )
}

export { NavListItem }
