import MuiPhoneNumber from './components/index'

import { TextFieldProps } from '@mui/material'
import * as React from 'react'

export type MuiPhoneNumberProps = TextFieldProps & {
  autoFormat?: boolean
  classes?: any
  countryCodeEditable?: boolean
  defaultCountry?: string
  disableAreaCodes?: boolean
  disableCountryCode?: boolean
  disableDropdown?: boolean
  dropdownClass?: string
  enableLongNumbers?: boolean
  excludeCountries?: string[]
  inputClass?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => void
  onlyCountries?: string[]
  preferredCountries?: string[]
  regions?: [string] | string
}

const MuiPhoneNumberTyped: React.FC<MuiPhoneNumberProps> = MuiPhoneNumber

export default MuiPhoneNumberTyped
export { validatePhone } from './country_data'
