import { ServiceModel } from 'shared/models'
import { Tooltip } from '@mui/material'
import { CellName, CellTitle } from './styled'

function SchedulerCell({
  data,
  setSelectedServicePoint
}: {
  data: ServiceModel
  setSelectedServicePoint?: (id: number) => void
}) {
  function cellClick() {
    setSelectedServicePoint?.(data.id)
  }

  return (
    <Tooltip
      arrow
      title={
        <div>
          <CellTitle>
            <div style={{ flex: 1 }}>{data.name}</div>
          </CellTitle>
        </div>
      }
    >
      <CellName onClick={cellClick}>{data.name}</CellName>
    </Tooltip>
  )
}

export { SchedulerCell }
