import { OrderViewModel, OrderModel, OrderEditModel, SchedulerOrderModel, OrderJournalActionInitiator } from 'shared/models'

const fakeOrders: OrderModel[] = [
  {
    id: '1231',
    customer: {
      id: '1',
      email: 'admin@insidequest.ru',
      name: 'Сергей',
      phoneNumber: '+79007753434'
    },
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'None',
    services: [
      {
        id: '1',
        price: 2000,
        priceId: '1',
        reservation: {
          id: '1',
          persons: 5,
          startTime: new Date(2024, 4, 20).getTime(),
          endTime: new Date(2024, 4, 20).getTime()
        },
        service: {
          id: 7,
          addressId: 1,
          name: 'Квест Пятница 13',
          withReservation: true
        }
      },
      {
        id: '2',
        price: 1000,
        priceId: '2',
        reservation: {
          id: '1',
          persons: 5,
          startTime: new Date(2024, 4, 20).getTime(),
          endTime: new Date(2024, 4, 20).getTime()
        },
        service: {
          id: 2,
          addressId: 1,
          name: 'Фотограф',
          withReservation: false
        }
      },
      {
        id: '3',
        price: 1500,
        priceId: '3',
        reservation: {
          id: '1',
          persons: 5,
          startTime: new Date(2024, 4, 20).getTime(),
          endTime: new Date(2024, 4, 20).getTime()
        },
        service: {
          id: 3,
          addressId: 1,
          name: 'Дополнительный актер',
          withReservation: false
        }
      }
    ],
    status: 'CallConfirmed',
    comment: 'День рождения у дочери',
    staffComment: 'Запрошена доплата за измененный заказ',
    total: 7200,
    creationInitiaton: OrderJournalActionInitiator.Backoffice
  },
  {
    id: '1230',
    customer: {
      id: '2',
      email: 'ivan.golub@gmail.com',
      name: 'Павел',
      phoneNumber: '+7 909 020-12-16'
    },
    staffComment: '',
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'Failed',
    services: [
      {
        id: '1',
        price: 2000,
        priceId: '1',
        reservation: {
          id: '1',
          persons: 2,
          startTime: new Date(2024, 4, 20).getTime(),
          endTime: new Date(2024, 4, 20).getTime()
        },
        service: {
          id: 7,
          addressId: 1,
          name: 'Квест Пятница 13',
          withReservation: true
        }
      }
    ],
    status: 'Completed',
    comment: '',
    total: 3200,
    creationInitiaton: OrderJournalActionInitiator.MirKvestov
  },
  {
    id: '1229',
    customer: {
      id: '3',
      email: 'semenov1994@gmail.com',
      name: 'Семен',
      phoneNumber: '+7 999 331-09-09'
    },
    staffComment: '',
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'Paid',
    prePaymentAmount: 1000,
    services: [
      {
        id: '1',
        price: 2000,
        priceId: '1',
        reservation: {
          id: '1',
          persons: 2,
          startTime: new Date(2024, 4, 20).getTime(),
          endTime: new Date(2024, 4, 20).getTime()
        },
        service: {
          id: 2,
          addressId: 1,
          name: 'Кровавый цирк',
          withReservation: true
        }
      }
    ],
    status: 'Confirmed',
    comment: '',
    total: 2800,
    creationInitiaton: OrderJournalActionInitiator.Site
  },
  {
    id: '1228',
    customer: {
      id: '4',
      email: 'semenov1994@gmail.com',
      name: 'Семен',
      phoneNumber: '+7 999 331-09-09'
    },
    staffComment: '',
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'None',
    services: [
      {
        id: '1',
        price: 2000,
        priceId: '1',
        reservation: {
          id: '1',
          persons: 2,
          startTime: new Date(2024, 4, 20).getTime(),
          endTime: new Date(2024, 4, 20).getTime()
        },
        service: {
          id: 3,
          addressId: 1,
          name: 'Шерлок Холмс',
          withReservation: true
        }
      }
    ],
    status: 'CallFailed',
    comment: '',
    total: 6000,
    creationInitiaton: OrderJournalActionInitiator.KvestBattle
  },
  {
    id: '1227',
    customer: {
      id: '5',
      email: 'semenov1994@gmail.com',
      name: 'Семен',
      phoneNumber: '+7 999 331-09-09'
    },
    staffComment: '',
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'None',
    services: [
      {
        id: '1',
        price: 2000,
        priceId: '1',
        reservation: {
          id: '1',
          persons: 2,
          startTime: new Date(2024, 4, 20).getTime(),
          endTime: new Date(2024, 4, 20).getTime()
        },
        service: {
          id: 3,
          addressId: 1,
          name: 'Побег из тюрьмы',
          withReservation: true
        }
      }
    ],
    status: 'Cancelled',
    comment: '',
    total: 4200,
    creationInitiaton: OrderJournalActionInitiator.KvestInfo
  },
  {
    id: '1226',
    customer: {
      id: '6',
      email: 'admin@insidequest.ru',
      name: 'Сергей',
      phoneNumber: '+79007753434'
    },
    staffComment: '',
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'Requested',
    services: [
      {
        id: '1',
        price: 2000,
        priceId: '1',
        reservation: {
          id: '1',
          persons: 5,
          startTime: new Date(2024, 4, 20).getTime(),
          endTime: new Date(2024, 4, 20).getTime()
        },
        service: {
          id: 7,
          addressId: 1,
          name: 'Квест Пятница 13',
          withReservation: true
        }
      }
    ],
    status: 'Created',
    comment: '',
    total: 3500,
    creationInitiaton: OrderJournalActionInitiator.TopKvestov
  }
]

const fakeSchedulerOrders: SchedulerOrderModel[] = [
  {
    id: '1231',
    customer: {
      id: '1',
      email: 'admin@insidequest.ru',
      name: 'Сергей',
      phoneNumber: '+79007753434'
    },
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'None',
    orderWebsiteLink: 'https://insidequest.store/order/8a98fdbe-c4f5-44bf-8bf0-de00f2d20ef4',
    mainService: {
      id: '1',
      price: 2000,
      priceId: '2',
      service: {
        id: 7,
        addressId: 1,
        name: 'Квест Пятница 13',
        withReservation: true
      },
      reservation: {
        id: '1',
        persons: 5,
        startTime: new Date(2024, 4, 20).getTime(),
        endTime: new Date(2024, 4, 20).getTime()
      }
    },
    additionalServices: [
      {
        id: '2',
        price: 1000,
        priceId: '2',
        service: {
          id: 2,
          addressId: 1,
          name: 'Фотограф',
          withReservation: false
        }
      },
      {
        id: '3',
        price: 1500,
        priceId: '2',
        service: {
          id: 3,
          addressId: 1,
          name: 'Дополнительный актер',
          withReservation: false
        }
      }
    ],
    staffComment: '',
    status: 'Confirmed',
    comment: 'День Рождения дочери',
    total: 7200,
    creationInitiaton: OrderJournalActionInitiator.MirKvestov
  },
  {
    id: '1230',
    customer: {
      id: '2',
      email: 'ivan.golub@gmail.com',
      name: 'Павел',
      phoneNumber: '+7 909 020-12-16'
    },
    orderWebsiteLink: 'https://insidequest.store/order/8a98fdbe-c4f5-44bf-8bf0-de00f2d20ef4',
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'Failed',
    mainService: {
      id: '1',
      price: 2000,
      priceId: '2',
      service: {
        id: 7,
        addressId: 1,
        name: 'Квест Пятница 13',
        withReservation: true
      },
      reservation: {
        id: '1',
        persons: 2,
        startTime: new Date(2024, 4, 20, 12, 0, 0, 0).getTime(),
        endTime: new Date(2024, 4, 20, 13, 30, 0).getTime()
      }
    },
    additionalServices: [
      {
        id: 2,
        price: 1000,
        priceId: '2',
        service: {
          id: 2,
          addressId: 1,
          name: 'Фотограф',
          withReservation: false
        }
      },
      {
        id: 3,
        price: 1500,
        priceId: '3',
        service: {
          id: 3,
          addressId: 1,
          name: 'Аниматор',
          withReservation: false
        }
      }
    ],
    staffComment: '',
    status: 'CallConfirmed',
    comment: '',
    total: 3200,
    creationInitiaton: OrderJournalActionInitiator.Backoffice
  },
  {
    id: '1229',
    customer: {
      id: '3',
      email: 'semenov1994@gmail.com',
      name: 'Семен',
      phoneNumber: '+7 999 331-09-09'
    },
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'Paid',
    prePaymentAmount: 1000,
    mainService: {
      id: '1',
      price: 2000,
      priceId: '',
      service: {
        id: 9,
        addressId: 1,
        name: 'Кровавый цирк',
        withReservation: true
      },
      reservation: {
        id: '1',
        persons: 2,
        startTime: new Date(2024, 4, 20, 13, 30, 0, 0).getTime(),
        endTime: new Date(2024, 4, 20, 14, 0, 0, 0).getTime()
      }
    },
    additionalServices: [],
    status: 'Confirmed',
    comment: '',
    staffComment: '',
    total: 2800
  },
  {
    id: '1228',
    customer: {
      id: '4',
      email: 'semenov1994@gmail.com',
      name: 'Семен',
      phoneNumber: '+7 999 331-09-09'
    },
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'None',
    mainService: {
      id: '1',
      price: 2000,
      priceId: '',
      reservation: {
        id: '1',
        persons: 2,
        startTime: new Date(2024, 4, 20, 13, 30, 0, 0).getTime(),
        endTime: new Date(2024, 4, 20, 15, 0, 0).getTime()
      },
      service: {
        id: 10,
        addressId: 1,
        name: 'Шерлок Холмс',
        withReservation: true
      }
    },
    status: 'CallFailed',
    additionalServices: [],
    staffComment: '',
    comment: '',
    total: 6000
  },
  {
    id: '1227',
    customer: {
      id: '5',
      email: 'semenov1994@gmail.com',
      name: 'Семен',
      phoneNumber: '+7 999 331-09-09'
    },
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'None',
    mainService: {
      id: '1',
      price: 2000,
      priceId: '',
      reservation: {
        id: '1',
        persons: 2,
        startTime: new Date(2024, 4, 20, 15, 30, 0, 0).getTime(),
        endTime: new Date(2024, 4, 20, 17, 0, 0).getTime()
      },
      service: {
        id: 3,
        addressId: 1,
        name: 'Побег из тюрьмы',
        withReservation: true
      }
    },
    additionalServices: [],
    staffComment: '',
    status: 'Cancelled',
    comment: '',
    total: 4200
  },
  {
    id: '1226',
    customer: {
      id: '6',
      email: 'admin@insidequest.ru',
      name: 'Сергей',
      phoneNumber: '+79007753434'
    },
    dateTime: new Date(2024, 4, 20).getTime(),
    paymentStatus: 'Requested',
    mainService: {
      id: '1',
      price: 2000,
      priceId: '',
      reservation: {
        id: '1',
        persons: 5,
        startTime: new Date(2024, 4, 20, 17, 0, 0, 0).getTime(),
        endTime: new Date(2024, 4, 20, 18, 30, 0).getTime()
      },
      service: {
        id: 7,
        addressId: 1,
        name: 'Квест Пятница 13',
        withReservation: true
      }
    },
    additionalServices: [],
    staffComment: '',
    status: 'Created',
    comment: '',
    total: 3500
  }
]

const fakeOrderView: OrderViewModel = {
  id: '1231',
  customer: {
    id: '1',
    email: 'admin@insidequest.ru',
    name: 'Сергей',
    phoneNumber: '+79007753434'
  },
  dateTime: new Date(2024, 4, 20).getTime(),
  paymentStatus: 'Paid',
  prePaymentAmount: 2000,
  orderWebsiteLink: 'https://insidequest.store/order/8a98fdbe-c4f5-44bf-8bf0-de00f2d20ef4',
  mainService: {
    id: 1,
    price: 2000,
    priceId: '1',
    reservation: {
      id: '1',
      persons: 5,
      startTime: new Date(2024, 4, 20, 19, 0, 0, 0).getTime(),
      endTime: new Date(2024, 4, 20, 20, 30, 0).getTime()
    },
    service: {
      id: 7,
      addressId: 1,
      name: 'Квест Пятница 13',
      withReservation: true
    }
  },
  additionalServices: [
    {
      id: 2,
      price: 1000,
      priceId: '2',
      service: {
        id: 2,
        addressId: 1,
        name: 'Фотограф',
        withReservation: false
      }
    },
    {
      id: 3,
      price: 1500,
      priceId: '3',
      service: {
        id: 3,
        addressId: 1,
        name: 'Аниматор',
        withReservation: false
      }
    }
  ],
  status: 'CallConfirmed',
  comment: 'День Рождения дочери',
  staffComment: 'Не взял трубку, но отписался в тг с подтверждением',
  total: 7200,
  creationInitiaton: OrderJournalActionInitiator.MirKvestov
}

const fakeOrderEditForm: OrderEditModel = {
  id: '1231',
  serviceId: 7,
  additionalServiceIds: [11, 12],
  comment: 'День Рождения дочери',
  persons: 5,
  email: 'admin@insidequest.ru',
  name: 'Сергей',
  phoneNumber: '+79007753434',
  date: 1719014400000,
  time: 21600000
}

export { fakeOrders, fakeOrderView, fakeOrderEditForm, fakeSchedulerOrders }
