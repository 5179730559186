export function login() {
  return '/login'
}

export function questList() {
  return '/quests'
}

export function questEdit(id: string) {
  return `${questList()}/${id}`
}

export function questCreate() {
  return `${questList()}/create`
}

export function questOrders() {
  return `${questList()}/orders`
}

export function questCategoryList() {
  return `/questCategories`
}

export function questCategoryEdit(id: string) {
  return `${questCategoryList()}/${id}`
}

export function questCategoryCreate() {
  return `${questCategoryList()}/create`
}

export function serviceList() {
  return `/services`
}

export function serviceEdit(id: string) {
  return `${serviceList()}/${id}`
}

export function serviceCreate() {
  return `${serviceList()}/create`
}

export function addressList() {
  return `/addresses`
}

export function addressEdit(id: string) {
  return `${addressList()}/${id}`
}

export function addressCreate() {
  return `${addressList()}/create`
}

export function userList() {
  return `/users`
}

export function userEdit(id: string) {
  return `${userList()}/${id}`
}

export function userCreate() {
  return `${userList()}/create`
}

export function profile() {
  return `/profile`
}

export function orderList() {
  return `/orders`
}

export function cancelledOrderList() {
  return `/cancelled-orders`
}

export function orderView(id: string) {
  return `/orders/${id}`
}

export function reservationScheduler() {
  return `/scheduler`
}

export function reservationWeekdaysScheduler() {
  return `/weekdays-scheduler`
}

export function clientList() {
  return `/clients`
}

export function pageList() {
  return `/pages`
}

export function pageEdit(id: string) {
  return `${pageList()}/${id}`
}

export function pageCreate() {
  return `${pageList()}/create`
}

export function mainSettings() {
  return '/main-settings'
}
