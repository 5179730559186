import { OrderModel } from './order.model'

export enum OrderJournalAction {
  OrderCreation = 'OrderCreation',
  OrderUpdate = 'OrderUpdate',
  OrderCancellation = 'OrderCancellation',
  OrderStatusChanged = 'OrderStatusChanged',
  OrderStaffCommentChanged = 'OrderStaffCommentChanged',
  PaymentRequested = 'PaymentRequested',
  PaymentConfirmed = 'PaymentConfirmed',
  PaymentRejected = 'PaymentRejected'
}

export enum OrderJournalActionInitiator {
  Auto = 'Auto',
  Site = 'Site',
  Backoffice = 'Backoffice',
  MirKvestov = 'MirKvestov',
  TopKvestov = 'TopKvestov',
  KvestBattle = 'KvestBattle',
  KvestInfo = 'KvestInfo'
}

export type OrderJournalItem = {
  id: string | number
  dateTime: number
  actionType: OrderJournalAction
  source?: string
  order: OrderModel
  actionInitiator: OrderJournalActionInitiator
  user?: {
    id: number
    firstName: string
    lastName: string
    email: string
  }
  paymentInfo?: {
    id: string | number
    link: string
    amount: number
  }
}
