import { useEffect, useState, FC } from 'react'
import { adminService } from 'shared/api'
import CircularProgress from '@mui/material/CircularProgress'
import { isAuthorized } from 'shared/auth'
import { useAppDispatch } from 'store'
import { setUserProfile } from 'store/slices/profile-slice'

function StoreUser<T extends object>(WrappedComponent: FC<T>) {
  return function StoreUserComponent(props: T) {
    const [loading, setLoading] = useState(true)
    const dispatch = useAppDispatch()

    useEffect(() => {
      if (!isAuthorized()) {
        setLoading(false)
      } else {
        adminService.user
          .getCurrentUserProfile()
          .then((profile) => {
            dispatch(setUserProfile(profile.data))
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }, [])

    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      )
    }

    return <WrappedComponent {...props} />
  }
}

export default StoreUser
