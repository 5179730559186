import { ReactNode } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import ru from 'dayjs/locale/ru';

type Props = {
  children: ReactNode
}

export function PickersUtilsProvider(props: Props) {
  dayjs.locale({
    ...ru,
    weekStart: 1
  })

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
      {props.children}
    </LocalizationProvider>
  )
}
