import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  TextField,
  Tooltip
} from '@mui/material'
import { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { AdditionalServiceListProps } from './types'
import { AdditionalServiceListItem } from './additional-service-list-item'

function AdditionalServiceList({ value, serviceList, onChange, addressId }: AdditionalServiceListProps) {
  const [addServiceDialog, setAddServiceDialog] = useState(false)
  const [selectedService, setSelectedService] = useState<number>()

  function handleAddServiceToList() {
    setAddServiceDialog(true)
  }

  function handleDialogClose() {
    setAddServiceDialog(false)
    setSelectedService(undefined)
  }

  function handleAddToList() {
    setAddServiceDialog(false)
    setSelectedService(undefined)
    if (selectedService) {
      onChange([...value, selectedService])
    }
  }

  const availableServicesToAdd =
    serviceList
      ?.filter((s) => !s.withReservation)
      ?.filter((s) => s.addressId === addressId)
      ?.filter((s) => !value.includes(s.id)) ?? []

  return (
    <DndProvider backend={HTML5Backend} key="additionalServiceList">
      <List disablePadding>
        {value.map?.((serviceId) => (
          <AdditionalServiceListItem
            key={serviceId}
            onChange={onChange}
            value={value}
            serviceId={serviceId}
            service={serviceList?.find?.((s) => s.id === serviceId)}
          />
        ))}
        <Box style={{ display: 'flex', justifyContent: 'center', margin: '0.5rem 1rem' }}>
          <Tooltip title="Добавить">
            <IconButton onClick={handleAddServiceToList}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Dialog open={addServiceDialog} fullWidth onClose={handleDialogClose}>
          <DialogTitle>Добавить услугу</DialogTitle>
          <DialogContent>
            <Autocomplete
              value={serviceList?.find?.((s) => s.id === selectedService) || null}
              fullWidth
              onChange={(_, newValue) => setSelectedService(newValue?.id)}
              options={availableServicesToAdd}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Услуга" margin="dense" variant="standard" size="small" />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddToList} disabled={!selectedService}>
              Добавить
            </Button>
          </DialogActions>
        </Dialog>
      </List>
    </DndProvider>
  )
}

export { AdditionalServiceList }
