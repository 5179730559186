import { SchedulePosition, ColumnItem, CellTimeSlot } from '../types'

function sortPositionsByTimeAndDuration(positions: SchedulePosition[]) {
  const result = positions
    .sort((a, b) => {
      const duratioinA = new Date(a.endDate).getTime() - new Date(a.startDate).getTime()
      const duratioinB = new Date(b.endDate).getTime() - new Date(b.startDate).getTime()

      return duratioinA - duratioinB
    })
    .sort((a, b) => (new Date(a.startDate).getTime() > new Date(b.startDate).getTime() ? 1 : -1))

  return result
}

function calculateColumnDistributedOverlaysPositions(positions: SchedulePosition[]) {
  const result = positions.reduce((acc, el) => {
    const columns = [...acc]

    let distributed = false

    for (let i = 0; i < columns.length; i++) {
      const foundDiscributedElement = columns[i].find((x) => {
        return new Date(x.position.endDate).setSeconds(0, 0) > new Date(el.startDate).setSeconds(0, 0)
      })

      if (!foundDiscributedElement) {
        if (!distributed) {
          columns[i].push({ position: el, meta: { columnIndex: i } })
          distributed = true
        }
      }
    }

    if (!distributed) {
      columns.push([{ position: el, meta: { columnIndex: columns.length } }])
    }

    return columns
  }, [] as ColumnItem[][])

  return result
}

function groupPositionDistributedByTimeSlots(items: ColumnItem[][], timeSlots: CellTimeSlot[], maxColumnCount: number) {
  const result = items
    .slice(maxColumnCount)
    .flat()
    .reduce(
      (acc, el) => {
        const timeSlot = timeSlots.find(
          (x) =>
            new Date(x.startTime).setSeconds(0, 0) <= new Date(el.position.startDate).setSeconds(0, 0) &&
            new Date(x.endTime).setSeconds(0, 0) > new Date(el.position.startDate).setSeconds(0, 0)
        )

        if (timeSlot) {
          return { ...acc, [timeSlot.i]: { timeSlot, positions: [...(acc[timeSlot.i]?.positions || []), el] } }
        }
        return acc
      },
      {} as { [key: number]: { positions: ColumnItem[]; timeSlot: CellTimeSlot } }
    )

  return Object.values(result)
}

export {
  sortPositionsByTimeAndDuration,
  calculateColumnDistributedOverlaysPositions,
  groupPositionDistributedByTimeSlots
}
