import { PageCreateModel } from 'shared/models'

const pageCreateData: PageCreateModel = {
  name: '',
  alias: '',
  mainServiceId: undefined as any,
  additionalServiceIds: [],
  headerImageUrl: '',
  htmlDescription: '',
  htmlKeywords: '',
  htmlTitle: '',
  content: ''
}

export { pageCreateData }
