const typedErrors: Record<string, string> = {
  AddressNotFoundError: 'Адрес не найден',
  CategoryNotFoundError: 'Категория не найдена',
  PriceNotFoundError: 'Прайс-лист не найден',
  QuestNotFoundError: 'Квест не найден',
  UserNotFoundError: 'Пользователь не найден',
  ServiceNotFoundError: 'Услуга не найдена',
  ScheduleNotFoundError: 'Расписание не найдено',
  ServiceCannotBeDeletedError: 'Невозможно удалить услугу, т.к. она привязана к квесту или странице'
}

export { typedErrors }
