import { useContext } from 'react'
import { modeContext } from './mode-context'

function useMode() {
  const mode = useContext(modeContext)

  return mode
}

export { useMode }
