import { http, HttpResponse, delay } from 'msw'
import { fakeCategories } from '../fake-data/fake-categories'

export const categories = [
  http.get('/api/categories', async () => {
    await delay(900)

    return HttpResponse.json(fakeCategories)
  }),

  http.get('/api/categories/:id', async ({ params }) => {
    await delay(300)

    const { id } = params

    const foundCategory = id ? fakeCategories.find((caterory) => caterory.id === Number(id)) : undefined

    if (!foundCategory) {
      return HttpResponse.json(undefined, { status: 404 })
    } else {
      return HttpResponse.json(foundCategory)
    }
  }),

  http.post('/api/categories', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.put('/api/categories/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.delete('/api/categories/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  })
]
