import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileModel } from 'shared/models'
import { RootState } from '..'

type ProfileState = {
  loggedIn: boolean
  userProfile: ProfileModel | null
  isSettingsMode: boolean
}

const initialState: ProfileState = {
  loggedIn: false,
  userProfile: null,
  isSettingsMode: localStorage.getItem('isSettingsMode') === 'true'
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserProfile(state, { payload }: PayloadAction<ProfileModel>) {
      state.loggedIn = true
      state.userProfile = payload
    },
    clearProfile(state) {
      state.loggedIn = false
      state.userProfile = null
    },

    setSettingsMode(state, { payload }: PayloadAction<boolean>) {
      state.isSettingsMode = payload
    }
  }
})

function setSettingsMode(value: boolean) {
  return (dispatch) => {
    localStorage.setItem('isSettingsMode', String(value))
    dispatch(profileSlice.actions.setSettingsMode(value))
  }
}

function getUserProfileSelector(state: RootState) {
  return state.profile.userProfile
}

function getUserLoggedInSelector(state: RootState) {
  return state.profile.loggedIn
}

function getIsSettingsModeSelector(state: RootState) {
  return state.profile.isSettingsMode
}

export const { clearProfile, setUserProfile } = profileSlice.actions
export { getUserProfileSelector, getUserLoggedInSelector, getIsSettingsModeSelector, setSettingsMode }
export default profileSlice.reducer
