import { ServiceCreateModel, ServiceModel } from 'shared/models'
import * as Yup from 'yup'

function getValidations() {
  return Yup.object().shape({
    name: Yup.string().required('Поле обязательно для заполнения'),
    addressId: Yup.number().required('Поле обязательно для заполнения')
  }) as Yup.ObjectSchema<NonNullable<ServiceModel | ServiceCreateModel>, Yup.AnyObject>
}

export { getValidations }
