import { memo } from 'react'
import { CalendarDataEl } from '../styled'
import { CellsComponentProps } from './types'

function CellsComponent(props: CellsComponentProps) {
  return (
    <>
      {props.day.dayTimes.map((column, colIndex) => (
        <CalendarDataEl key={colIndex * (props.day.date + 1)}>
          {props.dataCellRender(column, props.resourceId)}
        </CalendarDataEl>
      ))}
    </>
  )
}

export const Cells = memo(CellsComponent)
