import { useMemo } from 'react'
import { SchedulerProps } from './types'
import { SchedulerAppointment, SchedulerCell } from '.'
import { SchedulerBase, Resource, SchedulePosition } from '../scheduler-base'
import { OtherPositionList, PositionStateIndicator } from './styled'
import { Typography } from '@mui/material'
import { orderStatusBackground, orderStatusColor, orderStatusText } from 'pages/orders/status-utils'

function Scheduler(props: SchedulerProps) {
  const resourceCellRender = useMemo(() => {
    return (resource: Resource) => (
      <SchedulerCell data={resource.rawResource} setSelectedServicePoint={props.setSelectedService} />
    )
  }, [])

  const otherAppointmentRender = useMemo(() => {
    return (appointment: SchedulePosition) => {
      return (
        <OtherPositionList key={appointment.id}>
          <div style={{ flex: 1 }}>
            <Typography variant="body2">{appointment.text}</Typography>
            <div style={{ opacity: 0.56 }}>{orderStatusText[appointment.rawPosition]}</div>
          </div>
          <PositionStateIndicator
            style={{
              backgroundColor: orderStatusBackground[appointment?.rawPosition?.status]
            }}
          />
        </OtherPositionList>
      )
    }
  }, [])

  const getPositionStyles = useMemo(() => {
    return (position: SchedulePosition) => {
      const background = orderStatusBackground[position.rawPosition.status] || '#5567f5'
      const color = orderStatusColor[position.rawPosition.status] || '#fff'

      return { background, color }
    }
  }, [])

  const appointmentRender = useMemo(() => {
    return (appointment: SchedulePosition) => {
      return <SchedulerAppointment key={appointment.id} data={appointment} />
    }
  }, [])

  const dataCellRender = useMemo(() => {
    return () => {
      return null
    }
  }, [])

  const handlePositionSelect = useMemo(() => {
    return (position: SchedulePosition) => {
      props.setSelectedPosition(position.id)
    }
  }, [])

  const resources = useMemo(
    () => props.resounces?.map((s) => ({ id: s.id, text: s.name, rawResource: s })),
    [props.resounces]
  )

  return (
    <SchedulerBase
      positions={props.positions}
      date={props.date}
      resources={resources}
      appointmentRender={appointmentRender}
      dataCellRender={dataCellRender}
      otherAppointmentRender={otherAppointmentRender}
      resourceCellRender={resourceCellRender}
      setSelectedPosition={handlePositionSelect}
      getAppointmentStyles={getPositionStyles}
      appointmentHeight={props.appointmentHeight}
      cellDuration={props.cellDuration}
      currentView={props.currentView}
      disableCustomCellRender={props.disableCustomCellRender}
      hideInfo={props.hideInfo}
      maxColumnCount={props.maxColumnCount}
      startDayHour={props.startDayHour}
      endDayHour={props.endDayHour}
    />
  )
}

export { Scheduler }
