import { CalendarHeader, CalendarHeaderContainer, CalendarLeftHeader } from '../styled'
import { ReactNode, memo, useMemo } from 'react'
import { Resource } from '../types'

function HeaderResourcesComponent(props: {
  resources?: Resource[]
  resourceCellRender?: (resource: Resource) => ReactNode
  dayCount: number
  days: number[]
}) {
  const calendarHeaderStyles = useMemo(
    () => ({ gridTemplateColumns: `repeat(${(props.resources?.length || 1) * props.dayCount}, minmax(50px, 1fr))` }),
    [props.resources?.length || 1, props.dayCount]
  )

  return (
    <CalendarHeaderContainer>
      <CalendarLeftHeader />
      <CalendarHeader style={calendarHeaderStyles}>
        {props.days.map((day) => {
          return props.resources?.map((data, index) => {
            return <div key={`${day}-${index}`}>{props.resourceCellRender?.(data)}</div>
          })
        })}
      </CalendarHeader>
    </CalendarHeaderContainer>
  )
}

export const HeaderResources = memo(HeaderResourcesComponent)
