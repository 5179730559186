import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'

const file = {
  upload(data: FormData, config?: AxiosRequestConfig) {
    return adminInstance.post<{ url: string }>('/files/upload', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...config
    })
  }
}

export { file }
