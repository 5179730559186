import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  AppBar,
  Skeleton,
  IconButton,
  Tooltip
} from '@mui/material'
import { useQuery } from 'react-query'
import { adminService } from 'shared/api'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { SearchInput } from 'features/search-input'
import { useMediaQuery } from 'react-responsive'

function ClientList() {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  const [searchParams, setSearchParams] = useSearchParams()

  const [term, setTerm] = useState(searchParams.get('term') || '')

  const { data, isLoading } = useQuery(['clientList'], ({ signal }) =>
    adminService.customer.getCustomerList({ signal })
  )

  useEffect(() => {
    const search = new URLSearchParams(searchParams)
    if (term) {
      search.set('term', term)
    } else {
      search.delete('term')
    }
    setSearchParams(search)
  }, [term])

  function handleTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTerm(e.target.value)
  }

  const filteredList = (data?.data || []).filter((client) => {
    const termForFilter = [client?.name, client?.phoneNumber, client.phoneNumber?.replace(/\D/g, ''), client?.email]
      .join(' ')
      .toLowerCase()

    return termForFilter.includes(term.toLowerCase())
  })

  return (
    <>
      <AppBar
        position="sticky"
        color="inherit"
        style={{ margin: '-1.5rem -1.5rem 1.5rem', top: isMobile ? '56px' : '4rem', width: 'auto' }}
        elevation={1}
      >
        <Toolbar style={{ display: 'flex', gap: '1rem' }}>
          <SearchInput value={term} onChange={handleTermChange} onClear={() => setTerm('')} withoutLabel={true} />
          <Tooltip title="Экспортировать клиентов в файл Excel">
            <a href={adminService.customer.getExportDataLink({ term })} target="_blank">
              <IconButton>
                <CloudDownloadIcon fontSize="small" />
              </IconButton>
            </a>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" style={{ padding: isMobile ? 0 : undefined }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 1, mb: 2 }}>
          <Typography color="GrayText" variant="body2">
            {filteredList.length || 0} результатов
          </Typography>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden', display: 'grid' }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Имя</TableCell>
                  <TableCell style={{ width: '250px' }}>Email</TableCell>
                  <TableCell style={{ width: '200px' }}>Номер телефона</TableCell>
                </TableRow>
              </TableHead>
              {!!isLoading && (
                <TableBody>
                  {[...Array(7)].map((_, i) => (
                    <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      {[...Array(3)].map((_, index) => (
                        <TableCell key={index}>
                          <Skeleton />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              )}
              {!isLoading && (
                <TableBody>
                  {!filteredList.length && (
                    <TableRow>
                      <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                        Ничего не найдено
                      </TableCell>
                    </TableRow>
                  )}
                  {filteredList.map?.((client) => (
                    <TableRow key={client.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell style={{ verticalAlign: 'top' }}>{client.name}</TableCell>
                      <TableCell>{client.email}</TableCell>
                      <TableCell style={{ verticalAlign: 'top' }}>{client.phoneNumber}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  )
}

export { ClientList }
