import { CategoryModel, CateroryCreateModel } from 'shared/models'
import * as Yup from 'yup'

function getValidations() {
  return Yup.object().shape({
    name: Yup.string().required('Поле обязательно для заполнения')
  }) as Yup.ObjectSchema<NonNullable<CategoryModel | CateroryCreateModel>, Yup.AnyObject>
}

export { getValidations }
