import { CreateUserModel, EditUserModel, ProfileModel, UserModel } from 'shared/models'
import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'

const user = {
  login(email: string, password: string, config?: AxiosRequestConfig) {
    return adminInstance.post<void>('/auth/login', { email, password }, config)
  },

  logout(config?: AxiosRequestConfig) {
    return adminInstance.post<void>('/auth/logout', config)
  },

  getCurrentUserProfile(config?: AxiosRequestConfig) {
    return adminInstance.get<ProfileModel>('/auth/getCurrentUserProfile', config)
  },

  getUserList(config?: AxiosRequestConfig) {
    return adminInstance.get<UserModel[]>('/users', { ...config })
  },

  getUser(userId: number, config?: AxiosRequestConfig) {
    return adminInstance.get<UserModel>(`/users/${userId}`, config)
  },

  createUser(data: CreateUserModel, config?: AxiosRequestConfig) {
    return adminInstance.post<void>('/users', data, config)
  },

  updateUser(userId: number, data: EditUserModel, config?: AxiosRequestConfig) {
    return adminInstance.put<void>(`/users/${userId}`, data, config)
  },

  deleteUser(userId: number, config?: AxiosRequestConfig) {
    return adminInstance.delete<void>(`/users/${userId}`, config)
  }
}

export { user }
