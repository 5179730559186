import { TimeSlotModel } from '../../models'

const fakeTimeSots: TimeSlotModel[] = [
  {
    date: new Date().setUTCHours(0, 0, 0, 0),
    times: [
      { time: new Date(0).setHours(9, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(10, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(11, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(12, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(13, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(14, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(15, 0, 0, 0), available: false, minPrice: 1200 },
      { time: new Date(0).setHours(16, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(17, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(18, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(19, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(20, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(21, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(22, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(23, 0, 0, 0), available: true, minPrice: 1600 }
    ]
  },
  {
    date: new Date().setUTCHours(0, 0, 0, 0) + 1 * 86400000,
    times: [
      { time: new Date(0).setHours(9, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(10, 0, 0, 0), available: false, minPrice: 1200 },
      { time: new Date(0).setHours(11, 0, 0, 0), available: false, minPrice: 1200 },
      { time: new Date(0).setHours(12, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(13, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(14, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(15, 0, 0, 0), available: false, minPrice: 1200 },
      { time: new Date(0).setHours(16, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(17, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(18, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(19, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(20, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(21, 0, 0, 0), available: false, minPrice: 1600 },
      { time: new Date(0).setHours(22, 0, 0, 0), available: false, minPrice: 1200 },
      { time: new Date(0).setHours(23, 0, 0, 0), available: true, minPrice: 1200 }
    ]
  },
  {
    date: new Date().setUTCHours(0, 0, 0, 0) + 2 * 86400000,
    times: [
      { time: new Date(0).setHours(8, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(9, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(10, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(11, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(12, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(13, 0, 0, 0), available: false, minPrice: 1000 },
      { time: new Date(0).setHours(14, 0, 0, 0), available: false, minPrice: 1000 },
      { time: new Date(0).setHours(15, 0, 0, 0), available: false, minPrice: 1000 },
      { time: new Date(0).setHours(16, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(17, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(18, 0, 0, 0), available: false, minPrice: 1000 },
      { time: new Date(0).setHours(19, 0, 0, 0), available: false, minPrice: 1000 },
      { time: new Date(0).setHours(20, 0, 0, 0), available: false, minPrice: 1000 },
      { time: new Date(0).setHours(21, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(22, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(23, 0, 0, 0), available: false, minPrice: 1000 }
    ]
  },
  {
    date: new Date().setUTCHours(0, 0, 0, 0) + 3 * 86400000,
    times: [
      { time: new Date(0).setHours(8, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(9, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(10, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(11, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(12, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(13, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(14, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(15, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(16, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(17, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(18, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(19, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(20, 0, 0, 0), available: false, minPrice: 1000 },
      { time: new Date(0).setHours(21, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(22, 0, 0, 0), available: false, minPrice: 1000 },
      { time: new Date(0).setHours(23, 0, 0, 0), available: true, minPrice: 1000 }
    ]
  },
  {
    date: new Date().setUTCHours(0, 0, 0, 0) + 4 * 86400000,
    times: [
      { time: new Date(0).setHours(8, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(9, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(10, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(11, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(12, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(13, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(14, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(15, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(16, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(17, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(18, 0, 0, 0), available: false, minPrice: 1000 },
      { time: new Date(0).setHours(19, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(20, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(21, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(22, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(23, 0, 0, 0), available: true, minPrice: 1000 }
    ]
  },
  {
    date: new Date().setUTCHours(0, 0, 0, 0) + 5 * 86400000,
    times: [
      { time: new Date(0).setHours(8, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(9, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(10, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(11, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(12, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(13, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(14, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(15, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(16, 0, 0, 0), available: false, minPrice: 1000 },
      { time: new Date(0).setHours(17, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(18, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(19, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(20, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(21, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(22, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(23, 0, 0, 0), available: true, minPrice: 1000 }
    ]
  },
  {
    date: new Date().setUTCHours(0, 0, 0, 0) + 6 * 86400000,
    times: [
      { time: new Date(0).setHours(8, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(9, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(10, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(11, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(12, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(13, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(14, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(15, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(16, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(17, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(18, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(19, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(20, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(21, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(22, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(23, 0, 0, 0), available: true, minPrice: 1000 }
    ]
  },
  {
    date: new Date().setUTCHours(0, 0, 0, 0) + 7 * 86400000,
    times: [
      { time: new Date(0).setHours(8, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(9, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(10, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(11, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(12, 0, 0, 0), available: true, minPrice: 1400 },
      { time: new Date(0).setHours(13, 0, 0, 0), available: true, minPrice: 1400 },
      { time: new Date(0).setHours(14, 0, 0, 0), available: true, minPrice: 1400 },
      { time: new Date(0).setHours(15, 0, 0, 0), available: true, minPrice: 1400 },
      { time: new Date(0).setHours(16, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(17, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(18, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(19, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(20, 0, 0, 0), available: true, minPrice: 1900 },
      { time: new Date(0).setHours(21, 0, 0, 0), available: true, minPrice: 1900 },
      { time: new Date(0).setHours(22, 0, 0, 0), available: true, minPrice: 1900 },
      { time: new Date(0).setHours(23, 0, 0, 0), available: true, minPrice: 1600 }
    ]
  },
  {
    date: new Date().setUTCHours(0, 0, 0, 0) + 8 * 86400000,
    times: [
      { time: new Date(0).setHours(8, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(9, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(10, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(11, 0, 0, 0), available: true, minPrice: 1200 },
      { time: new Date(0).setHours(12, 0, 0, 0), available: true, minPrice: 1400 },
      { time: new Date(0).setHours(13, 0, 0, 0), available: true, minPrice: 1400 },
      { time: new Date(0).setHours(14, 0, 0, 0), available: true, minPrice: 1400 },
      { time: new Date(0).setHours(15, 0, 0, 0), available: true, minPrice: 1400 },
      { time: new Date(0).setHours(16, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(17, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(18, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(19, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(20, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(21, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(22, 0, 0, 0), available: true, minPrice: 1600 },
      { time: new Date(0).setHours(23, 0, 0, 0), available: true, minPrice: 1600 }
    ]
  },
  {
    date: new Date().setUTCHours(0, 0, 0, 0) + 9 * 86400000,
    times: [
      { time: new Date(0).setHours(8, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(9, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(10, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(11, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(12, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(13, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(14, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(15, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(16, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(17, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(18, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(19, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(20, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(21, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(22, 0, 0, 0), available: true, minPrice: 1000 },
      { time: new Date(0).setHours(23, 0, 0, 0), available: true, minPrice: 1000 }
    ]
  }
]

export { fakeTimeSots }
