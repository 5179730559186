import { ImageItem } from './types'
import { ImageDndItem } from './image-dnd-item'

function ImageDndList({ images, onChange }: { images: ImageItem[]; onChange: (images: ImageItem[]) => void }) {
  function handleMoveCard(dragId: string, hoverId: string) {
    const result = Array.from(images)

    const firstIndex = result.findIndex((el) => el.id === dragId)
    const secondIndex = result.findIndex((el) => el.id === hoverId)

    const [removed] = result.splice(firstIndex, 1)

    result.splice(secondIndex, 0, removed)

    const items = result.map((item, i) => ({ ...item, order: i }))

    onChange(items)
  }

  function handleRemove(id: string) {
    return () => {
      onChange(images.filter((el) => el.id !== id))
    }
  }

  return (
    <>
      {images.map?.((item) => (
        <ImageDndItem key={item.id} item={item} handleRemove={handleRemove} moveCard={handleMoveCard} />
      ))}
    </>
  )
}

export { ImageDndList }
