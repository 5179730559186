import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Flags from 'country-flag-icons/react/3x2'

type ItemProps = {
  selected?: boolean
  onClick?: React.MouseEventHandler<HTMLLIElement | HTMLOptionElement>
  name: string
  iso2: string
  dialCode: string
  localization?: string
}

const Item: React.FC<ItemProps> = (props) => {
  const { name, iso2, dialCode, localization = null, ...restProps } = props

  const FlagComponent = Flags[iso2?.toUpperCase()]

  return (
    <MenuItem data-dial-code="1" data-country-code={iso2} style={{ display: 'flex', gap: '0.5rem' }} {...restProps}>
      {Boolean(FlagComponent) && (
        <div style={{ width: '20px', height: '20px' }}>
          <FlagComponent />
        </div>
      )}
      <span>{localization || name}</span>
      <span>{`+${dialCode}`}</span>
    </MenuItem>
  )
}

export default Item
