import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'

export interface MenuState {
  open: boolean
}

const initialState: MenuState = {
  open: localStorage.getItem('drawer_open') !== 'false'
}

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    handleMenu: (state) => {
      localStorage.setItem('drawer_open', String(!state.open))
      state.open = !state.open
    }
  }
})

function getMenuOpenSelector(state: RootState) {
  return state.menu.open
}

const { handleMenu } = menuSlice.actions

export { handleMenu, getMenuOpenSelector }
export default menuSlice.reducer
