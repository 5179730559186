import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { adminService } from 'shared/api'
import { MainSettingsEditForm } from './main-settings-form'
import { ConfigModel } from 'shared/models'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { useAppDispatch } from 'store'

function MainSettingsPage() {
  const dispatch = useAppDispatch()

  const { data, isFetching } = useQuery(['get-config'], ({ signal }) => adminService.config.getConfig({ signal }), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })

  const { mutateAsync: updateConfig, isLoading: isUpdateLoading } = useMutation((data: ConfigModel) =>
    adminService.config.updateConfig(data)
  )

  async function handleSubmit(data: ConfigModel) {
    try {
      await updateConfig(data)

      dispatch(showMessage({ text: 'Параметры успешно сохранены', type: MessageTypes.Success }))
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, minHeight: 40 }}>
        <Typography variant="h6">Параметры</Typography>
      </Box>
      {!!isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && !!data?.data && (
        <MainSettingsEditForm defaultData={data.data} onSubmit={handleSubmit} isProcess={isUpdateLoading} />
      )}
    </Container>
  )
}

export { MainSettingsPage }
