import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material'
import CreateIcon from '@mui/icons-material/Add'
import { Link, useSearchParams } from 'react-router-dom'
import { pageCreate, pageEdit } from 'pages/nav'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { adminService } from 'shared/api'
import EditIcon from '@mui/icons-material/Edit'
import { SearchInput } from 'features/search-input'

function PageList() {
  const { data, isLoading } = useQuery(['pages'], ({ signal }) => adminService.page.getPageList({ signal }))

  const [searchParams, setSearchParams] = useSearchParams()

  const [term, setTerm] = useState(searchParams.get('term') || '')

  useEffect(() => {
    const search = new URLSearchParams(searchParams)
    if (term) {
      search.set('term', term)
    } else {
      search.delete('term')
    }
    setSearchParams(search)
  }, [term])

  function handleTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTerm(e.target.value)
  }

  const filteredData = data?.data?.filter?.((quest) => quest.name.toLowerCase().includes(term.toLowerCase()))

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6">Список страниц</Typography>
        <Tooltip title="Добавить новую страницу">
          <Link to={pageCreate()}>
            <IconButton>
              <CreateIcon />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
      <Box sx={{ margin: '0 0.5rem 1rem', display: 'flex', gap: '1rem' }}>
        <SearchInput value={term} onChange={handleTermChange} onClear={() => setTerm('')} />
      </Box>
      <List>
        {!!isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading &&
          filteredData?.map?.((page) => (
            <ListItem key={page.id}>
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                    <Typography>{page.name}</Typography>
                  </Box>
                }
                primaryTypographyProps={{ component: 'div' }}
                secondary={page.alias}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Редактировать страницу">
                  <Link to={pageEdit(String(page.id))}>
                    <IconButton>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </Container>
  )
}

export { PageList }
