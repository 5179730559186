import { useRef, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Alert, AlertColor, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { MessageTypes, MessageModel } from 'store/slices/message-slice'

type DurationByType = { [key in MessageTypes]: number }
type AlertColorByType = { [key in MessageTypes]: AlertColor }

const MapTypeDuration: DurationByType = {
  [MessageTypes.Default]: 4000,
  [MessageTypes.Info]: 4000,
  [MessageTypes.Success]: 4000,
  [MessageTypes.Warning]: 4000,
  [MessageTypes.Error]: 10000
}

const MapTypeAlertColor: AlertColorByType = {
  [MessageTypes.Default]: 'info',
  [MessageTypes.Info]: 'info',
  [MessageTypes.Success]: 'success',
  [MessageTypes.Warning]: 'warning',
  [MessageTypes.Error]: 'error'
}

type Props = {
  messages: MessageModel[]
  onClose: (stamp: number) => void
}

function Notifier(props: Props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const displayedRef = useRef<(number | string)[]>([])

  function storeDisplayed(id) {
    displayedRef.current = [...displayedRef.current, id]
  }

  function removeDisplayed(id) {
    displayedRef.current = displayedRef.current.filter((key) => id !== key)
  }

  useEffect(() => {
    props.messages.forEach((message) => {
      const { stamp, type = MessageTypes.Default, text, duration = MapTypeDuration[type] || 4000 } = message
      if (displayedRef.current.includes(stamp)) return

      enqueueSnackbar(text, {
        key: stamp,
        variant: type,
        autoHideDuration: duration,
        action: (
          <div>
            <IconButton aria-label="close" color="inherit" size="small" onClick={() => closeSnackbar(stamp)}>
              <CloseIcon />
            </IconButton>
          </div>
        ),

        anchorOrigin: {
          vertical: type === MessageTypes.Error ? 'top' : 'bottom',
          horizontal: type === MessageTypes.Error ? 'right' : 'center'
        },
        onEnter: () => {
          storeDisplayed(stamp)
        },
        onExited: () => {
          removeDisplayed(stamp)
          props.onClose(stamp)
        },
        content: (key, message) => (
          <Alert
            severity={MapTypeAlertColor[type]}
            elevation={6}
            key={key}
            variant="filled"
            action={
              <IconButton
                style={{ color: 'inherit' }}
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => closeSnackbar(stamp)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        )
      })
    })
  }, [props.messages, closeSnackbar, enqueueSnackbar])

  return null
}

export default Notifier
