import { Controller, useForm } from 'react-hook-form'
import { PriceEditFormProps, PriceFormData } from './types'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidations } from './validation'
import { WeekdaysScheduler } from 'features/weekdays-scheduler'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PersonIcon from '@mui/icons-material/Person'
import { useState } from 'react'

function PriceEditForm({ defaultData, onSubmit, isProcess, serviceId, isEdit, onDelete }: PriceEditFormProps) {
  const [isDeleting, setIsDeleting] = useState(false)

  const { control, handleSubmit, watch, setValue } = useForm<PriceFormData>({
    defaultValues: {
      id: defaultData?.id,
      name: defaultData?.name || '',
      prices: defaultData?.prices || [],
      isPersonPrices: !!defaultData?.prices?.length && defaultData?.prices.length > 1,
      schedule: (defaultData?.schedule || []).map((el) => ({
        startTime: el.startTime,
        endTime: el.endTime,
        isSpecificDate: el.date ? true : false,
        date: el.date,
        weekdays: el.weekdays
      }))
    },
    disabled: isProcess,
    resolver: yupResolver<PriceFormData>(getValidations())
  })

  function handleSaveForm(data: PriceFormData) {
    onSubmit({
      id: data.id,
      name: data.name,
      prices: data.prices,
      serviceId,
      schedule: data.schedule
        .map((s) => ({
          date: s.isSpecificDate ? s.date : null,
          weekdays: s.isSpecificDate ? [] : s.weekdays,
          endTime: s.endTime,
          startTime: s.startTime
        }))
        .filter((s) => !!s.date || !!s.weekdays.length)
    })
  }

  function handleDelete() {
    setIsDeleting(true)
  }

  function cancelDelete() {
    setIsDeleting(false)
  }

  return (
    <>
      <DialogContent style={{ padding: 0 }}>
        <form onSubmit={handleSubmit(handleSaveForm)}>
          <DialogContent>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Название"
                  fullWidth
                  autoComplete="off"
                  placeholder="Название"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="none"
                />
              )}
            />
            <Controller
              control={control}
              name="isPersonPrices"
              defaultValue={false}
              render={({ field }) => (
                <FormControlLabel
                  style={{ margin: '1.5rem 0 1rem', display: 'flex', justifyContent: 'space-between' }}
                  control={
                    <Switch
                      checked={field.value}
                      onChange={(_, val) => {
                        field.onChange(val)

                        if (val === false) {
                          setValue('prices', [
                            {
                              numberOfPersons: null,
                              price: watch('prices')[0]?.price || 0,
                              minimumPrePaymentAmount: watch('prices')[0]?.minimumPrePaymentAmount || undefined
                            }
                          ])
                        } else {
                          setValue('prices', [
                            {
                              numberOfPersons: 1,
                              price: watch('prices')[0]?.price || 0,
                              minimumPrePaymentAmount: watch('prices')[0]?.minimumPrePaymentAmount || undefined
                            }
                          ])
                        }
                      }}
                      onBlur={field.onBlur}
                      disabled={isProcess}
                      size="small"
                    />
                  }
                  label="Цена зависит от количества участников"
                  labelPlacement="start"
                />
              )}
            />
            {!watch('isPersonPrices') && (
              <Controller
                control={control}
                name="prices"
                defaultValue={[{ numberOfPersons: null, price: 0 }]}
                render={({ field, fieldState }) => (
                  <>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                      <TextField
                        label="Цена"
                        fullWidth
                        autoComplete="off"
                        placeholder="Цена"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        value={field.value[0]?.price || ''}
                        onChange={(ev) => {
                          const price = ev.target.value !== '' ? Number(ev.target.value) : null

                          if ((typeof price === 'number' && price >= 0) || price === null) {
                            field.onChange([
                              {
                                numberOfPersons: null,
                                price,
                                minimumPrePaymentAmount: field.value[0]?.minimumPrePaymentAmount || undefined
                              }
                            ])
                          }
                        }}
                        onBlur={field.onBlur}
                        disabled={isProcess}
                        error={!!fieldState.error}
                        ref={field.ref}
                      />
                      <TextField
                        label="Мин. предоплата"
                        fullWidth
                        autoComplete="off"
                        placeholder="Сумма мин. предоплаты"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        value={field.value[0]?.minimumPrePaymentAmount || ''}
                        onChange={(ev) => {
                          const minimumPrePaymentAmount = ev.target.value !== '' ? Number(ev.target.value) : undefined

                          if (
                            (typeof minimumPrePaymentAmount === 'number' && minimumPrePaymentAmount >= 0) ||
                            minimumPrePaymentAmount === undefined
                          ) {
                            field.onChange([
                              {
                                numberOfPersons: null,
                                minimumPrePaymentAmount,
                                price: field.value[0]?.price || null
                              }
                            ])
                          }
                        }}
                        onBlur={field.onBlur}
                        disabled={isProcess}
                        error={!!fieldState.error}
                        ref={field.ref}
                      />
                    </div>
                    {!!fieldState.error &&
                      (fieldState.error as any)?.map?.((f, i) => (
                        <Typography key={i} variant="caption" color="#d32f2f">
                          [{i + 1}]{' '}
                          {Object.values(f || {})
                            .map((e: any) => e.message)
                            .filter(Boolean)
                            .join(', ')}
                        </Typography>
                      ))}
                  </>
                )}
              />
            )}
            {watch('isPersonPrices') && (
              <Controller
                control={control}
                name="prices"
                defaultValue={[]}
                render={({ field, fieldState }) => (
                  <div>
                    <div style={{ display: 'flex', margin: '0.5rem 0', alignItems: 'center', gap: '0.5rem' }}>
                      <div style={{ flex: 1 }}>Количество участников</div>
                      <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <IconButton
                          size="small"
                          disabled={field.value.length < 2}
                          onClick={() => {
                            if (field.value.length > 1) {
                              field.onChange([...field.value.slice(0, -1)])
                            }
                          }}
                        >
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                        {field.value.length || 0}
                        <IconButton
                          size="small"
                          onClick={() =>
                            field.onChange([
                              ...field.value,
                              { numberOfPersons: (field.value?.length || 0) + 1, price: 0 }
                            ])
                          }
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                      {field.value.map((p, i) => (
                        <div key={i} style={{ flex: 0.5 }}>
                          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                              <PersonIcon fontSize="small" color="action" /> {p.numberOfPersons}
                            </div>
                            <TextField
                              label="Цена"
                              fullWidth
                              autoComplete="off"
                              placeholder="Цена"
                              variant="standard"
                              InputLabelProps={{ shrink: true }}
                              style={{ flex: 1, minWidth: '130px' }}
                              value={p.price === 0 ? p.price : p.price || ''}
                              type="number"
                              onChange={(ev) => {
                                const price = ev.target.value !== '' ? Number(ev.target.value) : null

                                if ((typeof price === 'number' && price >= 0) || price === null) {
                                  field.onChange([
                                    ...field.value.slice(0, i),
                                    { ...p, price },
                                    ...field.value.slice(i + 1)
                                  ])
                                }
                              }}
                              disabled={isProcess}
                              margin="dense"
                              error={!!fieldState.error}
                            />
                            <TextField
                              label="Мин. предоплата"
                              fullWidth
                              autoComplete="off"
                              placeholder="Сумма мин. предоплаты"
                              variant="standard"
                              InputLabelProps={{ shrink: true }}
                              style={{ flex: 1, minWidth: '130px' }}
                              value={
                                p.minimumPrePaymentAmount === 0
                                  ? p.minimumPrePaymentAmount
                                  : p.minimumPrePaymentAmount || ''
                              }
                              type="number"
                              onChange={(ev) => {
                                const minimumPrePaymentAmount =
                                  ev.target.value !== '' ? Number(ev.target.value) : undefined

                                if (
                                  (typeof minimumPrePaymentAmount === 'number' && minimumPrePaymentAmount >= 0) ||
                                  minimumPrePaymentAmount === undefined
                                ) {
                                  field.onChange([
                                    ...field.value.slice(0, i),
                                    { ...p, minimumPrePaymentAmount },
                                    ...field.value.slice(i + 1)
                                  ])
                                }
                              }}
                              disabled={isProcess}
                              margin="dense"
                              error={!!fieldState.error}
                            />
                          </div>
                        </div>
                      ))}
                      {!!fieldState.error &&
                        (fieldState.error as any)?.map?.((f, i) => (
                          <Typography key={i} variant="caption" color="#d32f2f">
                            [{i + 1}]{' '}
                            {Object.values(f || {})
                              .map((e: any) => e.message)
                              .filter(Boolean)
                              .join(', ')}
                          </Typography>
                        ))}
                    </div>
                  </div>
                )}
              />
            )}
          </DialogContent>
          <Controller
            control={control}
            name="schedule"
            defaultValue={[]}
            render={({ field }) => <WeekdaysScheduler onChange={field.onChange} value={field.value} />}
          />
        </form>
      </DialogContent>
      <DialogActions>
        {!isDeleting && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
            {isEdit && (
              <Button onClick={handleDelete} disabled={isProcess}>
                Удалить
              </Button>
            )}
            <Button type="submit" variant="outlined" disabled={isProcess} onClick={handleSubmit(handleSaveForm)}>
              Сохранить
            </Button>
          </Box>
        )}
        {isDeleting && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1rem' }}>
            Вы действительно хотите удалить прайс лист?
            <Button onClick={cancelDelete} disabled={isProcess}>
              Отмена
            </Button>
            <Button variant="outlined" color="error" disabled={isProcess} onClick={onDelete}>
              Удалить
            </Button>
          </Box>
        )}
      </DialogActions>
    </>
  )
}

export { PriceEditForm }
