import { EmailTemplateType, ServiceEmailTemplate } from 'shared/models/email-templates'
import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'

const emailTemplates = {
  getEmailTemplates(params: { serviceId?: number; type?: EmailTemplateType }, config?: AxiosRequestConfig) {
    return adminInstance.get<ServiceEmailTemplate[]>(`/emailTemplates`, { params, ...config })
  },

  getEmailTemplate(id: number, config?: AxiosRequestConfig) {
    return adminInstance.get<ServiceEmailTemplate>(`/emailTemplates/${id}`, config)
  },

  createEmailTemplate(data: Omit<ServiceEmailTemplate, 'id'>, config?: AxiosRequestConfig) {
    return adminInstance.post<void>(`/emailTemplates`, data, config)
  },

  updateEmailTemplate(data: ServiceEmailTemplate, config?: AxiosRequestConfig) {
    return adminInstance.put<void>(`/emailTemplates/${data.id}`, data, config)
  },

  deleteEmailTemplate(id: number, config?: AxiosRequestConfig) {
    return adminInstance.delete<void>(`/emailTemplates/${id}`, config)
  }
}

export { emailTemplates }
