import { IconButton, ImageListItem, ImageListItemBar, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { DragItem, ImageItem } from './types'
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import type { Identifier } from 'dnd-core'
import { useRef } from 'react'

function ImageDndItem({
  handleRemove,
  item,
  moveCard
}: {
  item: ImageItem
  handleRemove: (id: string) => () => void
  moveCard: (dragId: string, hoverId: string) => void
}) {
  const dragRef = useRef<HTMLImageElement>(null)

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'image-list',
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() }
    },
    hover(hoverItem: DragItem) {
      if (!dragRef.current) {
        return
      }

      const dragId = hoverItem.id
      const hoverId = item.id

      if (dragId === hoverId) {
        return
      }

      moveCard(dragId, hoverId)
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'image-list',
    item: () => ({ id: item.id }),
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() })
  })

  drag(drop(dragRef))

  return (
    <ImageListItem
      ref={dragRef}
      component={'div'}
      key={item.id}
      style={{ height: '200px', opacity: isDragging ? 0 : 1 }}
    >
      <img srcSet={item.url} src={item.url} loading="lazy" style={{ cursor: 'pointer' }} />
      <ImageListItemBar
        sx={{
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
        }}
        position="top"
        actionIcon={
          <Tooltip title="Удалить">
            <IconButton sx={{ color: 'white' }} onClick={handleRemove(item.id)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
        actionPosition="right"
      />
    </ImageListItem>
  )
}

export { ImageDndItem }
