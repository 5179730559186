import { OrderJournalAction, OrderJournalActionInitiator, OrderJournalItem } from 'shared/models'
import { fakeOrders } from './fake-orders'

export const fakeOrderJournal: OrderJournalItem[] = [
  {
    id: '1',
    dateTime: new Date().setHours(11, 5, 0, 0),
    actionType: OrderJournalAction.OrderCreation,
    actionInitiator: OrderJournalActionInitiator.KvestBattle,
    order: fakeOrders[0]
  },
  {
    id: '2',
    dateTime: new Date().setHours(11, 5, 0, 0),
    actionType: OrderJournalAction.PaymentRequested,
    actionInitiator: OrderJournalActionInitiator.Site,
    order: fakeOrders[0],
    paymentInfo: {
      id: 1,
      link: 'https://pay.alfabank.ru/payment/merchants/ecom2/payment_ru.html?mdOrder=4db14ca5-ec33-7632-b778-b1ba04440ea3',
      amount: 2000
    }
  },
  {
    id: '3',
    dateTime: new Date().setHours(11, 8, 0, 0),
    actionType: OrderJournalAction.PaymentConfirmed,
    actionInitiator: OrderJournalActionInitiator.Auto,
    order: fakeOrders[0],
    paymentInfo: {
      id: 2,
      link: 'https://pay.alfabank.ru/payment/merchants/ecom2/payment_ru.html?mdOrder=4db14ca5-ec33-7632-b778-b1ba04440ea3',
      amount: 2000
    }
  },
  {
    id: '2',
    dateTime: new Date().setHours(13, 47, 0, 0),
    actionType: OrderJournalAction.OrderStatusChanged,
    actionInitiator: OrderJournalActionInitiator.Backoffice,
    order: fakeOrders[0],
    user: {
      id: 1,
      firstName: 'Сергей',
      lastName: 'Иванов',
      email: 'admin@insidequest.ru'
    }
  },
  {
    id: '4',
    dateTime: new Date().setHours(13, 48, 0, 0),
    actionType: OrderJournalAction.OrderUpdate,
    actionInitiator: OrderJournalActionInitiator.Backoffice,
    order: fakeOrders[0],
    user: {
      id: 1,
      firstName: 'Сергей',
      lastName: 'Иванов',
      email: 'admin@insidequest.ru'
    }
  },
  {
    id: '2',
    dateTime: new Date().setHours(13, 49, 0, 0),
    actionType: OrderJournalAction.OrderStaffCommentChanged,
    actionInitiator: OrderJournalActionInitiator.Backoffice,
    order: fakeOrders[0],
    user: {
      id: 1,
      firstName: 'Сергей',
      lastName: 'Иванов',
      email: 'admin@insidequest.ru'
    }
  },
  {
    id: '2',
    dateTime: new Date().setHours(13, 49, 0, 0),
    actionType: OrderJournalAction.PaymentRequested,
    actionInitiator: OrderJournalActionInitiator.Backoffice,
    order: fakeOrders[0],
    paymentInfo: {
      id: 2,
      link: 'https://pay.alfabank.ru/payment/merchants/ecom2/payment_ru.html?mdOrder=4db14ca5-ec33-7632-b778-b1ba04440ea3',
      amount: 1000
    },
    user: {
      id: 1,
      firstName: 'Сергей',
      lastName: 'Иванов',
      email: 'admin@insidequest.ru'
    }
  },
  {
    id: '2',
    dateTime: new Date().setHours(14, 19, 0, 0),
    actionType: OrderJournalAction.PaymentRejected,
    actionInitiator: OrderJournalActionInitiator.Auto,
    order: fakeOrders[0],
    paymentInfo: {
      id: 2,
      link: 'https://pay.alfabank.ru/payment/merchants/ecom2/payment_ru.html?mdOrder=4db14ca5-ec33-7632-b778-b1ba04440ea3',
      amount: 1000
    }
  },
  {
    id: '5',
    dateTime: new Date().setHours(16, 15, 0, 0),
    actionType: OrderJournalAction.OrderCancellation,
    actionInitiator: OrderJournalActionInitiator.Backoffice,
    order: fakeOrders[0],
    user: {
      id: 1,
      firstName: 'Сергей',
      lastName: 'Иванов',
      email: 'admin@insidequest.ru'
    }
  }
]
