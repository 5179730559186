import { Box, Chip, Collapse, IconButton, TableCell, TableRow, Typography, Link as MuiLink } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useState } from 'react'
import { OrderJournalAction, OrderJournalItem } from 'shared/models'
import { orderStatusBackground, orderStatusColor, orderStatusText } from 'pages/orders/status-utils'
import { ReservationWrapper } from 'pages/orders/styled'
import { pluralizationRu } from 'shared/utils/pluralization-ru'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import { jounalActionText, jounalInitiatorText } from './utils'

function OrderJournalTableItem({ item }: { item: OrderJournalItem }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
        <TableCell style={{ width: '36px' }}>
          {[
            OrderJournalAction.OrderCreation,
            OrderJournalAction.OrderUpdate,
            OrderJournalAction.PaymentConfirmed,
            OrderJournalAction.PaymentRejected,
            OrderJournalAction.PaymentRequested
          ].includes(item.actionType) && (
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell align="left" width={'150px'}>
          {Intl.DateTimeFormat('ru', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
          }).format(item.dateTime)}
        </TableCell>
        <TableCell align="left" width={'230px'}>
          {jounalActionText[item.actionType] || item.actionType}
        </TableCell>
        <TableCell align="left" width={'250px'}>
          <div>{jounalInitiatorText[item.actionInitiator] || item.actionInitiator}</div>
          {!!item.user && (
            <div>
              <Typography variant="caption">{item.user.email}</Typography>
            </div>
          )}
          {!!item.user && (
            <div>
              <Typography variant="caption">
                {[item.user.lastName, item.user.firstName].filter(Boolean).join(' ')}
              </Typography>
            </div>
          )}
        </TableCell>
        <TableCell align="left">
          {item.actionType === OrderJournalAction.OrderStaffCommentChanged && <div>{item.order.staffComment}</div>}
          {item.actionType === OrderJournalAction.OrderStatusChanged && !!item.order.status && (
            <Chip
              label={orderStatusText[item.order.status]}
              size="small"
              style={{
                backgroundColor: orderStatusBackground[item.order.status],
                color: orderStatusColor[item.order.status]
              }}
            />
          )}
          {item.actionType === OrderJournalAction.OrderCancellation && (
            <Chip
              label={orderStatusText['Cancelled']}
              size="small"
              style={{
                backgroundColor: orderStatusBackground['Cancelled'],
                color: orderStatusColor['Cancelled']
              }}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {[
                OrderJournalAction.PaymentRequested,
                OrderJournalAction.PaymentRejected,
                OrderJournalAction.PaymentConfirmed
              ].includes(item.actionType) &&
                !!item.paymentInfo && (
                  <div style={{ padding: '0.5rem 1rem' }}>
                    <div>
                      Сумма: <span style={{ color: '#5567f5' }}>{item.paymentInfo.amount} ₽</span>
                    </div>
                    <div>
                      Ссылка на оплату:{' '}
                      <Typography
                        component="a"
                        variant="body2"
                        target="_blank"
                        href={item.paymentInfo.link}
                        style={{ marginTop: '1rem' }}
                      >
                        {item.paymentInfo.link}
                      </Typography>
                    </div>
                  </div>
                )}
              {[OrderJournalAction.OrderCreation, OrderJournalAction.OrderUpdate].includes(item.actionType) &&
                !!item.order && (
                  <div style={{ padding: '0.5rem 1rem' }}>
                    <div>
                      {!!item.order?.customer && (
                        <>
                          <div>
                            <Typography variant="body1" fontWeight={500}>
                              {item.order.customer.name}
                            </Typography>
                            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', marginTop: '2px' }}>
                              <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                <PhoneIcon fontSize="small" style={{ opacity: 0.72, width: '16px', height: '16px' }} />
                                <MuiLink style={{ fontSize: '13px' }} href={`tel:${item.order.customer?.phoneNumber}`}>
                                  {item.order.customer.phoneNumber}
                                </MuiLink>
                              </div>
                              <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                <EmailIcon fontSize="small" style={{ opacity: 0.72, width: '16px', height: '16px' }} />
                                <MuiLink style={{ fontSize: '13px' }} href={`mailto:${item.order.customer?.email}`}>
                                  {item.order.customer.email}
                                </MuiLink>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <Box sx={{ marginTop: '1rem' }}>
                      <Typography variant="caption">Комментарий к заказу</Typography>
                      <Typography style={{ opacity: item.order.comment ? 1 : 0.72 }}>
                        {item.order.comment || 'Не указан'}
                      </Typography>
                    </Box>
                    <div style={{ marginTop: '1rem' }}>
                      {item.order.services
                        ?.sort((a, b) => Number(!!b.service.withReservation) - Number(!!a.service.withReservation))
                        ?.map((service) => (
                          <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>{service.service.name}</div>
                              <div style={{ fontSize: '14px' }}>{service.price} ₽</div>
                            </div>
                            {!!service.reservation && !!service.service.withReservation && (
                              <ReservationWrapper>
                                <Typography fontWeight={500}>Бронирование</Typography>
                                <div
                                  style={{ display: 'flex', gap: '0.5rem 2rem', flexWrap: 'wrap', marginTop: '0.5rem' }}
                                >
                                  <Box>
                                    <Typography variant="caption">Дата</Typography>
                                    <Typography>
                                      {Intl.DateTimeFormat('ru', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                      }).format(service.reservation.startTime)}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="caption">Время</Typography>
                                    <Typography>
                                      {[
                                        Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(
                                          service.reservation.startTime
                                        ),
                                        Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(
                                          service.reservation.endTime
                                        )
                                      ].join(' - ')}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="caption">Кол-во участников</Typography>
                                    <Typography>
                                      {service.reservation.persons}{' '}
                                      {pluralizationRu(service.reservation.persons || 1, [
                                        'человек',
                                        'человека',
                                        'человек'
                                      ])}
                                    </Typography>
                                  </Box>
                                </div>
                              </ReservationWrapper>
                            )}
                          </div>
                        ))}

                      <div style={{ marginTop: '0.5rem', fontSize: '18px', display: 'flex' }}>
                        ИТОГО: {item.order.total || 0} ₽
                      </div>
                    </div>
                  </div>
                )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export { OrderJournalTableItem }
