import styled from '@emotion/styled'

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -1.5rem;
  padding: 0.5rem 1.5rem;
`

const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
`

const ItemActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 1rem;
`

export { ItemContainer, ItemInfo, ItemActions }
