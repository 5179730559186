import { ConfigModel } from 'shared/models'

const fakeConfig: ConfigModel = {
  title: 'InsideQuest - сеть антуражных квестов в Екатеринбурге',
  description:
    'Квесты для взрослых и детей, из них 3 хоррор-квеста с актёром, а также Дом Енотов! \n\nУ нас Вы найдете: квесты на любой вкус - сказочные, веселые, мистические, страшные, уютные зоны отдыха, чистоту и вежливое обслуживание.',
  phone: '+7 (343) 213-0-999',
  navPages: [
    {
      id: 1,
      name: 'Дом енотов',
      linkTo: '/pages/7-raccoon-house',
      order: 1
    },
    {
      id: 2,
      name: 'Сертификаты',
      linkTo: '/pages/10-certificates',
      order: 2
    },
    {
      id: 3,
      name: 'Правила',
      linkTo: '/pages/2-rules',
      order: 3
    },
    {
      id: 4,
      name: 'Контакты',
      linkTo: '/pages/9-contacts',
      order: 4
    }
  ]
}

export { fakeConfig }
