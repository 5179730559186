import { memo, useMemo, useState } from 'react'
import { ColumnItem, SchedulePosition, SchedulerBaseProps } from './types'
import { CalendarContainer, CalendarContent, CalendarFullContainer } from './styled'
import { generataWeekTimeSlots } from './utils'
import { HeaderResources, LeftBarTimes, HeaderWeekDays, MoreAppointment, AppointmentData } from './components'

function SchedulerBaseComponent(props: SchedulerBaseProps) {
  const [openPopover, setOpenPopover] = useState<{ target: HTMLElement; data: ColumnItem[] }>()

  const maxColumnCount = useMemo(() => props.maxColumnCount || 2, [props.maxColumnCount])
  const timeSlotHeight = useMemo(() => props.appointmentHeight || 60, [props.appointmentHeight])
  const timeSlotIntervalInMin = useMemo(
    () => (props.cellDuration && props.cellDuration >= 10 ? props.cellDuration : 30),
    [props.cellDuration]
  )

  const timeSlots = useMemo(
    () =>
      generataWeekTimeSlots(timeSlotIntervalInMin, props.date, props.startDayHour, props.endDayHour, props.currentView),
    [props.date, props.startDayHour, props.endDayHour, props.currentView, timeSlotIntervalInMin]
  )

  const days = useMemo(() => timeSlots.map((el) => el.date), [timeSlots])
  const dayCount = useMemo(() => timeSlots.length, [timeSlots])

  const handleMoreItemsIndicatorClick = useMemo(() => {
    return (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, positions: ColumnItem[]) => {
      setOpenPopover({ target: ev.currentTarget, data: positions })
    }
  }, [])

  const handleClosePopover = useMemo(() => {
    return () => {
      setOpenPopover(undefined)
    }
  }, [])

  const handlePopoverPositionClick = useMemo(() => {
    return (position: SchedulePosition) => {
      setOpenPopover(undefined)
      props.setSelectedPosition(position)
    }
  }, [])

  return (
    <>
      {props.currentView === 'week' && <HeaderWeekDays dayCount={dayCount} days={days} />}
      {!!props.resources?.length && (
        <HeaderResources
          key="header-resources"
          dayCount={dayCount}
          days={days}
          resources={props.resources}
          resourceCellRender={props.resourceCellRender}
        />
      )}
      <CalendarFullContainer>
        <LeftBarTimes timeSlots={timeSlots} timeSlotHeight={timeSlotHeight} />
        <CalendarContainer>
          <CalendarContent
            style={{ gridTemplateColumns: `repeat(${(props.resources?.length || 1) * dayCount}, minmax(50px, 1fr))` }}
          >
            {timeSlots.map((day) => (
              <AppointmentData
                key={day.date}
                day={day}
                dataCellRender={props.dataCellRender}
                positions={props.positions}
                maxColumnCount={maxColumnCount}
                timeSlotHeight={timeSlotHeight}
                timeSlotIntervalInMin={timeSlotIntervalInMin}
                onMoreItemsIndicatorClick={handleMoreItemsIndicatorClick}
                getAppointmentStyles={props.getAppointmentStyles}
                appointmentRender={props.appointmentRender}
                setSelectedPosition={props.setSelectedPosition}
                timeSlots={day.dayTimes}
                resources={props.resources}
              />
            ))}
          </CalendarContent>
        </CalendarContainer>
      </CalendarFullContainer>
      <MoreAppointment
        openPopover={openPopover}
        onClose={handleClosePopover}
        otherAppointmentRender={props.otherAppointmentRender}
        onPositionClick={handlePopoverPositionClick}
      />
    </>
  )
}

export const SchedulerBase = memo(SchedulerBaseComponent)
