import { memo } from 'react'
import { CurrentTimeIndicator } from '../styled'
import { TodayLineComponentProps } from './types'

function TodayLineComponent(props: TodayLineComponentProps) {
  const startPositionTime = new Date().getTime()

  const incomingIntervals = props.timeSlots.filter(
    (time) => time.endTime >= startPositionTime && time.startTime < startPositionTime
  )

  if (!incomingIntervals?.length) {
    return null
  }

  const start = incomingIntervals[0].i + 1

  const minAfterStartSlot = (startPositionTime - incomingIntervals[0].startTime) / 60000
  const mt_startSlot = Math.floor((props.timeSlotHeight / props.timeSlotIntervalInMin) * minAfterStartSlot)

  return <CurrentTimeIndicator style={{ gridRow: `${start} / ${start + 1}`, marginTop: `calc(${mt_startSlot}px)` }} />
}

export const TodayLine = memo(TodayLineComponent)
