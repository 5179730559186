import { EmailTemplateType, ServiceEmailTemplate } from 'shared/models/email-templates'

const fakeEmailTemplates: ServiceEmailTemplate[] = [
  {
    id: 1,
    type: EmailTemplateType.ReservationCreate,
    subject: 'Reservation create subject',
    body: 'Reservation create body',
    serviceId: 1
  },
  {
    id: 2,
    type: EmailTemplateType.ReservationCancel,
    subject: 'Reservation cancel subject',
    body: 'Reservation cancel body',
    serviceId: 1
  }
]

export { fakeEmailTemplates }
