import { Theme } from '@mui/material'
import styled from '@emotion/styled'

const CalendarFullContainer = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
`

const CalendarContainer = styled.div`
  width: 100%;
  overflow: hidden;
`

const CalendarHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  background: #fff;
  border-bottom: solid 1px #ededed;
  position: sticky;
  top: 0;
  z-index: 1001;
`

const CalendarHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, minmax(50px, 1fr));
  grid-template-rows: 40px;
  grid-auto-rows: 120px;
  overflow: auto;
  align-items: center;
  text-align: center;
  font-weight: 400;
  z-index: 999;
`
const CalendarContent = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, minmax(50px, 1fr));
  text-align: center;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
`
const CalendarColumn = styled.div`
  display: grid;
  width: 100%;
  grid-auto-rows: 60px;
  position: relative;
`
const CalendarDataEl = styled.div`
  border-left: solid #00000012 1px;
  border-bottom: solid #00000012 1px;
  cursor: pointer;

  &:hover {
    background: #0000000d;
  }
`

const CalendarLeftHeader = styled.div`
  position: sticky;
  left: 0;
  background: #fff;
  width: 60px;
  height: 40px;
  z-index: 1000;
`

const CalendarLeftEl = styled.div`
  text-align: right;
  padding-right: 0.5rem;
  font-weight: 300;
  font-size: 14px;
`

const CalendarLeftColumn = styled.div`
  display: grid;
  grid-auto-rows: 60px;
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 1000;
`

const CalendarDataAppointment = styled.div`
  cursor: pointer;
  position: absolute;
  width: -webkit-fill-available;
  border: solid 1px #0003;
  background: rgb(230, 230, 230);
  margin-left: -1px;
  text-align: start;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    min-height: 50px;
    z-index: 999;
  }
`

const MoreItemsIndicator = styled.div`
  cursor: pointer;
  width: 21px;
  height: 21px;
  position: absolute;
  right: 2px;
  top: -0.75rem;
  background: #db214d;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    z-index: 9999;
  }
`

const PositionStateIndicator = styled.div`
  width: 12px;
  height: 12px;
  min-width: 12px;
  border-radius: 100%;
`

const OtherPositionList = styled.div`
  width: 100%;
  text-align: left;
  min-height: 40px;
  padding: 0.5rem;
  box-sizing: border-box;
  border-bottom: solid 1px #0000001c;
  display: flex;
  justify-content: start;
  gap: 4px;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
`

const CurrentTimeIndicator = styled.div`
  height: 1px;
  width: 100%;
  position: absolute;
  border-bottom: dashed 1px #000;
  z-index: 999;
`

const HideControl = styled.div<{ break?: boolean; exceededWaiting?: boolean; readonly?: boolean }>`
  padding: 2px 3px;
  flex: 1;
  height: -webkit-fill-available;
  border-left: ${(props) => {
    if (props.readonly) {
      return 'solid 2px #000'
    }

    if (props.break) {
      return 'solid 2px #367cf4'
    }

    return props.exceededWaiting ? 'solid 2px #f44336;' : 'none'
  }};
`

const CellTitle = styled.div`
  font-size: 14px;
  display: flex;
`

const CellName = styled.div`
  margin: 2px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const RightBarWrapper = styled.div<{ theme?: Theme }>`
  z-index: 1100;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 132px);
  overflow: auto;
  width: 420px;
  background: ${(props) => props.theme.palette.background.paper || '#fff'};
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 1001;
`

const RightBarActions = styled.div<{ theme?: Theme }>`
  padding: 1rem;
  position: sticky;
  bottom: 0;
  background: ${(props) => props.theme.palette.background.paper || '#fff'};
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  border-top: solid 1px ${(props) => props.theme.palette.divider};
`

const CloseButtonWrapper = styled.div`
  padding: 0.5rem 0.5rem 0;
  display: flex;
  justify-content: flex-end;
`

const ReservationWrapper = styled.div`
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  max-width: 400px;
  width: fit-content;
`

export {
  CalendarColumn,
  CalendarContainer,
  CalendarContent,
  CalendarDataAppointment,
  CalendarDataEl,
  CalendarFullContainer,
  CalendarHeader,
  CalendarHeaderContainer,
  CalendarLeftColumn,
  CalendarLeftEl,
  CalendarLeftHeader,
  MoreItemsIndicator,
  PositionStateIndicator,
  OtherPositionList,
  CurrentTimeIndicator,
  CellName,
  CellTitle,
  HideControl,
  RightBarWrapper,
  CloseButtonWrapper,
  ReservationWrapper,
  RightBarActions
}
