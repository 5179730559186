import { http, HttpResponse, delay } from 'msw'
import { fakeConfig } from '../fake-data/fake-config'

export const config = [
  http.get('/api/config', async () => {
    await delay(900)

    return HttpResponse.json(fakeConfig)
  }),

  http.put('/api/config', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  })
]
