import ClearIcon from '@mui/icons-material/Close'
import { IconButton, TextField } from '@mui/material'
import { SearchInputProps } from './type'
import { useRef } from 'react'

function SearchInput({ value, onChange, onClear, label, placeholder, withoutLabel }: SearchInputProps) {
  const inputRef = useRef<HTMLInputElement>()

  function handleClear(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onClear?.(ev)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <TextField
      variant="standard"
      fullWidth
      label={withoutLabel ? undefined : label || 'Поиск'}
      inputProps={{ ref: inputRef }}
      InputProps={{
        endAdornment: onClear ? (
          <IconButton size="small" onClick={handleClear} disabled={!value}>
            <ClearIcon fontSize="small" />
          </IconButton>
        ) : undefined
      }}
      InputLabelProps={{ shrink: withoutLabel ? false : true }}
      placeholder={placeholder || 'Поиск...'}
      value={value}
      onChange={onChange}
    />
  )
}

export { SearchInput }
