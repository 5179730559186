import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'
import { QuestCreateModel, QuestModel } from 'shared/models'

const quest = {
  getQuestList(config?: AxiosRequestConfig) {
    return adminInstance.get<QuestModel[]>('/quests', { ...config })
  },

  getQuest(questId: number, config?: AxiosRequestConfig) {
    return adminInstance.get<QuestModel>(`/quests/${questId}`, config)
  },

  createQuest(data: QuestCreateModel, config?: AxiosRequestConfig) {
    return adminInstance.post<void>('/quests', data, config)
  },

  updateQuest(questId: number, data: QuestModel, config?: AxiosRequestConfig) {
    return adminInstance.put<void>(`/quests/${questId}`, data, config)
  },

  deleteQuest(questId: number, config?: AxiosRequestConfig) {
    return adminInstance.delete<void>(`/quests/${questId}`, config)
  },

  updateQuestOrders(data: { id: number; order: number }[], config?: AxiosRequestConfig) {
    return adminInstance.patch<void>('/quests/updateOrders', data, config)
  }
}

export { quest }
