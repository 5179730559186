import { Controller, useForm } from 'react-hook-form'
import { UserEditFormProps } from './types'
import { CreateUserModel, EditUserModel } from 'shared/models'
import { Box, Button, Card, MenuItem, Tab, TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidations } from './validation'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useState } from 'react'

function UserEditForm({ defaultData, onSubmit, isProcess, onDelete, isEdit, isSelf }: UserEditFormProps) {
  const [isDeleting, setIsDeleting] = useState(false)

  const { control, handleSubmit } = useForm<EditUserModel | CreateUserModel>({
    defaultValues: defaultData,
    disabled: isProcess,
    resolver: yupResolver<EditUserModel | CreateUserModel>(getValidations())
  })

  function handleDelete() {
    setIsDeleting(true)
  }

  function cancelDelete() {
    setIsDeleting(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card elevation={1}>
        <TabContext value="main">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList>
              <Tab label="Основная информация" value="main" />
            </TabList>
          </Box>
          <TabPanel value="main">
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Email"
                  fullWidth
                  type="email"
                  autoComplete="off"
                  placeholder="Email"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="none"
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Фамилия"
                  fullWidth
                  placeholder="Фамилия"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={field.disabled}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="firstName"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Имя"
                  fullWidth
                  placeholder="Имя"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={field.disabled}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="profile"
              defaultValue="Manager"
              render={({ field, fieldState }) => (
                <TextField
                  label="Профиль"
                  fullWidth
                  select
                  placeholder="Профиль"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={field.disabled}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                >
                  <MenuItem key="Admin" value="Admin">
                    Администратор
                  </MenuItem>
                  <MenuItem key="Manager" value="Manager">
                    Менеджер
                  </MenuItem>
                </TextField>
              )}
            />
            <Controller
              control={control}
              name="newPassword"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Пароль"
                  fullWidth
                  placeholder="Новый пароль"
                  type="password"
                  variant="standard"
                  autoComplete="new-password"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={field.disabled}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
          </TabPanel>
        </TabContext>
      </Card>
      {!isDeleting && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: '1rem' }}>
          {isEdit && !isSelf && (
            <Button onClick={handleDelete} disabled={isProcess}>
              Удалить
            </Button>
          )}
          <Button type="submit" variant="outlined" disabled={isProcess}>
            Сохранить
          </Button>
        </Box>
      )}
      {isDeleting && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 3, gap: '1rem' }}>
          Вы действительно хотите удалить сотрудника?
          <Button onClick={cancelDelete} disabled={isProcess}>
            Отмена
          </Button>
          <Button variant="outlined" color="error" disabled={isProcess} onClick={onDelete}>
            Удалить
          </Button>
        </Box>
      )}
    </form>
  )
}

export { UserEditForm }
