import { Box, CircularProgress, DialogTitle } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { adminService } from 'shared/api'
import { ServiceScheduleCreateModel, ServiceScheduleModel } from 'shared/models'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { ScheduleEditProps } from './types'
import { ScheduleEditForm } from './schedule-edit-form'

function ScheduleEdit({ scheduleId, serviceId, onClose }: ScheduleEditProps) {
  const dispatch = useAppDispatch()

  const { data, isFetching } = useQuery(
    ['schedule', scheduleId],
    ({ signal }) => adminService.service.getServiceSchedule(Number(scheduleId), { signal }),
    { enabled: !!scheduleId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { mutateAsync: createSchedule, isLoading: isCreateLoading } = useMutation((data: ServiceScheduleCreateModel) =>
    adminService.service.createServiceSchedule(data)
  )
  const { mutateAsync: updateSchedule, isLoading: isUpdateLoading } = useMutation((data: ServiceScheduleModel) =>
    adminService.service.updateServiceSchedule(data.id, data)
  )
  const { mutateAsync: deleteSchedule, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.service.deleteServiceSchedule(id)
  )

  async function handleSubmit(data: ServiceScheduleModel | ServiceScheduleCreateModel) {
    try {
      if ('id' in data && data.id) {
        await updateSchedule(data)
      } else {
        await createSchedule(data as ServiceScheduleCreateModel)
      }

      onClose()
      dispatch(showMessage({ text: 'Расписание сохранено', type: MessageTypes.Success }))
    } catch (e) {
      console.log(e)
    }
  }

  async function handleDelete() {
    if (!data?.data) {
      return
    }

    try {
      await deleteSchedule(data.data.id)

      dispatch(showMessage({ text: 'Расписание успешно удалено', type: MessageTypes.Success }))
      onClose()
    } catch (e) {
      console.log(e)
    }
  }

  const isEdit = !!scheduleId

  return (
    <>
      <DialogTitle>
        {isEdit ? 'Редактирование расписания бронирования' : 'Создание расписания бронирования'}
      </DialogTitle>
      {!!isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && (
        <ScheduleEditForm
          isEdit={isEdit}
          defaultData={data?.data}
          onSubmit={handleSubmit}
          isProcess={isCreateLoading || isUpdateLoading || isDeleteLoading}
          serviceId={serviceId}
          onDelete={handleDelete}
        />
      )}
    </>
  )
}

export { ScheduleEdit }
