import { QuestCreateModel } from 'shared/models'

const questCreateData: QuestCreateModel = {
  name: '',
  description: '',
  addressId: undefined as any,
  mainServiceId: undefined as any,
  additionalServiceIds: [],
  ageRating: 0,
  categories: [],
  complexity: 0,
  headerImageUrl: '',
  htmlDescription: '',
  htmlKeywords: '',
  htmlTitle: '',
  previewImageUrl: '',
  htmlWidget: ''
}

export { questCreateData }
