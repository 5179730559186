import { memo } from 'react'
import { Column } from './column'
import { Cells } from './cells'
import { Appointments } from './appointments'
import { TodayLine } from './today-line'
import { AppointmentDataComponentProps } from './types'

function AppointmentDataComponent(props: AppointmentDataComponentProps) {
  return (
    <>
      {props.resources?.map((resource) => (
        <Column timeSlotHeight={props.timeSlotHeight} key={resource.id}>
          <Cells day={props.day} dataCellRender={props.dataCellRender} resourceId={resource.id} />
          <Appointments
            day={props.day}
            positions={props.positions}
            resource={resource}
            maxColumnCount={props.maxColumnCount}
            timeSlotHeight={props.timeSlotHeight}
            timeSlotIntervalInMin={props.timeSlotIntervalInMin}
            onMoreItemsIndicatorClick={props.onMoreItemsIndicatorClick}
            getAppointmentStyles={props.getAppointmentStyles}
            appointmentRender={props.appointmentRender}
            setSelectedPosition={props.setSelectedPosition}
          />
          <TodayLine
            timeSlots={props.timeSlots}
            timeSlotHeight={props.timeSlotHeight}
            timeSlotIntervalInMin={props.timeSlotIntervalInMin}
          />
        </Column>
      ))}
      {!props.resources?.length && (
        <Column timeSlotHeight={props.timeSlotHeight} key="singlie-view">
          <Cells day={props.day} dataCellRender={props.dataCellRender} />
          <Appointments
            day={props.day}
            positions={props.positions}
            maxColumnCount={props.maxColumnCount}
            timeSlotHeight={props.timeSlotHeight}
            timeSlotIntervalInMin={props.timeSlotIntervalInMin}
            onMoreItemsIndicatorClick={props.onMoreItemsIndicatorClick}
            getAppointmentStyles={props.getAppointmentStyles}
            appointmentRender={props.appointmentRender}
            setSelectedPosition={props.setSelectedPosition}
          />
          <TodayLine
            timeSlots={props.timeSlots}
            timeSlotHeight={props.timeSlotHeight}
            timeSlotIntervalInMin={props.timeSlotIntervalInMin}
          />
        </Column>
      )}
    </>
  )
}

export const AppointmentData = memo(AppointmentDataComponent)
