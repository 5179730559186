import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { UserEditForm } from 'pages/users/user-edit-form'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { adminService } from 'shared/api'
import { CreateUserModel, EditUserModel } from 'shared/models'
import { useAppDispatch, useAppSelector } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { userList } from 'pages/nav'
import { getUserProfileSelector } from 'store/slices/profile-slice'

function UserEdit() {
  const profile = useAppSelector(getUserProfileSelector)
  
  const { userId } = useParams<{ userId?: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data, isFetching } = useQuery(
    ['user', userId],
    ({ signal }) => adminService.user.getUser(Number(userId), { signal }),
    { enabled: !!userId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { mutateAsync: createUser, isLoading: isCreateLoading } = useMutation((data: CreateUserModel) =>
    adminService.user.createUser(data)
  )
  const { mutateAsync: updateUser, isLoading: isUpdateLoading } = useMutation((data: EditUserModel) =>
    adminService.user.updateUser(data.id, data)
  )
  const { mutateAsync: deleteUser, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.user.deleteUser(id)
  )

  async function handleSubmit(data: CreateUserModel | EditUserModel) {
    try {
      if ('id' in data && data.id) {
        await updateUser(data)
      } else {
        await createUser(data)
      }

      dispatch(showMessage({ text: 'Данные сотрудника успешно сохранены', type: MessageTypes.Success }))
      navigate(userList() + '?term=' + data.email)
    } catch (e) {
      console.log(e)
    }
  }

  async function handleDelete() {
    if (!data?.data) {
      return
    }

    try {
      await deleteUser(data.data.id)

      dispatch(showMessage({ text: 'Пользователь успешно удален', type: MessageTypes.Success }))
      navigate(userList())
    } catch (e) {
      console.log(e)
    }
  }

  const isEdit = !!userId

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, minHeight: 40 }}>
        <Typography variant="h6">{isEdit ? 'Редактирование сотрудника' : 'Добавление сотрудника'}</Typography>
      </Box>
      {!!isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && (
        <UserEditForm
          defaultData={data?.data}
          isEdit={isEdit}
          onSubmit={handleSubmit}
          isProcess={isCreateLoading || isUpdateLoading || isDeleteLoading}
          onDelete={handleDelete}
          isSelf={profile?.id === data?.data?.id}
        />
      )}
    </Container>
  )
}

export { UserEdit }
