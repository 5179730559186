import { CircularProgress, IconButton, ImageList, ImageListItem, Tooltip, Typography } from '@mui/material'
import { ImageGalleryProps, ImageQueueItem } from './types'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { nativeFilesSelect } from 'shared/utils/file'
import { useEffect, useRef, useState } from 'react'
import { adminService } from 'shared/api'
import ReplayIcon from '@mui/icons-material/Replay'
import CloseIcon from '@mui/icons-material/Close'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ImageDndList } from './image-dnd-list'
import imageCompression from 'browser-image-compression'

function ImageGallery({ images, onChange }: ImageGalleryProps) {
  const imageListRef = useRef(images)

  const imageQueueListRef = useRef<ImageQueueItem[]>([])
  const [imageQueueChangeTime, setImageQueueChangeTime] = useState(Date.now())
  const uploadingIsStarted = useRef(false)

  useEffect(() => {
    imageListRef.current = images
  }, [images])

  useEffect(() => {
    if (imageQueueListRef.current.filter((el) => el.status === 'inQueue').length && !uploadingIsStarted.current) {
      tryToStartUploadingProcess(0)
    }
  }, [imageQueueChangeTime])

  function tryToStartUploadingProcess(i: number) {
    if (imageQueueListRef.current[i]) {
      tryToUploadImages(imageQueueListRef.current[i])
      imageQueueListRef.current[i].status = 'pending'
      setImageQueueChangeTime(Date.now())
    } else {
      uploadingIsStarted.current = false
    }
  }

  async function tryToUploadImages(item: ImageQueueItem) {
    uploadingIsStarted.current = true

    try {
      const formData = new FormData()

      const output = await imageCompression(item.file, {
        maxSizeMB: 512,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      })

      const file = new File([output], item.file?.name || 'file.jpg', {
        lastModified: item.file?.lastModified,
        type: item.file?.type
      })

      formData.append('file', file)

      const res = await adminService.file.upload(formData)

      if (res.data.url) {
        onChange([
          ...imageListRef.current,
          { id: `${Date.now()}-${Math.floor(Math.random() * 1000)}`, url: res.data.url }
        ])

        const items = [...imageQueueListRef.current].filter((el) => el.id !== item?.id)
        const nextIndex = items.findIndex((x) => x.status === 'inQueue')

        imageQueueListRef.current = items

        setImageQueueChangeTime(Date.now())

        tryToStartUploadingProcess(nextIndex)
      }
    } catch (err) {
      const items = [...imageQueueListRef.current]

      const foundItemIndex = items.findIndex((x) => x.id === item.id)

      if (foundItemIndex !== -1) {
        items[foundItemIndex].status = 'error'

        tryToStartUploadingProcess(foundItemIndex + 1)
      }

      imageQueueListRef.current = items
      setImageQueueChangeTime(Date.now())
    }
  }

  function handleRestartUploadItem(id: string) {
    return () => {
      const newList = imageQueueListRef.current
      const item = newList.find((el) => el.id === id)

      if (item) {
        item.status = 'inQueue'
        setImageQueueChangeTime(Date.now())
      }
    }
  }

  function handleRemoveUploadItem(id: string) {
    return () => {
      const newList = imageQueueListRef.current.filter((el) => el.id !== id)
      imageQueueListRef.current = newList
      setImageQueueChangeTime(Date.now())
    }
  }

  async function handleUploadImages() {
    try {
      const files = await nativeFilesSelect({
        multiple: true,
        accept: '.apng, .png, .avif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .webp'
      })

      const newList = [...imageQueueListRef.current]

      for (const file of files) {
        newList.push({ id: Date.now() + file.name, file, status: 'inQueue', progress: 10 })
      }

      imageQueueListRef.current = newList
      setImageQueueChangeTime(Date.now())
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div
        style={{
          background: '#f2f2f2',
          padding: '0.5rem 1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: '0.5rem',
          gap: '1rem'
        }}
        onClick={handleUploadImages}
      >
        <CloudUploadIcon fontSize="small" color="action" />
        <Typography variant="subtitle1" fontWeight="500" color="GrayText">
          Загрузить
        </Typography>
      </div>
      <ImageList
        style={{
          width: '100%',
          gridTemplateColumns: 'repeat(auto-fit, minmax(215px, 260px))',
          marginBlockStart: 0,
          marginBlockEnd: 0
        }}
        variant="quilted"
      >
        <DndProvider backend={HTML5Backend} key="gallery-image-list">
          <ImageDndList images={images} onChange={onChange} />
        </DndProvider>
        {imageQueueListRef.current.map((item) => (
          <ImageListItem key={item.id} style={{ height: '200px' }}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: '100%',
                height: '100%',
                background: item.status === 'error' ? 'rgba(80, 1, 1, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {item.status === 'pending' && <CircularProgress style={{ color: '#FFF' }} />}
              {item.status === 'error' && (
                <Tooltip title="Повторить">
                  <IconButton style={{ color: '#FFF' }} onClick={handleRestartUploadItem(item.id)}>
                    <ReplayIcon />
                  </IconButton>
                </Tooltip>
              )}
              {item.status !== 'pending' && (
                <Tooltip title="Удалить">
                  <IconButton style={{ color: '#FFF' }} onClick={handleRemoveUploadItem(item.id)}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <img srcSet={URL.createObjectURL(item.file)} src={URL.createObjectURL(item.file)} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  )
}

export { ImageGallery }
