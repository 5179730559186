import { createContext } from 'react'
import { ThemeMode } from './types'

const modeContext = createContext<{ mode: ThemeMode; setMode: (mode: ThemeMode) => void }>({
  mode: 'system',
  setMode: () => {}
})

const { Provider: ModeProvider } = modeContext

export { modeContext, ModeProvider }
