import { SchedulerOrderModel } from 'shared/models'
import { CloseButtonWrapper, ReservationWrapper, RightBarActions, RightBarWrapper } from './styled'
import {
  Box,
  Chip,
  Divider,
  IconButton,
  Typography,
  Link as MuiLink,
  Dialog,
  DialogTitle,
  Button,
  Tooltip
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { orderStatusBackground, orderStatusColor, orderStatusText, paymentStatusText } from 'pages/orders/status-utils'
import { pluralizationRu } from 'shared/utils/pluralization-ru'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import EditIcon from '@mui/icons-material/Edit'
import ViewIcon from '@mui/icons-material/EditNote'
import { EditOrderStatusDialog } from 'pages/orders/edit-status-dialog'
import { EditStaffCommentDialog } from 'pages/orders/edit-staff-comment-dialog'
import { useState } from 'react'
import { OrderEditForm } from 'pages/orders/order-edit-form'
import { adminService } from 'shared/api'
import { GettingPaymentLinkDialog } from 'features/getting-payment-link-dialog'
import CopyIcon from '@mui/icons-material/FileCopy'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { useAppDispatch } from 'store'
import { NavLink } from 'react-router-dom'
import { orderView } from 'pages/nav'
import DuplicateIcon from '@mui/icons-material/PersonAddAlt'
import { jounalInitiatorText } from 'features/order-journal/utils'

function SchedulerOrder({
  order,
  onClose,
  onRefetch
}: {
  order: SchedulerOrderModel
  onClose: () => void
  onRefetch: () => void
}) {
  const [editStatusDialogOpen, setEditStatusDialogOpen] = useState(false)
  const [editStaffCommentDialogOpen, setEditStaffCommentDialogOpen] = useState(false)
  const [editOrderDialogOpen, setEditOrderDialogOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [gettingPaymentLinkDialogOpen, setGettingPaymentLinkDialogOpen] = useState(false)
  const [orderCreateDialogOpen, setOrderCreateDialogOpen] = useState(false)

  const dispatch = useAppDispatch()

  async function onDelete() {
    setIsDeleting(false)
    setProcessing(true)

    try {
      await adminService.order.cancelOrder(order.id)
    } finally {
      setProcessing(false)
      onClose()
      onRefetch()
    }
  }

  function handleStatusChange() {
    setEditStatusDialogOpen(true)
  }

  function closeEditStatusDialog() {
    setEditStatusDialogOpen(false)
    onRefetch()
  }

  function handleStaffCommentChange() {
    setEditStaffCommentDialogOpen(true)
  }

  function closeEditStaffCommentDialog() {
    setEditStaffCommentDialogOpen(false)
    onRefetch()
  }

  function handleEditOrder() {
    setEditOrderDialogOpen(true)
  }

  function closeEditOrderDialog() {
    setEditOrderDialogOpen(false)
    onRefetch()
  }

  function cancelDelete() {
    setIsDeleting(false)
  }

  function handleDelete() {
    setIsDeleting(true)
  }

  function handlePaymentLinkClick() {
    setGettingPaymentLinkDialogOpen(true)
  }

  function closeGettingPaymentLinkDialog() {
    setGettingPaymentLinkDialogOpen(false)
  }

  function handleLinkCopy(paymentLink: string) {
    return () => {
      navigator.clipboard.writeText(paymentLink)
      dispatch(showMessage({ text: 'Ссылка скопирована в буфер обмена', type: MessageTypes.Success }))
    }
  }

  function handleCreate() {
    setOrderCreateDialogOpen(true)
  }

  function closeOrderCreateDialog() {
    setOrderCreateDialogOpen(false)
    onRefetch()
  }

  const isPrevOrder =
    order.mainService.reservation?.startTime &&
    new Date(order.mainService.reservation.startTime).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)

  return (
    <>
      <RightBarWrapper>
        <CloseButtonWrapper>
          <Tooltip title="Создать новый заказ для данного клиента">
            <IconButton onClick={handleCreate}>
              <DuplicateIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Открыть страницу заказа в новом окне">
            <NavLink to={orderView(order.id)} target="_blank">
              <IconButton>
                <ViewIcon />
              </IconButton>
            </NavLink>
          </Tooltip>
          <Tooltip title="Закрыть">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </CloseButtonWrapper>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '0.5rem',
            justifyContent: 'space-between',
            mb: 3,
            minHeight: 40,
            margin: '0 1.5rem'
          }}
        >
          <Typography variant="caption" style={{ opacity: 0.72 }}>
            {Intl.DateTimeFormat('ru', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            }).format(order.dateTime)}
          </Typography>
          {!!order.creationInitiaton && (
            <Chip label={jounalInitiatorText[order.creationInitiaton]} variant="outlined" size="small" />
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div style={{ padding: '0 1.5rem 1rem' }}>
            {!!order?.customer && (
              <div>
                <Typography variant="body1" fontWeight={500}>
                  {order.customer.name}
                </Typography>
                <div style={{ display: 'flex', marginTop: '0.25rem', gap: '0.5rem', alignItems: 'center' }}>
                  <PhoneIcon fontSize="small" style={{ opacity: 0.72, width: '16px', height: '16px' }} />
                  <MuiLink style={{ fontSize: '13px' }} href={`tel:${order.customer?.phoneNumber}`}>
                    {order.customer.phoneNumber}
                  </MuiLink>
                </div>
                <div style={{ display: 'flex', marginTop: '0.25rem', gap: '0.5rem', alignItems: 'center' }}>
                  <EmailIcon fontSize="small" style={{ opacity: 0.72, width: '16px', height: '16px' }} />
                  <MuiLink style={{ fontSize: '13px' }} href={`mailto:${order.customer?.email}`}>
                    {order.customer.email}
                  </MuiLink>
                </div>
              </div>
            )}
            <div style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
              <Box sx={{ marginTop: '1rem' }}>
                <Typography variant="caption">Комментарий к заказу</Typography>
                <Typography style={{ opacity: order.comment ? 1 : 0.72 }}>{order.comment || 'Не указан'}</Typography>
              </Box>
              <Box>
                <Typography variant="caption">Статус</Typography>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  {!!order.status && (
                    <Chip
                      label={orderStatusText[order.status]}
                      size="small"
                      style={{
                        backgroundColor: orderStatusBackground[order.status],
                        color: orderStatusColor[order.status]
                      }}
                    />
                  )}
                  {order.status !== 'Cancelled' && (
                    <Tooltip title="Изменить статус заказа">
                      <IconButton size="small" onClick={handleStatusChange}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </Box>
              <Box>
                <Typography variant="caption">Оплата</Typography>
                <Typography>{paymentStatusText[order.paymentStatus] || 'Не запрашивалась'}</Typography>
                {!!order.prePaymentAmount && (
                  <Typography variant="body2" color="#4e4e4e">
                    Внесено: <span style={{ color: '#5567f5' }}>{order.prePaymentAmount} ₽</span>
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  color="#5567f5"
                  style={{ cursor: 'pointer' }}
                  onClick={handlePaymentLinkClick}
                >
                  Получить ссылку на оплату
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">Комментарий сотрудника</Typography>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <Typography style={{ opacity: order.staffComment ? 1 : 0.72 }}>
                    {order.staffComment || 'Не указан'}
                  </Typography>
                  <Tooltip title="Изменить комментарий сотрудника к заказу">
                    <IconButton size="small" onClick={handleStaffCommentChange}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              </Box>
              {!!order.orderWebsiteLink && (
                <Box>
                  <Typography variant="caption">Ссылка на заказ</Typography>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <Typography component="a" target="_blank" href={order.orderWebsiteLink}>
                      {order.orderWebsiteLink}
                    </Typography>
                    <Tooltip title="Скопировать в буфер обмена">
                      <IconButton size="small" onClick={handleLinkCopy(order.orderWebsiteLink)}>
                        <CopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Box>
              )}
            </div>
          </div>
          <Divider />
          <div style={{ padding: '1rem 1.5rem', flex: 1 }}>
            {order.mainService && (
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>{order.mainService.service.name}</div>
                  <div style={{ fontSize: '14px' }}>{order.mainService.price} ₽</div>
                </div>
                {!!order.mainService.reservation && (
                  <ReservationWrapper>
                    <Typography fontWeight={500}>Бронирование</Typography>
                    <div style={{ display: 'flex', gap: '0.5rem 2rem', flexWrap: 'wrap', marginTop: '0.5rem' }}>
                      <Box>
                        <Typography variant="caption">Дата</Typography>
                        <Typography>
                          {Intl.DateTimeFormat('ru', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(
                            order.mainService.reservation.startTime
                          )}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption">Время</Typography>
                        <Typography>
                          {[
                            Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(
                              order.mainService.reservation.startTime
                            ),
                            Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(
                              order.mainService.reservation.endTime
                            )
                          ].join(' - ')}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption">Кол-во участников</Typography>
                        <Typography>
                          {order.mainService.reservation.persons}{' '}
                          {pluralizationRu(order.mainService.reservation.persons || 1, [
                            'человек',
                            'человека',
                            'человек'
                          ])}
                        </Typography>
                      </Box>
                    </div>
                  </ReservationWrapper>
                )}
              </div>
            )}
            {order.additionalServices?.map((service) => (
              <div key={service.id} style={{ display: 'flex', justifyContent: 'space-between', opacity: 0.64 }}>
                <div>+ {service.service.name}</div>
                <div style={{ fontSize: '14px' }}>{service.price} ₽</div>
              </div>
            ))}
            <div style={{ marginTop: '1rem', fontSize: '18px', display: 'flex', justifyContent: 'flex-end' }}>
              ИТОГО: {order.total} ₽
            </div>
          </div>
          {!isPrevOrder && order.status !== 'Cancelled' && (
            <RightBarActions>
              {!isDeleting && (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="error"
                    onClick={handleDelete}
                    disabled={processing}
                  >
                    Отменить заказ
                  </Button>
                  <Button type="submit" fullWidth variant="outlined" disabled={processing} onClick={handleEditOrder}>
                    Редактировать
                  </Button>
                </Box>
              )}
              {!!isDeleting && (
                <Box>
                  Вы действительно хотите отменить заказ?
                  <Box sx={{ display: 'flex', mt: 2, gap: '1rem' }}>
                    <Button fullWidth variant="outlined" onClick={cancelDelete} disabled={processing}>
                      Отмена
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      disableElevation
                      color="error"
                      disabled={processing}
                      onClick={onDelete}
                    >
                      Подтвердить
                    </Button>
                  </Box>
                </Box>
              )}
            </RightBarActions>
          )}
        </Box>
        <EditOrderStatusDialog
          orderId={order.id}
          initialStatus={order.status}
          open={editStatusDialogOpen}
          onClose={closeEditStatusDialog}
        />
        <EditStaffCommentDialog
          orderId={order.id}
          initialStaffComment={order.staffComment}
          open={editStaffCommentDialogOpen}
          onClose={closeEditStaffCommentDialog}
        />
        <Dialog open={editOrderDialogOpen} onClose={closeEditOrderDialog} fullWidth>
          <DialogTitle>Редактирование заказа</DialogTitle>
          <OrderEditForm orderId={order.id} onClose={closeEditOrderDialog} />
        </Dialog>
        <GettingPaymentLinkDialog
          open={gettingPaymentLinkDialogOpen}
          orderId={order.id}
          onClose={closeGettingPaymentLinkDialog}
        />
      </RightBarWrapper>
      <Dialog open={orderCreateDialogOpen} onClose={closeOrderCreateDialog} fullWidth>
        <DialogTitle>Создание заказа</DialogTitle>
        <OrderEditForm
          onClose={closeOrderCreateDialog}
          defaultValues={{
            name: order.customer.name,
            phoneNumber: order.customer.phoneNumber,
            email: order.customer.email
          }}
        />
      </Dialog>
    </>
  )
}

export { SchedulerOrder }
