import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

export enum MessageTypes {
  Default = 'default',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export type MessageModel = {
  type: MessageTypes
  text: string
  stamp: number
  duration?: number
}

export interface MessageState {
  messages: MessageModel[]
}

const initialState: MessageState = {
  messages: []
}

const messageSlice = createSlice({
  name: 'mainLayout',
  initialState,
  reducers: {
    showMessage: (state, action: PayloadAction<MessageModel>) => {
      state.messages.push(action.payload)
    },
    removeMessage: (state, action: PayloadAction<number>) => {
      state.messages = state.messages.filter((el) => el.stamp !== action.payload)
    }
  }
})

function getMessagesSelector(state: RootState) {
  return state.message.messages
}

const { removeMessage, showMessage: setMessage } = messageSlice.actions

function showMessage(data: { type: MessageTypes; text: string; duration?: number }) {
  const { type, text, duration } = data

  return (dispatch) => {
    dispatch(setMessage({ type, stamp: Date.now(), text, duration }))
  }
}

export { getMessagesSelector, removeMessage, showMessage }
export default messageSlice.reducer
