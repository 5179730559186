import { ComponentType } from 'react'
import { styled } from '@mui/material'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { Dayjs } from 'dayjs'

type CustomPickerDayProps<W> = PickersDayProps<W> & {
  dayIsBetween: boolean
  isFirstDay: boolean
  isLastDay: boolean
}

export const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay'
})<CustomPickerDayProps<Dayjs>>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  })
})) as ComponentType<CustomPickerDayProps<Dayjs>>
