import styled from '@emotion/styled'

const FilterMenuWrapper = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
`

const SchedulerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`

const SchedulerContainer = styled.div`
  overflow: auto;
  width: inherit;
  height: calc(100vh - 8.5rem);
`

export { SchedulerWrapper, SchedulerContainer, FilterMenuWrapper }
