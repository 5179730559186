import * as Yup from 'yup'
import { PriceFormData } from './types'

function getValidations() {
  return Yup.object().shape({
    name: Yup.string().required('Поле обязательно для заполнения'),
    prices: Yup.array().of(
      Yup.object().shape({
        price: Yup.number().required('Поле "Цена" обязательно для заполнения'),
        numberOfPersons: Yup.number()
          .nullable()
          .when('isPersonPrices', {
            is: true,
            then: (schema) => schema.required('Значение "Количество участников" должно быть заполнено')
          }),
        minimumPrePaymentAmount: Yup.number()
          .nullable()
          .test({
            test: (value, { parent }) => {
              return !value || value <= parent.price
            },
            message: 'Минимальная сумма предоплаты должна быть меньше или равна значению поля цены'
          })
      })
    )
  }) as Yup.ObjectSchema<NonNullable<PriceFormData>, Yup.AnyObject>
}

export { getValidations }
