import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'
import { PageModel, PageCreateModel } from 'shared/models'

const page = {
  getPageList(config?: AxiosRequestConfig) {
    return adminInstance.get<PageModel[]>('/pages', { ...config })
  },

  getPage(pageId: number, config?: AxiosRequestConfig) {
    return adminInstance.get<PageModel>(`/pages/${pageId}`, config)
  },

  createPage(data: PageCreateModel, config?: AxiosRequestConfig) {
    return adminInstance.post<void>('/pages', data, config)
  },

  updatePage(pageId: number, data: PageModel, config?: AxiosRequestConfig) {
    return adminInstance.put<void>(`/pages/${pageId}`, data, config)
  },

  deletePage(pageId: number, config?: AxiosRequestConfig) {
    return adminInstance.delete<void>(`/pages/${pageId}`, config)
  }
}

export { page }
