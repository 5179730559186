import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@mui/material'
import { useQuery } from 'react-query'
import { adminService } from 'shared/api'
import EditIcon from '@mui/icons-material/Edit'
import CreateIcon from '@mui/icons-material/Add'
import OrderListIcon from '@mui/icons-material/FormatListBulleted'
import { Link, useSearchParams } from 'react-router-dom'
import { questCreate, questEdit, questOrders } from 'pages/nav'
import { useEffect, useState } from 'react'
import { SearchInput } from 'features/search-input'

function QuestList() {
  const { data, isLoading } = useQuery(['quests'], ({ signal }) => adminService.quest.getQuestList({ signal }))

  const { data: addressData, isLoading: addressIsLoading } = useQuery(['addressList'], ({ signal }) =>
    adminService.address.getAddressList({ signal })
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const [term, setTerm] = useState(searchParams.get('term') || '')
  const [address, setAddress] = useState<string | undefined>(searchParams.get('address') || undefined)

  useEffect(() => {
    const search = new URLSearchParams(searchParams)

    if (address) {
      search.set('address', String(address))
    } else {
      search.delete('address')
    }

    if (term) {
      search.set('term', term)
    } else {
      search.delete('term')
    }
    setSearchParams(search)
  }, [term, address])

  function handleTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTerm(e.target.value)
  }

  const filteredData = data?.data?.filter?.(
    (quest) =>
      quest.name.toLowerCase().includes(term.toLowerCase()) && (!address || String(quest.addressId) === address)
  )

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6">Список квестов</Typography>
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          <Tooltip title="Порядок отображения квестов">
            <Link to={questOrders()}>
              <IconButton>
                <OrderListIcon />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Добавить новый квест">
            <Link to={questCreate()}>
              <IconButton>
                <CreateIcon />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ margin: '0 0.5rem 1rem', display: 'flex', gap: '1rem' }}>
        <SearchInput value={term} onChange={handleTermChange} onClear={() => setTerm('')} />
        <FormControl fullWidth variant="standard" style={{ maxWidth: '200px' }}>
          <InputLabel>Адрес</InputLabel>
          <Select
            value={!!address && !Number.isNaN(address) ? address : -1}
            onChange={(ev) => {
              if (ev.target.value !== '-1') {
                setAddress(String(ev.target.value))
              } else {
                setAddress(undefined)
              }
            }}
          >
            <MenuItem value="-1">Все адреса</MenuItem>
            {addressData?.data?.map?.((address) => (
              <MenuItem key={address.id} value={String(address.id)}>
                {address.address}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <List>
        {(!!isLoading || !!addressIsLoading) && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading &&
          !addressIsLoading &&
          filteredData?.map?.((quest) => {
            const questAddress = addressData?.data.find((a) => a.id === quest.addressId)

            return (
              <ListItem key={quest.id}>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                      <Typography>{quest.name}</Typography>
                      <Typography variant="caption" color="#00000032">
                        {quest.ageRating || 0}+
                      </Typography>
                    </Box>
                  }
                  primaryTypographyProps={{ component: 'div' }}
                  secondary={[questAddress?.city, questAddress?.address].filter(Boolean).join(', ')}
                />
                <ListItemSecondaryAction>
                  <Tooltip title="Редактировать квест">
                    <Link to={questEdit(String(quest.id))}>
                      <IconButton>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
      </List>
    </Container>
  )
}

export { QuestList }
