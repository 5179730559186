import { http, HttpResponse, delay } from 'msw'
import { fakeAddresses } from '../fake-data/fake-addresses'

export const addresses = [
  http.get('/api/addresses', async () => {
    await delay(900)

    return HttpResponse.json(fakeAddresses)
  }),

  http.get('/api/addresses/:id', async ({ params }) => {
    await delay(300)

    const { id } = params

    const foundAddress = id ? fakeAddresses.find((address) => address.id === Number(id)) : undefined

    if (!foundAddress) {
      return HttpResponse.json(undefined, { status: 404 })
    } else {
      return HttpResponse.json(foundAddress)
    }
  }),

  http.post('/api/addresses', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.put('/api/addresses/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.delete('/api/addresses/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  })
]
