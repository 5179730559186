import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import { OrderStatus } from 'shared/models'
import { orderStatusText } from './status-utils'
import { adminService } from 'shared/api'
import { useQueryClient } from 'react-query'

function EditOrderStatusDialog({
  orderId,
  initialStatus,
  onClose,
  open
}: {
  orderId: string
  initialStatus: OrderStatus
  open: boolean
  onClose: () => void
}) {
  const client = useQueryClient()
  const [status, setStatus] = useState(initialStatus)
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    if (open === true) {
      setStatus(initialStatus)
    }
  }, [open])

  async function handleStatusSave() {
    setProcessing(true)

    try {
      await adminService.order.changeStatus(orderId || '', status)
      client.invalidateQueries(['order', orderId])
    } finally {
      setProcessing(false)
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Статус заказа</DialogTitle>
      <DialogContent>
        <Select
          value={status}
          onChange={(ev) => {
            setStatus(ev.target.value as OrderStatus)
          }}
          fullWidth
          variant="standard"
          size="small"
        >
          {Object.keys(orderStatusText)
            .filter((el) => el !== 'Cancelled')
            .map((key) => (
              <MenuItem key={key} value={key}>
                {orderStatusText[key]}
              </MenuItem>
            ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="outlined" disabled={processing} onClick={handleStatusSave}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export { EditOrderStatusDialog }
