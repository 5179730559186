import { EditUserModel, UserModel } from 'shared/models'
import { isEmail, isValidPassword } from 'shared/utils/string-test'
import * as Yup from 'yup'

function getValidations(initData?: UserModel) {
  return Yup.object().shape({
    firstName: !initData?.firstName
      ? Yup.string().required('Поле обязательно для заполнения')
      : Yup.string().nullable(),
    lastName: !initData?.lastName ? Yup.string().required('Поле обязательно для заполнения') : Yup.string().nullable(),
    email: !initData?.email
      ? Yup.string()
          .required('Поле обязательно для заполнения')
          .test({ test: (value) => isEmail(value), message: 'Некорректный email' })
      : Yup.string().nullable(),
    newPassword: Yup.string().test({
      test: (val) => !val || isValidPassword(val),
      message: 'Пароль должен содержать большую букву, цифры и специальные символы'
    }),
    newPasswordConfirm: Yup.string().when('newPassword', {
      is: (val: string) => !!val,
      then: (schema) => schema.required('Поле обязательно для заполнения').oneOf([Yup.ref('newPassword')], 'Пароли не совпадают')
    }),
    currentPassword: Yup.string().when('newPassword', {
      is: (val: string) => !!val,
      then: (schema) => schema.required('Поле обязательно для заполнения')
    })
  }) as Yup.ObjectSchema<NonNullable<EditUserModel>, Yup.AnyObject>
}

export { getValidations }
