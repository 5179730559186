import { http, HttpResponse, delay } from 'msw'
import { fakeQuests } from '../fake-data/fake-quests'

export const quests = [
  http.get('/api/quests', async () => {
    await delay(900)

    return HttpResponse.json(fakeQuests)
  }),

  http.get('/api/quests/:id', async ({ params }) => {
    await delay(300)

    const { id } = params

    const foundQuest = id ? fakeQuests.find((quest) => quest.id === Number(id)) : undefined

    if (!foundQuest) {
      return HttpResponse.json(undefined, { status: 404 })
    } else {
      return HttpResponse.json(foundQuest)
    }
  }),

  http.post('/api/quests', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.put('/api/quests/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.delete('/api/quests/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.patch('/api/quests/updateOrders', async () => {
    await delay(700)

    return HttpResponse.json(undefined, { status: 204 })
  })
]
