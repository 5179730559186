import { adminService } from 'shared/api'
import { OrderEditFormProps } from './types'
import { useQuery } from 'react-query'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { OrderCreateModel, OrderEditModel } from 'shared/models'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidations } from './validations'
import { useState } from 'react'
import { IntlPhoneInput } from 'features/intl-phone-input'
import { getServicePrice } from './utils'
import { AdditionalService, AdditionalServiceList, AdditionalServicePrice, AdditionalServiceTitle } from './styled'
import { orderView } from 'pages/nav'
import { useNavigate } from 'react-router-dom'

function OrderEditForm(props: OrderEditFormProps) {
  const { palette } = useTheme()
  const navigate = useNavigate()

  const { handleSubmit, control, reset, watch, setValue } = useForm<OrderEditModel | OrderCreateModel>({
    resolver: yupResolver(getValidations()),
    defaultValues: props.defaultValues
  })

  const { data: serviceList, isLoading: isServiceListLoading } = useQuery(['serviceList'], ({ signal }) =>
    adminService.service.getServiceList({ signal })
  )

  const { data, isFetching } = useQuery(
    ['orderEditForm', props.orderId],
    ({ signal }) => adminService.order.getOrderEditForm(props.orderId || '', { signal }),
    {
      onSuccess: (res) => {
        reset(res.data)
      },
      enabled: !!props.orderId
    }
  )

  const mainService = serviceList?.data.find((el) => el.id === watch('serviceId'))
  const additionalServices =
    serviceList?.data.filter(
      (el) => !!mainService && !el.withReservation && el.addressId === mainService.addressId && !el.isGiftCertificate
    ) || []

  const { data: mainServicePricesList } = useQuery({
    queryKey: ['servicesPrices', watch('serviceId'), watch('date'), watch('time')],
    queryFn: () =>
      adminService.order.getServicesPrices({
        serviceIds: [watch('serviceId'), ...additionalServices.map((el) => el.id)],
        date: watch('date'),
        time: watch('time')
      }),
    retry: false,
    enabled: !!watch('serviceId') && !!watch('date') && !!watch('time') && !!serviceList?.data
  })

  const { data: timeSlots, isLoading: isTimeSlotsLoading } = useQuery({
    queryKey: ['serviceTimeSlots', watch('serviceId'), props.orderId],
    queryFn: () => adminService.order.getTimeSlots({ serviceId: watch('serviceId'), orderId: props.orderId }),
    retry: false,
    enabled: !!watch('serviceId')
  })

  const dates = timeSlots?.data?.map((timeSlot) => timeSlot.date) || []
  const times = timeSlots?.data?.find((timeSlot) => timeSlot.date === watch('date'))?.times || []

  const [isProcess, setProcess] = useState(false)

  const prices = mainServicePricesList?.data || []

  const mainServices = serviceList?.data?.filter?.((service) => !!service.withReservation) || []
  const mainServicePriceList = prices.find((p) => String(p.serviceId) === String(watch('serviceId')))?.priceList

  const mainServicePriceNumberOfPersons = mainServicePriceList?.prices.filter((el) => !!el.numberOfPersons)

  const additionalServiceAvailable = additionalServices.filter(
    (s) => getServicePrice(prices, s.id, watch('persons')) !== null
  )

  async function handleSave(data: OrderEditModel | OrderCreateModel) {
    setProcess(true)

    try {
      if (props.orderId) {
        await adminService.order.updateOrder(props.orderId, data as OrderEditModel)
      } else {
        const res = await adminService.order.createOrder(data as OrderCreateModel)

        if (res?.data?.id) {
          navigate(orderView(res.data.id))
        }
      }
    } finally {
      setProcess(false)
      props.onClose()
    }
  }

  return (
    <>
      {(!!isFetching || !!isServiceListLoading) && (
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        </DialogContent>
      )}
      {!isServiceListLoading && !isFetching && (!props.orderId || !!data?.data) && !!serviceList?.data && (
        <form onSubmit={handleSubmit(handleSave)}>
          <DialogContent>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Имя"
                  fullWidth
                  placeholder="Имя"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={field.disabled || isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="none"
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Email"
                  fullWidth
                  type="email"
                  autoComplete="off"
                  placeholder="Email"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field, fieldState }) => (
                <IntlPhoneInput
                  label="Контактный телефон"
                  fullWidth
                  placeholder="Контактный телефон"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={field.disabled || isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="comment"
              render={({ field, fieldState }) => (
                <TextField
                  label="Комментарий"
                  fullWidth
                  placeholder="Комментарий"
                  multiline
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={field.disabled || isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
          </DialogContent>
          <Divider />
          <DialogContent>
            <Controller
              control={control}
              name="serviceId"
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard" margin="dense">
                  <Autocomplete
                    value={mainServices?.find?.((s) => String(s.id) === String(field.value)) || null}
                    fullWidth
                    onChange={(_, value) => {
                      field.onChange(value?.id || null)
                      setValue('date', null as any)
                      setValue('time', null as any)
                      setValue('additionalServiceIds', [])
                      setValue('persons', 1)
                    }}
                    options={mainServices}
                    clearOnEscape={false}
                    clearIcon={false}
                    getOptionLabel={(option) => option.name}
                    disabled={isProcess}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Услуга"
                        margin="none"
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        size="small"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            {!!isTimeSlotsLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <CircularProgress />
              </Box>
            )}
            {!!watch('serviceId') && !isTimeSlotsLoading && (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <div style={{ flex: 1 }}>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth variant="standard" margin="dense">
                        <InputLabel>Дата</InputLabel>
                        <Select
                          value={field.value || ''}
                          onChange={(e) => {
                            field.onChange(e)
                            setValue('time', null as any)
                          }}
                          error={!!fieldState.error}
                          fullWidth
                          disabled={isProcess}
                        >
                          {dates?.map?.((date) => (
                            <MenuItem
                              key={date}
                              value={date}
                              sx={{
                                bgcolor: [6, 0].includes(new Date(date).getUTCDay())
                                  ? palette.mode === 'light'
                                    ? '#f8f8f8'
                                    : '#2b2b2b'
                                  : undefined
                              }}
                            >
                              <span
                                style={{
                                  textTransform: 'capitalize',
                                  marginRight: '0.25rem',
                                  fontSize: '12px',
                                  opacity: 0.68
                                }}
                              >
                                {Intl.DateTimeFormat('ru-RU', { timeZone: 'UTC', weekday: 'short' }).format(
                                  new Date(date)
                                )}
                              </span>
                              {Intl.DateTimeFormat('ru-RU', { timeZone: 'UTC' }).format(new Date(date))}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Controller
                    control={control}
                    name="time"
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth variant="standard" margin="dense">
                        <InputLabel>Время</InputLabel>
                        <Select
                          value={field.value || ''}
                          onChange={(e) => {
                            field.onChange(e)
                          }}
                          error={!!fieldState.error}
                          fullWidth
                          disabled={isProcess}
                        >
                          {times?.map?.((time) => (
                            <MenuItem key={time.time} value={time.time} disabled={!time.available}>
                              {Intl.DateTimeFormat('ru-RU', { hour: '2-digit', minute: '2-digit' }).format(
                                new Date(time.time)
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{fieldState.error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
              </Box>
            )}
            {!!mainServicePriceNumberOfPersons?.length && (
              <Controller
                control={control}
                name="persons"
                render={({ field }) => (
                  <FormControl fullWidth variant="standard" margin="dense">
                    <InputLabel shrink>Количество участников</InputLabel>
                    <div style={{ marginTop: '1.5rem', display: 'flex', gap: '0.5rem' }}>
                      {mainServicePriceNumberOfPersons.map((el) => (
                        <Chip
                          key={el.numberOfPersons}
                          label={el.numberOfPersons}
                          component="a"
                          onClick={() => {
                            field.onChange(el.numberOfPersons as number)
                            setValue(
                              'additionalServiceIds',
                              (watch('additionalServiceIds') || []).filter(
                                (id) => getServicePrice(prices, id, el.numberOfPersons) !== null
                              )
                            )
                          }}
                          color={field.value === el.numberOfPersons ? 'primary' : undefined}
                          clickable
                          disabled={isProcess}
                        />
                      ))}
                    </div>
                  </FormControl>
                )}
              />
            )}
            {!!additionalServiceAvailable.length && (
              <FormControl fullWidth variant="standard" margin="dense">
                <InputLabel shrink>Дополнительные услуги</InputLabel>
                <div style={{ marginTop: '0.5rem', display: 'flex', gap: '0.5rem' }}>
                  <Controller
                    name="additionalServiceIds"
                    control={control}
                    render={({ field }) => (
                      <AdditionalServiceList>
                        {additionalServiceAvailable.map((el) => (
                          <AdditionalService
                            key={el.id}
                            onClick={() => {
                              field.onChange(
                                field.value.includes(el.id)
                                  ? field.value.filter((id) => id !== el.id)
                                  : [...field.value, el.id]
                              )
                            }}
                          >
                            <Checkbox
                              checked={!!field.value.includes(el.id)}
                              onChange={() => null}
                              style={{ padding: 0 }}
                              disabled={isProcess}
                            />
                            <div>
                              <AdditionalServiceTitle>{el.name}</AdditionalServiceTitle>
                              <AdditionalServicePrice>
                                {getServicePrice(prices, el.id, watch('persons'))} ₽
                              </AdditionalServicePrice>
                            </div>
                          </AdditionalService>
                        ))}
                      </AdditionalServiceList>
                    )}
                  />
                </div>
              </FormControl>
            )}
          </DialogContent>
          <Box style={{ position: 'sticky', bottom: 0 }} sx={{ bgcolor: 'background' }}>
            <Divider />
            <DialogActions>
              <Button type="submit" disabled={isProcess} fullWidth variant="contained" disableElevation>
                Сохранить
              </Button>
            </DialogActions>
          </Box>
        </form>
      )}
    </>
  )
}

export { OrderEditForm }
