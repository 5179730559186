import dayjs from 'dayjs'
import { CellTimeSlot, SchedulerViews } from '../types'

function generateDayTimeSlots(
  timeSlotIntervalInMin: number,
  selectedDate?: number,
  startDayHour?: number,
  endDayHour?: number
): CellTimeSlot[] {
  const timeSlotInMs = timeSlotIntervalInMin * 60 * 1000

  const timeSlots = new Array((60 * 24) / timeSlotIntervalInMin).fill(0).map((_, i) => ({
    i,
    serial: `${selectedDate || 'today'}-${i}`,
    startTime: new Date(selectedDate || new Date()).setUTCHours(0, 0, 0, 0) + i * timeSlotInMs,
    endTime: new Date(selectedDate || new Date()).setUTCHours(0, 0, 0, 0) + (i + 1) * timeSlotInMs
  }))

  const dayTimes = timeSlots
    .filter((el) => el.startTime >= new Date(selectedDate || new Date()).setUTCHours(startDayHour || 0, 0, 0, 0))
    .filter((el) => el.startTime < new Date(selectedDate || new Date()).setUTCHours(endDayHour || 24, 0, 0, 0))
    .map((el, i) => ({ ...el, i }))

  return dayTimes
}

function generataWeekTimeSlots(
  timeSlotIntervalInMin: number,
  selectedDate?: number,
  startDayHour?: number,
  endDayHour?: number,
  currentView?: SchedulerViews
): { date: number; dayTimes: CellTimeSlot[] }[] {
  if (currentView === 'week') {
    const currentDate = dayjs(selectedDate || new Date().setUTCHours(0, 0, 0, 0), { utc: true }).startOf('week')

    return new Array(7).fill(0).map((_, i) => {
      const date = currentDate
        .day(i + 1)
        .toDate()
        .getTime()

      console.log(new Date(date))

      return { date, dayTimes: generateDayTimeSlots(timeSlotIntervalInMin, date, startDayHour, endDayHour) }
    })
  } else {
    const currentDate = dayjs(selectedDate || new Date().setUTCHours(0, 0, 0, 0))

    return [
      {
        date: currentDate.toDate().getTime(),
        dayTimes: generateDayTimeSlots(timeSlotIntervalInMin, selectedDate, startDayHour, endDayHour)
      }
    ]
  }
}

export { generataWeekTimeSlots, generateDayTimeSlots }
