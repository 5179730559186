type SelectFilesOption<M extends boolean> = {
  multiple?: M
  accept?: string
}

export async function nativeFilesSelect<M extends boolean>(
  options: SelectFilesOption<M>
): Promise<M extends true ? FileList : File> {
  const { accept = '' } = options
  const input = document.createElement('input') as HTMLInputElement
  input.type = 'file'
  input.multiple = options.multiple || false
  input.accept = accept

  if (options.multiple) {
    return new Promise((resolve) => {
      input.onchange = (e: any) => {
        resolve(e.target?.files)
      }

      input.click()
    })
  }

  return new Promise((resolve) => {
    input.onchange = (e: any) => {
      resolve(e.target?.files[0])
    }

    input.click()
  })
}
