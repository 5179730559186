import { useAppDispatch, useAppSelector } from 'store'
import { removeMessage, getMessagesSelector } from 'store/slices/message-slice'
import Notifer from './notifer'

export function Snackbar() {
  const dispatch = useAppDispatch()
  const messages = useAppSelector(getMessagesSelector)

  function handleClose(stamp: number) {
    dispatch(removeMessage(stamp))
  }

  return <Notifer messages={messages} onClose={handleClose} />
}
