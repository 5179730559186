import {
  OrderModel,
  OrderViewModel,
  OrderEditModel,
  OrderStatus,
  OrderCreateModel,
  ServicesPricesRes,
  TimeSlotModel,
  SchedulerOrderModel,
  OrderJournalItem
} from 'shared/models'
import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'

const order = {
  getOrderList(
    params: {
      startDate?: number
      endDate?: number
      page: number
      limit: number
      term?: string
      addressId?: string
      status?: string[]
      service?: string[]
    },
    config?: AxiosRequestConfig
  ) {
    return adminInstance.get<{ list: OrderModel[]; total: number; page: number; pageCount: number }>('/orders', {
      params,
      paramsSerializer: { indexes: null },
      ...config
    })
  },

  getOrder(orderId: string, config?: AxiosRequestConfig) {
    return adminInstance.get<OrderViewModel>(`/orders/${orderId}`, config)
  },

  getOrderEditForm(orderId: string, config?: AxiosRequestConfig) {
    return adminInstance.get<OrderEditModel>(`/orders/${orderId}/editForm`, config)
  },

  createOrder(data: OrderCreateModel, config?: AxiosRequestConfig) {
    return adminInstance.post<{ id?: string }>(`/orders`, data, config)
  },

  updateOrder(orderId: string, data: OrderEditModel, config?: AxiosRequestConfig) {
    return adminInstance.put(`/orders/${orderId}`, data, config)
  },

  cancelOrder(orderId: string, config?: AxiosRequestConfig) {
    return adminInstance.delete(`/orders/${orderId}`, config)
  },

  changeStaffComment(orderId: string, comment: string, config?: AxiosRequestConfig) {
    return adminInstance.patch(`/orders/${orderId}/editStaffComment`, { comment }, config)
  },

  changeStatus(orderId: string, status: OrderStatus, config?: AxiosRequestConfig) {
    return adminInstance.patch(`/orders/${orderId}/editStatus`, { status }, config)
  },

  getServicesPrices(params: { serviceIds: number[]; date: number; time: number }, config?: AxiosRequestConfig) {
    return adminInstance.get<ServicesPricesRes>(`/orders/getServicePrices`, {
      params,
      paramsSerializer: { indexes: null },
      ...config
    })
  },

  getTimeSlots(params: { serviceId: number; orderId?: string }, config?: AxiosRequestConfig) {
    return adminInstance.get<TimeSlotModel[]>(`/orders/getTimeslots`, { params, ...config })
  },

  getSchedulerOrders(params: { startDate: number; endDate: number; service?: string[] }, config?: AxiosRequestConfig) {
    return adminInstance.get<SchedulerOrderModel[]>(`/orders/getSchedulerOrders`, {
      params,
      paramsSerializer: { indexes: null },
      ...config
    })
  },

  getPaymentLink({ orderId, amount }: { orderId: string; amount: number }, config?: AxiosRequestConfig) {
    return adminInstance.get<{ paymentLink: string }>(`/orders/${orderId}/getPaymentLink`, {
      params: { amount },
      ...config
    })
  },

  getExportDataLink(params: {
    startDate?: number
    endDate?: number
    term?: string
    addressId?: string
    status?: string[]
    service?: string[]
  }) {
    const query = new URLSearchParams()

    if (params.term) {
      query.append('term', params.term)
    }

    if (params.startDate) {
      query.append('startDate', params.startDate.toString())
    }

    if (params.endDate) {
      query.append('endDate', params.endDate.toString())
    }

    if (params.addressId) {
      query.append('addressId', params.addressId)
    }

    if (params.status) {
      params.status.map((status) => query.append('status', status))
    }

    if (params.service) {
      params.service.map((id) => query.append('service', id))
    }

    return process.env.APP_API + `/orders/exportCustomerListAsXlsx` + `?${query.toString()}`
  },

  getOrderJournal(orderId: string, config?: AxiosRequestConfig) {
    return adminInstance.get<OrderJournalItem[]>(`/orders/${orderId}/journal`, config)
  }
}

export { order }
