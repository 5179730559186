import { http, HttpResponse, delay } from 'msw'

let i = 0

export const files = [
  http.post('/api/files/upload', async () => {
    await delay(3000)

    i += 1

    if (i % 3 === 0) {
      return HttpResponse.json(undefined, { status: 400 })
    }

    return HttpResponse.json({ url: 'https://insidequest.ru/upload/5d597104cd949.jpg' })
  })
]
