import { useMutation, useQuery, useQueryClient } from 'react-query'
import { adminService } from 'shared/api'
import { ServicePriceListProps } from './types'
import { Box, Button, CircularProgress, Dialog, IconButton, List } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ExportIcon from '@mui/icons-material/CloudDownload'
import ImportIcon from '@mui/icons-material/CloudUpload'
import { useEffect, useState } from 'react'
import { PriceEdit } from './service-price-edit'
import { ServicePriceExportModel, ServicePriceCreateModel, ServicePriceModel } from 'shared/models'
import { nativeFilesSelect } from 'shared/utils/file'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { useAppDispatch } from 'store'
import { ServicePriceItem } from './service-price-item'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

function ServicePriceList({ serviceId }: ServicePriceListProps) {
  const queryClient = useQueryClient()

  const dispatch = useAppDispatch()

  const { data, isLoading } = useQuery(
    ['servicePriceList', serviceId],
    ({ signal }) => adminService.service.getServicePriceList(Number(serviceId), { signal }),
    { enabled: !!serviceId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { mutateAsync: createPrice, isLoading: isCreateLoading } = useMutation((data: ServicePriceCreateModel) =>
    adminService.service.createServicePrice(data)
  )
  const { mutateAsync: deletePrice, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.service.deleteServicePrice(id)
  )

  const { mutateAsync: updateServicePriceOrders } = useMutation((orders: { id; order }[]) =>
    adminService.service.updateServicePriceOrders(orders)
  )

  const [priceList, setPriceList] = useState<ServicePriceModel[]>([])

  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [editPriceId, setEditPriceId] = useState<null | number>(null)
  const [orders, setOrders] = useState<{ id; order }[]>([])

  useEffect(() => {
    if (data?.data) {
      setPriceList(data.data || [])
    }
  }, [data])

  function handlePriceEditClose() {
    setEditModalIsOpen(false)
    queryClient.invalidateQueries(['servicePriceList', serviceId])
  }

  function handleExport() {
    if (!data) {
      return
    }

    const prices: ServicePriceModel[] = priceList || []
    const pricesForExport: ServicePriceExportModel[] = prices.map((price) => ({
      name: price.name,
      prices: price.prices,
      schedule: price.schedule
    }))

    const json = JSON.stringify(pricesForExport)

    const blob = new Blob([json], { type: 'application/json' })
    const href = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = href
    link.download = `prices_${serviceId}_${new Date().toISOString()}.json`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  async function handleImport() {
    try {
      const fileRaw = await nativeFilesSelect({ multiple: false, accept: '.json' })
      const resText = await fileRaw.text()
      const prices = JSON.parse(resText) as ServicePriceExportModel[]

      if (!!prices?.length && !!prices.filter((p) => !!p.prices).length) {
        const pricesForCreate: ServicePriceCreateModel[] = prices.map((p) => ({
          name: p.name || '',
          prices: p.prices,
          schedule: p.schedule,
          serviceId: serviceId
        }))

        const currentPrices: ServicePriceModel[] = priceList || []

        for (const p of currentPrices) {
          await deletePrice(p.id)
        }

        for (const p of pricesForCreate) {
          await createPrice(p)
        }

        queryClient.invalidateQueries(['servicePriceList', serviceId])
      } else {
        dispatch(showMessage({ text: 'Нет доступных для импорта прайс листов', type: MessageTypes.Error }))
      }
    } catch (e) {
      dispatch(showMessage({ text: 'Не удалось импортировать файл', type: MessageTypes.Error }))
    }
  }

  function handleAdd() {
    setEditPriceId(null)
    setEditModalIsOpen(true)
  }

  function handleModalClose() {
    setEditModalIsOpen(false)
  }

  async function handleDragEnd() {
    if (orders.length) {
      try {
        await updateServicePriceOrders(orders)
        dispatch(showMessage({ text: 'Порядок прайс листов обновлен', type: MessageTypes.Success }))
        queryClient.invalidateQueries(['servicePriceList', serviceId])
      } catch (e) {
        dispatch(showMessage({ text: 'Не удалось обновить порядок прайс листов', type: MessageTypes.Error }))
      }
    }
  }

  return (
    <>
      {(!!isLoading || !!isCreateLoading || !!isDeleteLoading) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && !isCreateLoading && !isDeleteLoading && (
        <>
          <Box
            padding={1}
            sx={{
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-end',
              borderBottom: '1px solid',
              borderColor: 'divider'
            }}
          >
            <Button startIcon={<ImportIcon />} onClick={handleImport}>
              Импортировать
            </Button>
            <Button startIcon={<ExportIcon />} onClick={handleExport}>
              Экспортировать
            </Button>
          </Box>
          <DndProvider backend={HTML5Backend} key="price-list">
            <List disablePadding>
              {priceList?.map?.((price, i, arr) => (
                <ServicePriceItem
                  key={price.id}
                  price={price}
                  onEdit={() => {
                    setEditPriceId(price.id)
                    setEditModalIsOpen(true)
                  }}
                  onSortChange={(dragId, hoverId) => {
                    const firstIndex = arr.findIndex((el) => el.id === dragId)
                    const secondIndex = arr.findIndex((el) => el.id === hoverId)
                    const [removed] = arr.splice(firstIndex, 1)
                    arr.splice(secondIndex, 0, removed)

                    const items = arr
                      .map((v) => ({ v, order: 0 }))
                      .map((item, i, a) => ({ ...item, order: a.length - i }))

                    setOrders(items.map((el) => ({ id: el.v.id, order: el.order })))

                    const res = items.sort((a, b) => b.order - a.order).map((item) => item.v)

                    setPriceList(res)
                  }}
                  onDragEnd={handleDragEnd}
                />
              ))}
              <Box display="flex" justifyContent="center" margin="0.5rem 1rem">
                <IconButton onClick={handleAdd}>
                  <AddIcon />
                </IconButton>
              </Box>
            </List>
          </DndProvider>
        </>
      )}
      <Dialog open={editModalIsOpen} onClose={handleModalClose} fullWidth>
        <PriceEdit
          key={editPriceId || undefined}
          onClose={handlePriceEditClose}
          priceId={editPriceId || undefined}
          serviceId={serviceId}
        />
      </Dialog>
    </>
  )
}

export { ServicePriceList }
