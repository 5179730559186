import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { adminService } from 'shared/api'
import { useQueryClient } from 'react-query'

function EditStaffCommentDialog({
  orderId,
  initialStaffComment,
  onClose,
  open
}: {
  orderId: string
  initialStaffComment: string
  open: boolean
  onClose: () => void
}) {
  const client = useQueryClient()
  const [staffComment, setStaffComment] = useState(initialStaffComment)
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    if (open === true) {
      setStaffComment(initialStaffComment)
    }
  }, [open])

  function handleStaffCommentInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setStaffComment(e.target.value)
  }

  async function handleStaffCommentSave() {
    setProcessing(true)

    try {
      await adminService.order.changeStaffComment(orderId || '', staffComment)
      client.invalidateQueries(['order', orderId])
    } finally {
      setProcessing(false)
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Комментарий сотрудника</DialogTitle>
      <DialogContent>
        <TextField
          label="Комментарий сотрудника"
          placeholder="Комментарий сотрудника"
          value={staffComment}
          variant="standard"
          InputLabelProps={{ shrink: true }}
          onChange={handleStaffCommentInputChange}
          fullWidth
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="outlined" disabled={processing} onClick={handleStaffCommentSave}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export { EditStaffCommentDialog }
