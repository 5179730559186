import { CateroryCreateModel, CategoryModel } from 'shared/models'
import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'

const caterory = {
  getCategoryList(config?: AxiosRequestConfig) {
    return adminInstance.get<CategoryModel[]>('/categories', { ...config })
  },

  getCategory(categoryId: number, config?: AxiosRequestConfig) {
    return adminInstance.get<CategoryModel>(`/categories/${categoryId}`, config)
  },

  createCategory(data: CateroryCreateModel, config?: AxiosRequestConfig) {
    return adminInstance.post<void>('/categories', data, config)
  },

  updateCategory(categoryId: number, data: CategoryModel, config?: AxiosRequestConfig) {
    return adminInstance.put<void>(`/categories/${categoryId}`, data, config)
  },

  deleteCategory(categoryId: number, config?: AxiosRequestConfig) {
    return adminInstance.delete<void>(`/categories/${categoryId}`, config)
  }
}

export { caterory }
