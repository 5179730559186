import { ServiceModel, ServicesPricesRes, ServicePriceModel, ReservationFormData } from '@/shared/models'

function getServicePrice(prices: ServicesPricesRes, serviceId: string | number, persons: number | null) {
  const price = prices.find((p) => String(p.serviceId) === String(serviceId))?.priceList

  if (!price?.prices?.length) {
    return null
  }

  if (price.prices.length > 1) {
    return price.prices.find((p) => p.numberOfPersons === persons)?.price || null
  }

  return price.prices[0].price
}

function calculateTotalPrice({
  persons,
  additionalServiceIds,
  mainServiceId,
  prices
}: {
  persons: number | null
  additionalServiceIds: (string | number)[]
  mainServiceId: string | number
  prices: ServicesPricesRes
}) {
  const mainPrice = getServicePrice(prices, mainServiceId, persons) || 0

  const additionalServicesPrice = additionalServiceIds.length
    ? additionalServiceIds.reduce((acc: number, s) => {
        const price = getServicePrice(prices, s, persons)

        return price ? acc + price : acc
      }, 0)
    : 0

  return mainPrice + additionalServicesPrice
}

function getDefaultReservationForm({
  date,
  mainService,
  mainServicePriceList,
  time
}: {
  date: number
  time: number
  mainService: ServiceModel
  mainServicePriceList?: ServicePriceModel
}): ReservationFormData {
  return {
    name: '',
    email: '',
    phoneNumber: '',
    persons:
      (mainServicePriceList?.prices?.length || 0) > 1 ? mainServicePriceList?.prices[0].numberOfPersons || null : null,
    additionalServiceIds: [],
    serviceId: mainService.id,
    date,
    time
  }
}

export { getServicePrice, getDefaultReservationForm, calculateTotalPrice }
