import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'
import { enableMocking } from 'shared/mocks'

const domElement = document.getElementById('root')!
const root = ReactDOM.createRoot(domElement)

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
})
