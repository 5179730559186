import { setupWorker } from 'msw/browser'
import { user } from './handlers/user'
import { categories } from './handlers/categories'
import { addresses } from './handlers/addresses'
import { quests } from './handlers/quests'
import { services } from './handlers/services'
import { orders } from './handlers/orders'
import { pages } from './handlers/pages'
import { config } from './handlers/config'
import { customers } from './handlers/customers'
import { files } from './handlers/file'
import { emailTemplates } from './handlers/email-templates'

const handlers = [
  ...user,
  ...categories,
  ...addresses,
  ...quests,
  ...services,
  ...orders,
  ...pages,
  ...config,
  ...customers,
  ...files,
  ...emailTemplates
]

export const worker = setupWorker(...handlers)

async function enableMocking() {
  if (process.env.APP_FAKES !== 'true') {
    return
  }

  const { worker } = await import('shared/mocks')

  return worker.start({
    onUnhandledRequest: ({ method, url }) => {
      if (url.startsWith('/api')) {
        console.warn(`Unhandled ${method} request to ${url}`)
      }
    }
  })
}

export { enableMocking }
