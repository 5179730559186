import { ConfigModel } from 'shared/models'
import * as Yup from 'yup'

function getValidations() {
  return Yup.object().shape({
    title: Yup.string().required('Поле обязательно для заполнения'),
    description: Yup.string().required('Поле обязательно для заполнения'),
    phone: Yup.string()
  }) as Yup.ObjectSchema<NonNullable<ConfigModel>, Yup.AnyObject>
}

export { getValidations }
