import { memo } from 'react'
import { CalendarDataAppointment, MoreItemsIndicator } from '../styled'
import { ColumnItem, SchedulePosition } from '../types'
import {
  sortPositionsByTimeAndDuration,
  calculateColumnDistributedOverlaysPositions,
  groupPositionDistributedByTimeSlots
} from '../utils'
import { AppointmentsComponentProps } from './types'

function AppointmentsComponent(props: AppointmentsComponentProps) {
  const dayPositions = props.positions.filter((e) => new Date(e.startDate).setUTCHours(0, 0, 0, 0) === props.day.date)
  const resourcePositions = dayPositions.filter((e) => !props.resource?.id || e.resourceId == props.resource?.id)
  const sortedPositions = sortPositionsByTimeAndDuration(resourcePositions)
  const columnDistributedOverlays = calculateColumnDistributedOverlaysPositions(sortedPositions)
  const grouppedByTimeSlots = groupPositionDistributedByTimeSlots(
    columnDistributedOverlays,
    props.day.dayTimes,
    props.maxColumnCount
  )

  function handleClick(position: SchedulePosition) {
    return () => {
      props.setSelectedPosition(position)
    }
  }

  function handleMoreItemsIndicatorClick(positions: ColumnItem[]) {
    return (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      props.onMoreItemsIndicatorClick(ev, positions)
    }
  }

  return (
    <>
      {grouppedByTimeSlots.map(({ positions, timeSlot }) => {
        if (!timeSlot) {
          return null
        }

        const firstItemStartDate = new Date(positions[0].position.startDate).getTime()
        const minAfterStartSlot = (firstItemStartDate - timeSlot.startTime) / 60000
        const mt_startFirstItem = Math.floor((props.timeSlotHeight / props.timeSlotIntervalInMin) * minAfterStartSlot)

        return (
          <MoreItemsIndicator
            key={timeSlot.serial}
            style={{ gridRow: `${timeSlot.i + 1} / ${timeSlot.i + 2}`, marginTop: `${mt_startFirstItem}px` }}
            onClick={handleMoreItemsIndicatorClick(positions)}
          >
            {positions.length}
          </MoreItemsIndicator>
        )
      })}
      {columnDistributedOverlays.map((column, columnIndex, { length: colCount }) => {
        if (columnIndex > props.maxColumnCount - 1) {
          return null
        }

        return column.map((item) => {
          const startPositionTime = new Date(item.position.startDate).getTime()
          const endPositionTime = new Date(item.position.endDate).getTime()

          const incomingIntervals = props.day.dayTimes.filter(
            (time) => time.endTime >= startPositionTime && time.startTime <= endPositionTime
          )

          if (!incomingIntervals?.length) {
            return null
          }

          const start = incomingIntervals[0].i + 1
          const end = incomingIntervals[incomingIntervals.length - 1].i + 2

          const minAfterStartSlot = (startPositionTime - incomingIntervals[0].startTime) / 60000
          const minAfterEndSlot = (incomingIntervals[incomingIntervals.length - 1].endTime - endPositionTime) / 60000

          const mt_startSlot = Math.floor((props.timeSlotHeight / props.timeSlotIntervalInMin) * minAfterStartSlot)
          const mt_endSlot = Math.floor((props.timeSlotHeight / props.timeSlotIntervalInMin) * minAfterEndSlot)

          const columnCount = colCount > props.maxColumnCount ? props.maxColumnCount : colCount
          const appointmentStyles = props.getAppointmentStyles?.(item.position) || {}

          return (
            <CalendarDataAppointment
              key={item.position.id}
              onClick={handleClick(item.position)}
              style={{
                gridRow: `${start} / ${end}`,
                marginTop: `calc(${mt_startSlot}px)`,
                height: `calc(100% - ${mt_startSlot}px - ${mt_endSlot}px)`,
                width: `calc(100% / ${columnCount} - ${
                  grouppedByTimeSlots?.length > 0 && columnIndex === props.maxColumnCount - 1 ? 27 : 1
                }px)`,
                marginLeft: `calc(100% / ${columnCount} * ${columnIndex})`,
                ...appointmentStyles
              }}
            >
              {props.appointmentRender(item.position)}
            </CalendarDataAppointment>
          )
        })
      })}
    </>
  )
}

export const Appointments = memo(AppointmentsComponent)
