import { CreateUserModel, EditUserModel } from 'shared/models'
import { isEmail } from 'shared/utils/string-test'
import * as Yup from 'yup'

function getValidations() {
  return Yup.object().shape({
    firstName: Yup.string().required('Поле обязательно для заполнения'),
    lastName: Yup.string().required('Поле обязательно для заполнения'),
    profile: Yup.string().required('Поле обязательно для заполнения'),
    email: Yup.string()
      .required('Поле обязательно для заполнения')
      .test({ test: (value) => isEmail(value), message: 'Некорректный email' }),
  }) as Yup.ObjectSchema<NonNullable<EditUserModel | CreateUserModel>, Yup.AnyObject>
}

export { getValidations }
