import { http, HttpResponse, delay } from 'msw'
import { fakePages } from '../fake-data/fake-pages'

export const pages = [
  http.get('/api/pages', async () => {
    await delay(900)

    return HttpResponse.json(fakePages)
  }),

  http.get('/api/pages/:id', async ({ params }) => {
    await delay(300)

    const { id } = params

    const foundQuest = id ? fakePages.find((quest) => quest.id === Number(id)) : undefined

    if (!foundQuest) {
      return HttpResponse.json(undefined, { status: 404 })
    } else {
      return HttpResponse.json(foundQuest)
    }
  }),

  http.post('/api/pages', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.put('/api/pages/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.delete('/api/pages/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  })
]
