import { Chip, Divider, FormGroup, IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { TimePicker } from 'features/time'
import { Fragment } from 'react'
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material'
import { CloseButtonContainer, TimesContainer, WeekdaysSchedulerActions, WeekdaysWrapper } from './styled'
import { weekdays } from './utils'
import { WeekdaysSchedulerItem, WeekdaysSchedulerProps } from './types'
import { DatePicker } from 'features/date'

function WeekdaysScheduler({ value, onChange }: WeekdaysSchedulerProps) {
  function handleItemChange<K extends keyof WeekdaysSchedulerItem>(index: number, key: K, v: WeekdaysSchedulerItem[K]) {
    const newValue = [...value]

    if (newValue?.[index]) {
      newValue[index][key] = v
    }

    onChange(newValue)
  }

  function handleWeekdayChange(index: number, entry: WeekdaysSchedulerItem, dayId: number) {
    return () => {
      const prevWeekdays = entry.weekdays || []
      const newWeekdays = prevWeekdays.includes(dayId)
        ? prevWeekdays.filter((d) => d !== dayId)
        : [...prevWeekdays, dayId]

      const newValue = [...value]

      if (newValue?.[index]) {
        newValue[index].weekdays = newWeekdays
      }

      onChange(newValue)
    }
  }

  function handleDeleteSchedule(index: number) {
    return () => {
      const items = value
      onChange([...items.slice(0, index), ...items.slice(index + 1)])
    }
  }

  function handleAddSchedule() {
    const notFilledWeekdays = weekdays.filter((day) => !value || !value.find((e) => e.weekdays.includes(day.id)))

    const canAddWeekdayScheduleItem =
      notFilledWeekdays.length > 0 &&
      value.filter((v) => !v.weekdays?.length && !v.isSpecificDate).length < notFilledWeekdays.length

    onChange([...value, { date: null, weekdays: [], isSpecificDate: !canAddWeekdayScheduleItem }])
  }

  const notFilledWeekdays = weekdays.filter((day) => !value || !value.find((e) => e.weekdays.includes(day.id)))

  const canAddWeekdayScheduleItem =
    notFilledWeekdays.length > 0 &&
    value.filter((v) => !v.weekdays?.length && !v.isSpecificDate).length < notFilledWeekdays.length

  return (
    <div>
      {!value.length && (
        <>
          <Divider />
          <FormGroup style={{ padding: '1rem 0' }}>
            <Typography variant="h6" color="GrayText" textAlign="center" style={{ opacity: 0.64 }}>
              Расписание не задано
            </Typography>
          </FormGroup>
        </>
      )}
      {value.map((entry, index) => (
        <Fragment key={index}>
          <Divider />
          <CloseButtonContainer>
            <IconButton size="small" onClick={handleDeleteSchedule(index)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </CloseButtonContainer>
          <FormGroup style={{ padding: '1rem 1.5rem 0.5rem' }}>
            <ToggleButtonGroup
              color="primary"
              value={entry.isSpecificDate ? 'date' : 'weekdays'}
              exclusive
              onChange={(_, val) => {
                const newValue = [...value]

                if (newValue?.[index]) {
                  newValue[index]['isSpecificDate'] = val === 'date'
                  newValue[index]['date'] = null
                  newValue[index]['weekdays'] = []
                }

                onChange(newValue)
              }}
              size="small"
            >
              <ToggleButton value="weekdays" disabled={!canAddWeekdayScheduleItem && !!entry.isSpecificDate}>
                Дни недели
              </ToggleButton>
              <ToggleButton value="date">Дата</ToggleButton>
            </ToggleButtonGroup>
          </FormGroup>
          {!entry.isSpecificDate && (
            <FormGroup style={{ padding: '1rem 1.5rem' }}>
              <Typography
                variant="subtitle2"
                style={{ cursor: 'pointer', marginBottom: '1rem', opacity: 0.86, fontSize: '13px' }}
                onClick={() => {
                  const newValue = [...value]

                  if (newValue?.[index] && newValue[index].weekdays.length !== weekdays.length) {
                    newValue[index].weekdays = weekdays.map((day) => day.id)
                  } else {
                    newValue[index].weekdays = []
                  }

                  onChange(newValue)
                }}
              >
                {entry.weekdays?.length !== weekdays.length ? 'Выбрать все дни недели' : 'Сбросить все дни недели'}
              </Typography>
              <WeekdaysWrapper>
                {weekdays.map((day) => (
                  <Chip
                    key={day.id}
                    style={{ borderRadius: '16px' }}
                    color={entry.weekdays.includes(day.id) ? 'primary' : 'default'}
                    label={day.name}
                    disabled={!!value?.find((el, i) => i !== index && (el.weekdays || []).includes(day.id))}
                    onClick={handleWeekdayChange(index, entry, day.id)}
                    clickable
                  />
                ))}
              </WeekdaysWrapper>
            </FormGroup>
          )}
          {entry.isSpecificDate && (
            <FormGroup style={{ padding: '1rem 1.5rem' }}>
              <DatePicker shrink label="Дата" onChange={(v) => handleItemChange(index, 'date', v)} value={entry.date} />
            </FormGroup>
          )}
          <FormGroup style={{ padding: '0rem 1.5rem 1rem' }}>
            <TimesContainer>
              <TimePicker
                fullWidth
                label={'С'}
                value={entry.startTime}
                onChange={(val) => handleItemChange(index, 'startTime', val || undefined)}
              />
              <TimePicker
                fullWidth
                label={'До'}
                value={entry.endTime}
                onChange={(val) => handleItemChange(index, 'endTime', val || undefined)}
              />
            </TimesContainer>
          </FormGroup>
        </Fragment>
      ))}
      <Divider />
      <WeekdaysSchedulerActions>
        <IconButton size="small" onClick={handleAddSchedule}>
          <AddIcon fontSize="small" />
        </IconButton>
      </WeekdaysSchedulerActions>
    </div>
  )
}

export { WeekdaysScheduler }
