import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { nativeFilesSelect } from 'shared/utils/file'
import { adminService } from 'shared/api'
import { FileUploadButtonProps } from './types'
import imageCompression from 'browser-image-compression'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { useAppDispatch } from 'store'

function FileUploadButton({ onFileChange, imageMaxSizeMB, imageMaxWidthOrHeight }: FileUploadButtonProps) {
  const [fetching, setFetching] = useState(false)

  const dispatch = useAppDispatch()

  async function handlePreviewUpload() {
    try {
      const fileRaw = await nativeFilesSelect({
        multiple: false,
        accept: '.apng, .png, .avif, .jpg, .jpeg, .jfif, .pjpeg, .pjp, .webp'
      })

      setFetching(true)

      if (imageMaxSizeMB || imageMaxWidthOrHeight) {
        try {
          const output = await imageCompression(fileRaw, {
            maxSizeMB: imageMaxSizeMB,
            maxWidthOrHeight: imageMaxWidthOrHeight,
            useWebWorker: true
          })

          const file = new File([output], fileRaw.name, { lastModified: fileRaw.lastModified, type: fileRaw.type })

          const formData = new FormData()
          formData.append('file', file)

          try {
            const res = await adminService.file.upload(formData)

            if (res.data.url) {
              onFileChange(res.data.url)
            }
          } catch (err) {
            console.log(err)
          }
        } catch (err) {
          console.warn(err)
          dispatch(
            showMessage({
              text: 'Не удалось сжать изображение, вероятно выбранный файл не является изображением',
              type: MessageTypes.Error
            })
          )
        }
      } else {
        if (fileRaw) {
          const formData = new FormData()
          formData.append('file', fileRaw)

          const res = await adminService.file.upload(formData)

          if (res.data.url) {
            onFileChange(res.data.url)
          }
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setFetching(false)
    }
  }

  return (
    <LoadingButton
      onClick={handlePreviewUpload}
      loading={fetching}
      loadingPosition="center"
      variant="outlined"
      disableElevation
      style={{ padding: '0.5rem 0', minWidth: '36px', height: '36px', borderRadius: '50%' }}
    >
      <CloudUploadIcon fontSize="small" />
    </LoadingButton>
  )
}

export { FileUploadButton }
