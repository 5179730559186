import { Box, Card, CircularProgress, Container, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { adminService } from 'shared/api'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { EditUserModel } from 'shared/models'
import { useAppDispatch, useAppSelector } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { getUserProfileSelector } from 'store/slices/profile-slice'
import { ProfileEditForm } from './profile-edit-form'

function ProfileEdit() {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(getUserProfileSelector)

  const queryClient = useQueryClient()

  const { data, isFetching } = useQuery(
    ['getCurrentProfile'],
    ({ signal }) => adminService.user.getUser(profile?.id || 0, { signal }),
    { refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { mutateAsync: updateUser, isLoading: isUpdateLoading } = useMutation((data: EditUserModel) =>
    adminService.user.updateUser(data.id, data)
  )

  async function handleSubmit(data: EditUserModel) {
    try {
      await updateUser(data)
      queryClient.invalidateQueries('getCurrentProfile')
      dispatch(showMessage({ text: 'Информация профиля успешно обновлена', type: MessageTypes.Success }))
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, minHeight: 40 }}>
        <Typography variant="h6">Профиль</Typography>
      </Box>
      {!!isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && (
        <Card elevation={1}>
          <TabContext value="main">
            <TabPanel value="main">
              <ProfileEditForm defaultData={data?.data} onSubmit={handleSubmit} isProcess={isUpdateLoading} />
            </TabPanel>
          </TabContext>
        </Card>
      )}
    </Container>
  )
}

export { ProfileEdit }
