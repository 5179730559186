import styled from '@emotion/styled'

const FilterMenuWrapper = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
`

const ReservationWrapper = styled.div`
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  max-width: 400px;
  width: fit-content;
`

const AdditionalService = styled.div`
  display: flex;
  border: solid 1px #e0e0e0;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  gap: 0.5rem;
`

const AdditionalServiceList = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`

const AdditionalServiceTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
`

const AdditionalServicePrice = styled.div`
  font-size: 12px;
  opacity: 0.48;
`

export {
  FilterMenuWrapper,
  ReservationWrapper,
  AdditionalService,
  AdditionalServiceList,
  AdditionalServiceTitle,
  AdditionalServicePrice
}
