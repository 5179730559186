import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@mui/material'
import { useQuery } from 'react-query'
import { adminService } from 'shared/api'
import EditIcon from '@mui/icons-material/Settings'
import ServiceIcon from '@mui/icons-material/ShoppingBasket'
import ServiceWithReservationIcon from '@mui/icons-material/Event'
import CreateIcon from '@mui/icons-material/Add'
import { serviceCreate, serviceEdit } from 'pages/nav'
import { Link, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { SearchInput } from 'features/search-input'

function ServiceList() {
  const { data, isLoading } = useQuery(['services'], ({ signal }) => adminService.service.getServiceList({ signal }))

  const { data: addressData, isLoading: addressIsLoading } = useQuery(['addressList'], ({ signal }) =>
    adminService.address.getAddressList({ signal })
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const [term, setTerm] = useState(searchParams.get('term') || '')
  const [address, setAddress] = useState<string | undefined>(searchParams.get('address') || undefined)

  useEffect(() => {
    const search = new URLSearchParams(searchParams)

    if (address) {
      search.set('address', String(address))
    } else {
      search.delete('address')
    }

    if (term) {
      search.set('term', term)
    } else {
      search.delete('term')
    }
    setSearchParams(search)
  }, [term, address])

  function handleTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTerm(e.target.value)
  }

  const filteredData = data?.data?.filter?.(
    (service) =>
      service.name.toLowerCase().includes(term.toLowerCase()) && (!address || String(service.addressId) === address)
  )

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6">Список услуг</Typography>
        <Tooltip title="Добавить новую услугу">
          <Link to={serviceCreate()}>
            <IconButton>
              <CreateIcon />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
      <Box sx={{ margin: '0 0.5rem 1rem', display: 'flex', gap: '1rem' }}>
        <SearchInput value={term} onChange={handleTermChange} onClear={() => setTerm('')} />
        <FormControl fullWidth variant="standard" style={{ maxWidth: '200px' }}>
          <InputLabel>Адрес</InputLabel>
          <Select
            value={!!address && !Number.isNaN(address) ? address : -1}
            onChange={(ev) => {
              if (ev.target.value !== '-1') {
                setAddress(String(ev.target.value))
              } else {
                setAddress(undefined)
              }
            }}
          >
            <MenuItem value="-1">Все адреса</MenuItem>
            {addressData?.data?.map?.((address) => (
              <MenuItem key={address.id} value={String(address.id)}>
                {address.address}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <List>
        {(!!isLoading || !!addressIsLoading) && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading &&
          !addressIsLoading &&
          filteredData?.map?.((service) => {
            const address = addressData?.data?.find?.((address) => address.id === service.addressId)

            return (
              <ListItem key={service.id}>
                <ListItemIcon>
                  {service.withReservation ? <ServiceWithReservationIcon /> : <ServiceIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={service.name}
                  secondary={[address?.city, address?.address].filter(Boolean).join(', ')}
                />
                <ListItemSecondaryAction>
                  <Link to={serviceEdit(String(service.id))}>
                    <Tooltip title="Просмотреть и отредактировать праметры услуги">
                      <IconButton>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Link>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
      </List>
    </Container>
  )
}

export { ServiceList }
