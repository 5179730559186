import { ConfigModel } from 'shared/models'
import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'

const config = {
  getConfig(config?: AxiosRequestConfig) {
    return adminInstance.get<ConfigModel>('/config', { ...config })
  },

  updateConfig(data: ConfigModel, config?: AxiosRequestConfig) {
    return adminInstance.put<void>(`/config`, data, config)
  }
}

export { config }
