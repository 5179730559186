import { useEffect, useRef, useState } from 'react'
import MuiPhoneNumber, { MuiPhoneNumberProps } from './material-ui-phoneinput'

type Props = {
  value?: string | null
}

export function IntlPhoneInput(props: MuiPhoneNumberProps & Props) {
  const [localState, setLocalState] = useState('')

  useEffect(() => {
    if (props.value && props.value !== '+' && props.value !== props.defaultCountry) {
      setLocalState(props.value || '')
    }
  }, [props.value])

  useEffect(() => {
    setLocalState(props.value || '')
  }, [])

  const initValue = localState
  const value = initValue.replace(/\D+/g, '')
  const replacedValue = `${value.slice(0, 2).replace('89', '79')}${value.slice(2, value.length)}`

  const inputRef = useRef('')

  const defaultCodeMap = {
    ru: '7'
  }

  const validedValue = replacedValue ? `+${replacedValue}` : `+${defaultCodeMap['ru']}`

  const plusOrValue = initValue === '+' ? '+' : validedValue

  useEffect(() => {
    return () => {
      if (inputRef.current === '+') {
        props.onChange?.('')
      }
    }
  }, [])

  function handleChange(value: any) {
    const trimValue = value.replace(/[\\(\\)\-\s]+/g, '')

    inputRef.current = trimValue

    setLocalState(trimValue)

    if (trimValue === '+' || trimValue === `+${defaultCodeMap['ru']}`) {
      props.onChange('')
    } else {
      props.onChange(trimValue)
    }
  }

  return (
    <MuiPhoneNumber
      {...props}
      label={props.label}
      onChange={handleChange}
      defaultCountry="ru"
      countryCodeEditable
      value={plusOrValue}
    />
  )
}
