import { Divider, ListItem, ListItemText } from '@mui/material'
import { QuestOrder } from './types'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { useRef } from 'react'
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import type { Identifier } from 'dnd-core'

export interface DragItem {
  serviceId: number
}

function QuestOrderItem({
  quest,
  onOrderChange
}: {
  quest: QuestOrder
  onOrderChange: (dragId: number, hoverId: number) => void
}) {
  const dragRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'additionalServiceList',
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() }
    },
    hover(item: DragItem) {
      if (!dragRef.current) {
        return
      }

      const dragId = item.serviceId
      const hoverId = quest.id

      if (dragId === hoverId) {
        return
      }

      onOrderChange(dragId, hoverId)
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'additionalServiceList',
    item: () => ({ serviceId: quest.id }),
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() })
  })

  preview(drop(dragRef))

  return (
    <>
      <div style={{ padding: '0.25rem 0', opacity: isDragging ? 0 : 1 }} ref={dragRef}>
        <ListItem>
          <div
            ref={drag}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '1rem' }}
          >
            <DragIndicatorIcon style={{ opacity: '0.2', cursor: 'move' }} data-handler-id={handlerId} />
          </div>
          <ListItemText primaryTypographyProps={{ fontWeight: 500 }} primary={quest.name} />
        </ListItem>
      </div>
      <Divider />
    </>
  )
}

export { QuestOrderItem }
