import { AddressModel, AddressCreateModel } from 'shared/models'
import { adminInstance } from '.'
import { AxiosRequestConfig } from 'axios'

const address = {
  getAddressList(config?: AxiosRequestConfig) {
    return adminInstance.get<AddressModel[]>('/addresses', {
      ...config
    })
  },

  getAddress(addressId: number, config?: AxiosRequestConfig) {
    return adminInstance.get<AddressModel>(`/addresses/${addressId}`, config)
  },

  createAddress(data: AddressCreateModel, config?: AxiosRequestConfig) {
    return adminInstance.post<void>('/addresses', data, config)
  },

  updateAddress(addressId: number, data: AddressModel, config?: AxiosRequestConfig) {
    return adminInstance.put<void>(`/addresses/${addressId}`, data, config)
  },

  deleteAddress(addressId: number, config?: AxiosRequestConfig) {
    return adminInstance.delete<void>(`/addresses/${addressId}`, config)
  }
}

export { address }
