import { memo } from 'react'
import { HideControl } from './styled'
import { SchedulerAppointmentProps } from './types'

function SchedulerAppointment(props: SchedulerAppointmentProps) {
  const { rawPosition } = props.data

  return (
    <HideControl>
      <div style={{ fontSize: '12px', fontWeight: 500, lineHeight: '14px' }}>{rawPosition?.customer.name}</div>
      <div style={{ fontSize: '11px', opacity: 0.56 }}>{rawPosition?.customer.phoneNumber}</div>
    </HideControl>
  )
}

export default memo(SchedulerAppointment)
