import { Box, Button, Card, CircularProgress, Container, List, Typography } from '@mui/material'
import { useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useMutation, useQuery } from 'react-query'
import { adminService } from 'shared/api'
import { QuestOrderItem } from './quest-order-item'
import { QuestOrder } from './types'
import { useNavigate } from 'react-router-dom'
import { questList } from 'pages/nav'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { useAppDispatch } from 'store'

function QuestOrders() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [orderList, setOrderList] = useState<QuestOrder[]>([])

  const { isFetching } = useQuery('getQuests', ({ signal }) => adminService.quest.getQuestList({ signal }), {
    onSuccess: (data) => {
      const questList = (data?.data || [])
        .map((el) => ({ id: el.id, order: el.order || 0, name: el.name }))
        .sort((a, b) => a.order - b.order)

      setOrderList(questList)
    }
  })

  const { mutateAsync, isLoading: isSaveLoading } = useMutation((list: { id: number; order: number }[]) =>
    adminService.quest.updateQuestOrders(list)
  )

  function handleOrderChange(dragId: number, hoverId: number) {
    const firstIndex = orderList.findIndex((el) => el.id === dragId)
    const secondIndex = orderList.findIndex((el) => el.id === hoverId)
    const [removed] = orderList.splice(firstIndex, 1)
    orderList.splice(secondIndex, 0, removed)

    const items = orderList.map((item, i) => ({ ...item, order: i }))

    setOrderList(items)
  }

  async function handleSave() {
    try {
      await mutateAsync(orderList.map((q) => ({ id: q.id, order: q.order })))
      dispatch(showMessage({ text: 'Порядок отображения квестов сохранен', type: MessageTypes.Success }))
      navigate(questList())
    } catch (e) {
      dispatch(
        showMessage({ text: 'При сохранении порядка отображения квестов произошла ошибка', type: MessageTypes.Error })
      )
    }
  }

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ mb: 3, minHeight: 40 }}>
          <Typography variant="h6">Порядок отображения квестов</Typography>
        </Box>
      </Container>
      <Container maxWidth="sm">
        {!!isFetching && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        <Card>
          <DndProvider backend={HTML5Backend} key="additionalServiceList">
            <List disablePadding>
              {orderList.map((item) => (
                <QuestOrderItem key={item.id} quest={item} onOrderChange={handleOrderChange} />
              ))}
            </List>
          </DndProvider>
        </Card>
        {!isFetching && orderList.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button onClick={handleSave} variant="outlined" disabled={isSaveLoading}>
              Сохранить
            </Button>
          </Box>
        )}
      </Container>
    </>
  )
}

export { QuestOrders }
