import { useState } from 'react'
import { PageEditFormProps } from './types'
import { PageCreateModel, PageModel } from 'shared/models'
import { pageCreateData } from './utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { getValidations } from './validation'
import { Autocomplete, Box, Button, Card, FormControl, Tab, TextField } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { MarkdownWrapper } from './styled'
import { FileUploadButton } from 'features/file-upload-button'
import { GalleryTab } from 'features/image-gallery/gallery-tab'
import { AdditionalServiceList } from 'features/additional-service-list'

function PageEditForm({ isEdit, onSubmit, defaultData, isProcess, onDelete, serviceList }: PageEditFormProps) {
  const [isDeleting, setIsDeleting] = useState(false)

  const [tab, setTab] = useState<'main' | 'seo' | 'additionalServices' | 'gallery'>('main')

  const { control, handleSubmit, watch, setValue } = useForm<PageModel | PageCreateModel>({
    defaultValues: defaultData || pageCreateData,
    disabled: isProcess,
    resolver: yupResolver<PageModel | PageCreateModel>(getValidations())
  })

  function handleDelete() {
    setIsDeleting(true)
  }

  function cancelDelete() {
    setIsDeleting(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card elevation={1}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={(_, value) => setTab(value)}>
              <Tab label="Основная информация" value="main" />
              {!!isEdit && !!defaultData?.mainServiceId && (
                <Tab label="Дополнительные услуги" value="additionalServices" />
              )}
              <Tab label="SEO" value="seo" />
              <Tab label="Галерея" value="gallery" />
            </TabList>
          </Box>
          <TabPanel value="main">
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Название"
                  fullWidth
                  autoComplete="off"
                  placeholder="Название"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="none"
                />
              )}
            />
            <Controller
              control={control}
              name="alias"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Алиас (предпочтительно на английском языке)"
                  fullWidth
                  autoComplete="off"
                  placeholder="Алиас"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="mainServiceId"
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="standard" margin="dense">
                  <Autocomplete
                    value={serviceList?.find?.((s) => s.id === field.value) || null}
                    fullWidth
                    onChange={(_, value) => field.onChange(value?.id || undefined)}
                    options={serviceList || []}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Услуга"
                        margin="dense"
                        variant="standard"
                        size="small"
                        error={!!fieldState.error}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            {!!watch('mainServiceId') && (
              <Controller
                control={control}
                name="duration"
                defaultValue={undefined}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Длительность"
                    autoComplete="off"
                    placeholder="Длительность услуги"
                    variant="standard"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={field.value ?? ''}
                    onChange={(ev) => field.onChange(ev.target.value ? Number(ev.target.value) : null)}
                    onBlur={field.onBlur}
                    disabled={isProcess}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    ref={field.ref}
                    margin="dense"
                  />
                )}
              />
            )}
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <Controller
                control={control}
                name="minPersons"
                defaultValue={undefined}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Минимальное количество участников"
                    autoComplete="off"
                    placeholder="Минимальное количество участников"
                    variant="standard"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={field.value ?? ''}
                    onChange={(ev) => field.onChange(ev.target.value ? Number(ev.target.value) : null)}
                    onBlur={field.onBlur}
                    disabled={isProcess}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    ref={field.ref}
                    margin="dense"
                  />
                )}
              />
              <Controller
                control={control}
                name="maxPersons"
                defaultValue={undefined}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Максимальное количество участников"
                    autoComplete="off"
                    placeholder="Максимальное количество участников"
                    variant="standard"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={field.value ?? ''}
                    onChange={(ev) => field.onChange(ev.target.value ? Number(ev.target.value) : null)}
                    onBlur={field.onBlur}
                    disabled={isProcess}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    ref={field.ref}
                    margin="dense"
                  />
                )}
              />
            </div>
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'baseline' }}>
              <Controller
                control={control}
                name="headerImageUrl"
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    label="URL изображения шапки на странице"
                    fullWidth
                    autoComplete="off"
                    placeholder="URL изображения шапки на странице"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    disabled={isProcess}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    ref={field.ref}
                    margin="dense"
                  />
                )}
              />
              <FileUploadButton onFileChange={(url) => setValue('headerImageUrl', url)} />
            </div>
            <Controller
              control={control}
              name="ageRating"
              defaultValue={0}
              render={({ field, fieldState }) => (
                <TextField
                  label="Возрастной рейтинг"
                  autoComplete="off"
                  placeholder="Возрастной рейтинг"
                  variant="standard"
                  type="number"
                  style={{ width: '130px' }}
                  InputLabelProps={{ shrink: true }}
                  value={field.value ?? ''}
                  onChange={(ev) => field.onChange(ev.target.value ? Number(ev.target.value) : null)}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  InputProps={{ endAdornment: '+' }}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="content"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="Содержимое страницы"
                  fullWidth
                  autoComplete="off"
                  multiline
                  minRows={8}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            {!!watch('content') && (
              <MarkdownWrapper>
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    img: (props) => {
                      const alt = props.alt?.split(',')[0]
                      const width = (props.alt?.split(',')[1] || '100%').trim()
                      const height = (props.alt?.split(',')[2] || '100%').trim()
                      return <img {...props} alt={alt} style={{ width, height }} />
                    }
                  }}
                >
                  {watch('content')}
                </Markdown>
              </MarkdownWrapper>
            )}
          </TabPanel>
          <TabPanel value="seo">
            <Controller
              control={control}
              name="htmlTitle"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="HTML Заголовок"
                  fullWidth
                  autoComplete="off"
                  placeholder="HTML Заголовок"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="none"
                />
              )}
            />
            <Controller
              control={control}
              name="htmlKeywords"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="HTML Ключевые слова"
                  multiline
                  fullWidth
                  autoComplete="off"
                  placeholder="HTML Ключевые слова"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
            <Controller
              control={control}
              name="htmlDescription"
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  label="HTML Описание"
                  multiline
                  fullWidth
                  autoComplete="off"
                  placeholder="HTML Описание"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={isProcess}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  ref={field.ref}
                  margin="dense"
                />
              )}
            />
          </TabPanel>
          <TabPanel value="additionalServices" style={{ padding: 0 }}>
            <Controller
              control={control}
              name="additionalServiceIds"
              defaultValue={[]}
              render={({ field }) => (
                <AdditionalServiceList
                  addressId={serviceList.find((el) => el.id === watch('mainServiceId'))?.addressId}
                  onChange={field.onChange}
                  value={field.value || []}
                  serviceList={serviceList}
                />
              )}
            />
          </TabPanel>
          <TabPanel value="gallery">
            <GalleryTab images={watch('gallery') || []} onChange={(photos) => setValue('gallery', photos)} />
          </TabPanel>
        </TabContext>
      </Card>
      {!isDeleting && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: '1rem' }}>
          {isEdit && (
            <Button onClick={handleDelete} disabled={isProcess}>
              Удалить
            </Button>
          )}
          <Button type="submit" variant="outlined" disabled={isProcess}>
            Сохранить
          </Button>
        </Box>
      )}
      {isDeleting && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 3, gap: '1rem' }}>
          Вы действительно хотите удалить страницу?
          <Button onClick={cancelDelete} disabled={isProcess}>
            Отмена
          </Button>
          <Button variant="outlined" color="error" disabled={isProcess} onClick={onDelete}>
            Удалить
          </Button>
        </Box>
      )}
    </form>
  )
}

export { PageEditForm }
