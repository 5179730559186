import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { NavList } from './nav-list'
import { NavListProps } from './types'

function NavListDnd(props: NavListProps) {
  return (
    <DndProvider backend={HTML5Backend} key="service-list">
      <NavList {...props} />
    </DndProvider>
  )
}

export { NavListDnd }
