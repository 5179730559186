import styled from "@emotion/styled";

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0.5rem 0 0;
  margin-bottom: -2rem;
`

const WeekdaysSchedulerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`

const TimesContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`

const WeekdaysWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  width: 90%;
`

export { CloseButtonContainer, WeekdaysSchedulerActions, TimesContainer, WeekdaysWrapper }
