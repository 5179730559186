import styled from '@emotion/styled'

const PaymentLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
  gap: 1rem;
`

export { PaymentLinkWrapper }
