import styled from '@emotion/styled'

const MarkdownWrapper = styled.div`
  background-color: #100321;
  color: #fff;
  padding: 2rem;
  margin: 1rem -1.5rem -1.5rem;

  * {
    font-family: 'Golos Text', sans-serif;
  }

  img {
    width: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: bert-sans-700, sans-serif;
    font-weight: 700;
  }

  blockquote {
    background: rgb(30, 10, 60);
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    margin: 1rem 0;
  }
`

export { MarkdownWrapper }
