import { ServiceScheduleModel } from 'shared/models'

const fakeServiceScheduleList: ServiceScheduleModel[] = [
  {
    id: 1,
    name: 'Будние дни',
    duration: 60,
    reservationIsAvailable: true,
    schedule: [
      {
        date: null,
        weekdays: [1, 2, 3, 4, 5],
        startTime: new Date(0).setHours(9, 0, 0, 0),
        endTime: new Date(0).setHours(18, 0, 0, 0)
      }
    ]
  },
  {
    id: 3,
    name: 'Выходные',
    duration: 75,
    reservationIsAvailable: true,
    schedule: [
      {
        date: null,
        weekdays: [6, 0],
        startTime: new Date(0).setHours(9, 0, 0, 0),
        endTime: new Date(0).setHours(23, 0, 0, 0)
      }
    ]
  },
  {
    id: 4,
    name: 'Праздники',
    duration: null,
    reservationIsAvailable: false,
    schedule: [
      {
        date: new Date('2024-02-23').setUTCHours(0, 0, 0, 0),
        weekdays: [],
        startTime: new Date(0).setHours(9, 0, 0, 0),
        endTime: new Date(0).setHours(23, 0, 0, 0)
      },
      {
        date: new Date('2024-03-08').setUTCHours(0, 0, 0, 0),
        weekdays: [],
        startTime: new Date(0).setHours(9, 0, 0, 0),
        endTime: new Date(0).setHours(23, 0, 0, 0)
      }
    ]
  }
]

export { fakeServiceScheduleList }
