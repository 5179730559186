import axios from 'axios'
import { user } from './user'
import { caterory } from './category'
import { address } from './address'
import { service } from './service'
import { quest } from './quest'
import { customer } from './customer'
import { order } from './order'
import { setAuthorize } from 'shared/auth'
import { clearProfile } from 'store/slices/profile-slice'
import { store } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { typedErrors } from './typed-errors'
import { page } from './page'
import { config } from './config'
import { file } from './file'
import { emailTemplates } from './email-templates'

const adminInstance = axios.create({
  baseURL: process.env.APP_API,
  withCredentials: true,
  headers: { common: { 'X-App-Version': __APP_VERSION__ } }
})

adminInstance.interceptors.response.use(
  (response) => response,
  (error) => combine(error, unauthorized, netError, notFound, serverError, typingErrors, errorThrow)
)

function unauthorized(error) {
  if (error.response && error.response.status === 401) {
    setAuthorize(false)
    store.dispatch(clearProfile())
    store.dispatch(showMessage({ type: MessageTypes.Error, text: 'Ошибка авторизации' }))
  }

  return error
}

function combine(data, ...funcs) {
  return funcs.reduce((prev, curr) => curr(prev), data)
}

function errorThrow(response) {
  return Promise.reject(response)
}

function netError(error) {
  if (error.isAxiosError && error.response === undefined && error?.code !== 'ERR_CANCELED') {
    store.dispatch(
      showMessage({ type: MessageTypes.Error, text: 'Страница временно недоступна из-за проблемы с сетью' })
    )
  }

  return error
}

function notFound(error) {
  if (error.response && error.response.status === 404) {
    store.dispatch(showMessage({ type: MessageTypes.Error, text: 'Ресурс не найден' }))
  }

  return error
}

function typingErrors(error) {
  if (!error.config.errorHandle && error.config.errorHandle !== undefined) {
    return error
  }

  if (
    error.response &&
    error.response.status < 500 &&
    error.response.status >= 400 &&
    error.response.status !== 401 &&
    error.response.status !== 404
  ) {
    const type = error.response.data?.type

    if (type) {
      store.dispatch(
        showMessage({ type: MessageTypes.Error, text: typedErrors[type] || 'Произошла неизвестная ошибка' })
      )
    } else {
      store.dispatch(showMessage({ type: MessageTypes.Error, text: 'Произошла неизвестная ошибка' }))
    }
  }

  return error
}

function serverError(error) {
  if (error.response && error.response.status === 500) {
    store.dispatch(showMessage({ type: MessageTypes.Error, text: 'Ошибка сервера' }))
  }

  return error
}

const adminService = {
  user,
  caterory,
  address,
  service,
  quest,
  customer,
  order,
  page,
  config,
  file,
  emailTemplates
}

export { adminService, adminInstance }
