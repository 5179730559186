import { Controller, useForm } from 'react-hook-form'
import { UserEditFormProps } from './types'
import { EditUserModel } from 'shared/models'
import { Box, Button, TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { getValidations } from './validation'
import { useEffect } from 'react'

function ProfileEditForm({ defaultData, onSubmit, isProcess }: UserEditFormProps) {
  const { control, handleSubmit, reset } = useForm<EditUserModel>({
    defaultValues: defaultData,
    disabled: isProcess,
    resolver: yupResolver<EditUserModel>(getValidations(defaultData))
  })

  useEffect(() => {
    reset(defaultData)
  }, [defaultData])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="email"
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            label="Email"
            fullWidth
            type="email"
            autoComplete="off"
            placeholder="Email"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={field.disabled || !!defaultData?.email}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            ref={field.ref}
            margin="dense"
          />
        )}
      />
      <Controller
        control={control}
        name="lastName"
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            label="Фамилия"
            fullWidth
            placeholder="Фамилия"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={field.disabled || !!defaultData?.lastName}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            ref={field.ref}
            margin="dense"
          />
        )}
      />
      <Controller
        control={control}
        name="firstName"
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            label="Имя"
            fullWidth
            placeholder="Имя"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={field.disabled || !!defaultData?.firstName}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            ref={field.ref}
            margin="dense"
          />
        )}
      />
      <Controller
        control={control}
        name="currentPassword"
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            label="Текущий пароль"
            fullWidth
            placeholder="Текущий пароль"
            type="password"
            variant="standard"
            autoComplete="new-password"
            InputLabelProps={{ shrink: true }}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            disabled={field.disabled}
            ref={field.ref}
            margin="dense"
          />
        )}
      />
      <Controller
        control={control}
        name="newPassword"
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            label="Новый пароль"
            fullWidth
            placeholder="Новый пароль"
            type="password"
            variant="standard"
            autoComplete="new-password"
            InputLabelProps={{ shrink: true }}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            disabled={field.disabled}
            ref={field.ref}
            margin="dense"
          />
        )}
      />
       <Controller
        control={control}
        name="newPasswordConfirm"
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            label="Повторите новый пароль"
            fullWidth
            placeholder="Повторите новый пароль"
            type="password"
            variant="standard"
            autoComplete="new-password"
            InputLabelProps={{ shrink: true }}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            disabled={field.disabled}
            ref={field.ref}
            margin="dense"
          />
        )}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Button type="submit" variant="outlined" disabled={isProcess}>
          Сохранить
        </Button>
      </Box>
    </form>
  )
}

export { ProfileEditForm }
