import { NavListProps } from './types'
import { MenuItemModel } from 'shared/models'
import { Button } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { NavListItem } from './nav-list-item'
import { reorder } from 'shared/utils/reorder'

function NavList(props: NavListProps) {
  function handleChange(i: number) {
    return (item: MenuItemModel) => {
      const newList = [...props.navList]
      newList[i] = item
      props.onChange(newList)
    }
  }

  function handleRemove(i: number) {
    return () => {
      const newList = [...props.navList]
      newList.splice(i, 1)
      props.onChange(newList)
    }
  }

  function onAdd() {
    const newList = [...props.navList]
    newList.push({ linkTo: '', name: '', surrogateId: Date.now() })
    props.onChange(newList)
  }

  function handleMoveCard(dragIndex: number, hoverIndex: number) {
    const items = reorder(props.navList, dragIndex, hoverIndex).map((item, i) => ({ ...item, order: i }))
    props.onChange(items)
  }

  return (
    <>
      {props.navList.map((item, i) => (
        <NavListItem
          key={item.id || item.surrogateId || 'without_id'}
          id={item.id || item.surrogateId || 'without_id'}
          index={i}
          handleRemove={handleRemove(i)}
          handleChange={handleChange(i)}
          item={item}
          moveCard={handleMoveCard}
        />
      ))}
      <div style={{ marginTop: '0.5rem' }}>
        <Button startIcon={<AddIcon />} onClick={onAdd}>
          Добавить
        </Button>
      </div>
    </>
  )
}

export { NavList }
