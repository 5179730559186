import { Box, CircularProgress, DialogTitle } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { adminService } from 'shared/api'
import { ServicePriceCreateModel, ServicePriceModel } from 'shared/models'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { PriceEditProps } from './types'
import { PriceEditForm } from './price-edit-form'

function PriceEdit({ priceId, serviceId, onClose }: PriceEditProps) {
  const dispatch = useAppDispatch()

  const { data, isFetching } = useQuery(
    ['price', priceId],
    ({ signal }) => adminService.service.getServicePrice(Number(priceId), { signal }),
    { enabled: !!priceId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { mutateAsync: createPrice, isLoading: isCreateLoading } = useMutation((data: ServicePriceCreateModel) =>
    adminService.service.createServicePrice(data)
  )
  const { mutateAsync: updatePrice, isLoading: isUpdateLoading } = useMutation((data: ServicePriceModel) =>
    adminService.service.updateServicePrice(data.id, data)
  )
  const { mutateAsync: deletePrice, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.service.deleteServicePrice(id)
  )

  async function handleSubmit(data: ServicePriceModel | ServicePriceCreateModel) {
    try {
      if ('id' in data && data.id) {
        await updatePrice(data)
      } else {
        await createPrice(data as ServicePriceCreateModel)
      }

      onClose()
      dispatch(showMessage({ text: 'Прайс лист сохранен', type: MessageTypes.Success }))
    } catch (e) {
      console.log(e)
    }
  }

  async function handleDelete() {
    if (!data?.data) {
      return
    }

    try {
      await deletePrice(data.data.id)

      dispatch(showMessage({ text: 'Прайс лист успешно удален', type: MessageTypes.Success }))
      onClose()
    } catch (e) {
      console.log(e)
    }
  }

  const isEdit = !!priceId

  return (
    <>
      <DialogTitle>{isEdit ? 'Редактирование прайс листа' : 'Создание прайс листа'}</DialogTitle>
      {!!isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && (
        <PriceEditForm
          isEdit={isEdit}
          defaultData={data?.data}
          onSubmit={handleSubmit}
          isProcess={isCreateLoading || isUpdateLoading || isDeleteLoading}
          serviceId={serviceId}
          onDelete={handleDelete}
        />
      )}
    </>
  )
}

export { PriceEdit }
