import { ServicePriceModel, ServicesPricesRes } from 'shared/models'

const fakePriceList: ServicePriceModel[] = [
  {
    id: 1,
    name: 'Будние дни с 9:00 до 18:00',
    prices: [
      {
        price: 2000,
        numberOfPersons: 1
      },
      {
        price: 2500,
        numberOfPersons: 2
      },
      {
        price: 2800,
        numberOfPersons: 3
      },
      {
        price: 3200,
        numberOfPersons: 4
      },
      {
        price: 3500,
        numberOfPersons: 5
      }
    ],
    schedule: [
      {
        date: null,
        weekdays: [1, 2, 3, 4, 5],
        startTime: new Date(0).setHours(9, 0, 0, 0),
        endTime: new Date(0).setHours(18, 0, 0, 0)
      }
    ]
  },
  {
    id: 2,
    name: 'Будние дни с 18:00 до 23:00',
    prices: [
      {
        price: 1500,
        numberOfPersons: 1
      },
      {
        price: 1800,
        numberOfPersons: 2
      },
      {
        price: 2100,
        numberOfPersons: 3
      },
      {
        price: 2300,
        numberOfPersons: 4
      },
      {
        price: 2500,
        numberOfPersons: 5
      }
    ],
    schedule: [
      {
        date: null,
        weekdays: [1, 2, 3, 4, 5],
        startTime: new Date(0).setHours(18, 0, 0, 0),
        endTime: new Date(0).setHours(23, 0, 0, 0)
      }
    ]
  },
  {
    id: 3,
    name: 'Выходные весь день',
    prices: [
      {
        price: 5000,
        numberOfPersons: null
      }
    ],
    schedule: [
      {
        date: null,
        weekdays: [6, 0],
        startTime: new Date(0).setHours(9, 0, 0, 0),
        endTime: new Date(0).setHours(23, 0, 0, 0)
      }
    ]
  },
  {
    id: 4,
    name: 'Праздники',
    prices: [
      {
        price: 7000,
        numberOfPersons: null
      }
    ],
    schedule: [
      {
        date: new Date('2024-02-23').setUTCHours(0, 0, 0, 0),
        weekdays: [],
        startTime: new Date(0).setHours(9, 0, 0, 0),
        endTime: new Date(0).setHours(23, 0, 0, 0)
      },
      {
        date: new Date('2024-03-08').setUTCHours(0, 0, 0, 0),
        weekdays: [],
        startTime: new Date(0).setHours(9, 0, 0, 0),
        endTime: new Date(0).setHours(23, 0, 0, 0)
      }
    ]
  }
]

const fakePriceListForOrder: ServicesPricesRes = [
  //for main service
  {
    serviceId: 7,
    priceList: {
      id: 101,
      prices: [
        { price: 2000, numberOfPersons: 1 },
        { price: 2500, numberOfPersons: 2 },
        { price: 2800, numberOfPersons: 3 },
        { price: 3200, numberOfPersons: 4 },
        { price: 3500, numberOfPersons: 5 }
      ]
    }
  },
  //for first additional services
  {
    serviceId: 11,
    priceList: {
      id: 155,
      prices: [
        { price: 1000, numberOfPersons: 1 },
        { price: 1000, numberOfPersons: 2 },
        { price: 1400, numberOfPersons: 3 }
      ]
    }
  },
  //for second additional services
  {
    serviceId: 12,
    priceList: {
      id: 171,
      prices: [{ price: 1000, numberOfPersons: null }]
    }
  }
]

export { fakePriceList, fakePriceListForOrder }
