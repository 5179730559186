export type ServiceEmailTemplate = {
  id: number
  type: EmailTemplateType
  subject: string
  body: string
  serviceId: number
}

export enum EmailTemplateType {
  ReservationCreate = 'ReservationCreate',
  ReservationCancel = 'ReservationCancel'
}
