import { createTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: grey
  },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    }
  }
})

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#5567f5' },
    background: {
      default: '#fbfbfb'
    }
  },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    }
  }
})

export { lightTheme, darkTheme }
