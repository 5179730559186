import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material'
import { useQuery } from 'react-query'
import { adminService } from 'shared/api'
import EditIcon from '@mui/icons-material/Edit'
import CreateIcon from '@mui/icons-material/Add'
import { Link, useSearchParams } from 'react-router-dom'
import { addressCreate, addressEdit } from 'pages/nav'
import { useEffect, useState } from 'react'
import { SearchInput } from 'features/search-input'

function AddressList() {
  const { data, isLoading } = useQuery(['addresses'], ({ signal }) => adminService.address.getAddressList({ signal }))

  const [searchParams, setSearchParams] = useSearchParams()

  const [term, setTerm] = useState(searchParams.get('term') || '')

  useEffect(() => {
    const search = new URLSearchParams(searchParams)

    if (term) {
      search.set('term', term)
    } else {
      search.delete('term')
    }

    setSearchParams(search)
  }, [term])

  function handleTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTerm(e.target.value)
  }

  const filteredData = data?.data?.filter?.((address) =>
    [address.city, address.address].filter(Boolean).join(', ').toLowerCase().includes(term.toLowerCase())
  )

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h6">Список адресов</Typography>
        <Tooltip title="Добавить новый адрес">
          <Link to={addressCreate()}>
            <IconButton>
              <CreateIcon />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
      <Box sx={{ margin: '0 0.5rem 1rem' }}>
        <SearchInput value={term} onChange={handleTermChange} onClear={() => setTerm('')} />
      </Box>
      <List>
        {!!isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading &&
          filteredData?.map?.((address) => (
            <ListItem key={address.id}>
              <ListItemText
                primary={[address.city, address.address].filter(Boolean).join(', ')}
                secondary={address.phone}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Редактировать адрес">
                  <Link to={addressEdit(String(address.id))}>
                    <IconButton>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </Container>
  )
}

export { AddressList }
