import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { adminService } from 'shared/api'
import { CategoryModel, CateroryCreateModel } from 'shared/models'
import { useAppDispatch } from 'store'
import { MessageTypes, showMessage } from 'store/slices/message-slice'
import { questCategoryList } from 'pages/nav'
import { QuestCategoryEditForm } from './quest-category-edit-form'

function QuestCategoryEdit() {
  const { categoryId } = useParams<{ categoryId?: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { data, isFetching } = useQuery(
    ['questCategory', categoryId],
    ({ signal }) => adminService.caterory.getCategory(Number(categoryId), { signal }),
    { enabled: !!categoryId, refetchOnReconnect: false, refetchOnWindowFocus: false }
  )

  const { mutateAsync: createCategory, isLoading: isCreateLoading } = useMutation((data: CateroryCreateModel) =>
    adminService.caterory.createCategory(data)
  )
  const { mutateAsync: updateCategory, isLoading: isUpdateLoading } = useMutation((data: CategoryModel) =>
    adminService.caterory.updateCategory(data.id, data)
  )
  const { mutateAsync: deleteCategory, isLoading: isDeleteLoading } = useMutation((id: number) =>
    adminService.caterory.deleteCategory(id)
  )

  async function handleSubmit(data: CateroryCreateModel | CategoryModel) {
    try {
      if ('id' in data && data.id) {
        await updateCategory(data)
      } else {
        await createCategory(data)
      }

      dispatch(showMessage({ text: 'Категория успешно сохранена', type: MessageTypes.Success }))
      navigate(questCategoryList() + '?term=' + data.name)
    } catch (e) {
      console.log(e)
    }
  }

  async function handleDelete() {
    if (!data?.data) {
      return
    }

    try {
      await deleteCategory(data.data.id)

      dispatch(showMessage({ text: 'Категория успешно удалена', type: MessageTypes.Success }))
      navigate(questCategoryList())
    } catch (e) {
      console.log(e)
    }
  }

  const isEdit = !!categoryId

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, minHeight: 40 }}>
        <Typography variant="h6">{isEdit ? 'Редактирование категории' : 'Создание категории'}</Typography>
      </Box>
      {!!isFetching && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!isFetching && (
        <QuestCategoryEditForm
          defaultData={data?.data}
          isEdit={isEdit}
          onSubmit={handleSubmit}
          isProcess={isCreateLoading || isUpdateLoading || isDeleteLoading}
          onDelete={handleDelete}
        />
      )}
    </Container>
  )
}

export { QuestCategoryEdit }
