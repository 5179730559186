import { QuestModel } from 'shared/models'
import { fakeCategories } from './fake-categories'
import { fakeAddresses } from './fake-addresses'
import { fakeServices } from './fake-services'

const fakeQuests: QuestModel[] = [
  {
    id: 7,
    name: 'Пятница 13',
    addressId: fakeAddresses[0].id,
    ageRating: 14,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description:
      'Бессмертный маньяк Джейсон Вурхиз вернулся! Вы с друзьями отправились отдыхать в лес вблизи заброшенного лагеря "Хрустальное озеро", где когда-то обитал убийа-психопат. В разгар веселья один из вас пропадает. Отправившись на его поиски, вы натыкаетесь на старый дом, пробравшись в который оказываетесь в смертельное ловушке.\n\nУ вас будет только один час, чтобы выбраться...\n\nТоропитесь!',
    headerImageUrl: '',
    htmlDescription: 'Бессмертный маньяк Джейсон Вурхиз вернулся!',
    htmlKeywords: 'Квест Сайлент Хилл, квест с актером, квесты ужастики',
    htmlTitle: 'InsideQuest: Пятница 13',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id],
    gallery: [
      'https://insidequest.ru/upload/5f0731fb49038.jpg',
      'https://insidequest.ru/upload/5d597104cd949.jpg',
      'https://insidequest.ru/upload/5d611d628638f.jpg',
      'https://insidequest.ru/upload/5d59766a59dd8.jpg',
      'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      'https://images.unsplash.com/photo-1522770179533-24471fcdba45'
    ]
  },
  {
    id: 9,
    name: 'Гарри Поттер и Тайны Хогвартса',
    addressId: fakeAddresses[0].id,
    ageRating: 12,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description:
      'Тысячи лет меч Годрика Гриффиндора хранился в Зале Славы Хогвартса. И вот сегодня, когда он понадобился, его украли! Это немыслимо! Именно вы, истинные гриффиндорцы должнs помочь отыскать этот могучий артефакт и вернуть его в Зал Славы. Старайтесь никому не попадаться на глаза! И поторопитесь! Министр магии приедет в Хогвартс через час, и если он не обнаружит меч, Дамблдора отправят в Азкабан!',
    headerImageUrl: '',
    htmlDescription: 'Тысячи лет меч Годрика Гриффиндора хранился в Зале Славы Хогвартса.',
    htmlKeywords: 'квест гарри поттер, квест для детей',
    htmlTitle: 'InsideQuest: Гарри Поттер и Тайны Хогвартса',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 10,
    name: 'Алиса в стране чудес',
    addressId: fakeAddresses[2].id,
    ageRating: 6,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description:
      'Темные времена настали в стране чудес. Алиса не появлялась тут уже десятки лет. С тех пор все изменилось. Красная королева вырастила нового Бармаглота и захватила власть. А где белая королева? Никто и не помнит уже. Обитатели в стране заколдованы. Шляпник снова сошел с ума.',
    headerImageUrl: '',
    htmlDescription: 'Темные времена настали в стране чудес. Алиса не появлялась тут уже десятки лет.',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Алиса в стране чудес',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 22,
    name: 'Пираты Карибского Моря: Ацтекское золото',
    addressId: fakeAddresses[2].id,
    ageRating: 10,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description:
      'В ваших руках ацтекское золото. То самое, что индейцы принесли Кортесу как выкуп, в надежде положить конец расправам. Но алчность Кортеса была неутолима, и тогда языческие боги придумали страшное проклятие: любой смертный кто возьмёт хоть одну золотую бляшку обречён на вечные муки!',
    headerImageUrl: '',
    htmlDescription: 'В ваших руках ацтекское золото.',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Пираты Карибского Моря - Ацтекское золото',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 26,
    name: 'Ограбление особняка Аддамсов',
    addressId: fakeAddresses[2].id,
    ageRating: 14,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Ограбление особняка Аддамсов',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 27,
    name: 'Кровавый цирк',
    addressId: fakeAddresses[2].id,
    ageRating: 16,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Кровавый цирк',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 29,
    name: 'Шерлок Холмс',
    addressId: fakeAddresses[2].id,
    ageRating: 12,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description:
      'Полицейский участок заминирован, а никто об этом даже не знает? Масштабное преступление... И именно вам предстоит примерить на себя образ Величайшего Сыщика и остановить Мориарти.',
    headerImageUrl: '',
    htmlDescription: 'Полицейский участок заминирован, а никто об этом даже не знает? Масштабное преступление...',
    htmlKeywords: 'квест, шерлок холмс, сыщик, мориарти, величайшее сыщик',
    htmlTitle: 'InsideQuest: Шерлок Холмс',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 31,
    name: 'Побег из тюрьмы',
    addressId: fakeAddresses[0].id,
    ageRating: 14,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Побег из тюрьмы',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 32,
    name: 'Гробница Фараона',
    addressId: fakeAddresses[2].id,
    ageRating: 14,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Гробница Фараона',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 33,
    name: 'Звездные войны',
    addressId: fakeAddresses[2].id,
    ageRating: 10,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Звездные войны',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 36,
    name: 'Ночной кошмар',
    addressId: fakeAddresses[2].id,
    ageRating: 16,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Ночной кошмар',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 37,
    name: 'Сайлент Хилл',
    addressId: fakeAddresses[2].id,
    ageRating: 16,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Сайлент Хилл',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 42,
    name: 'Секретная лаборатория Грю',
    addressId: fakeAddresses[2].id,
    ageRating: 12,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Секретная лаборатория Грю',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 43,
    name: 'Летучий голландец',
    addressId: fakeAddresses[2].id,
    ageRating: 10,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Летучий голландец',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 44,
    name: 'Псарня',
    addressId: fakeAddresses[2].id,
    ageRating: 14,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Псарня',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 47,
    name: 'Зомби-шоу',
    addressId: fakeAddresses[2].id,
    ageRating: 14,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Зомби-шоу',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 48,
    name: 'Морг',
    addressId: fakeAddresses[2].id,
    ageRating: 16,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Морг',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 49,
    name: 'Нора Хоббита',
    addressId: fakeAddresses[2].id,
    ageRating: 12,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Нора Хоббита',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  },
  {
    id: 50,
    name: 'Ведьма',
    addressId: fakeAddresses[2].id,
    ageRating: 16,
    categories: [fakeCategories[0].id, fakeCategories[2].id],
    complexity: 2,
    description: '',
    headerImageUrl: '',
    htmlDescription: '',
    htmlKeywords: '',
    htmlTitle: 'InsideQuest: Ведьма',
    previewImageUrl: '',
    mainServiceId: fakeServices[0].id,
    additionalServiceIds: [fakeServices[3].id]
  }
]

export { fakeQuests }
