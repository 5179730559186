import * as Yup from 'yup'
import { ScheduleFormData } from './types'

function getValidations() {
  return Yup.object().shape({
    name: Yup.string().required('Поле обязательно для заполнения'),
    duration: Yup.number().nullable().when('reservationIsAvailable', {
      is: true,
      then: (schema) =>
        schema.required('Значение должно быть обязательным').min(1, 'Длительность не может быть меньше 1 минуты')
    })
  }) as Yup.ObjectSchema<NonNullable<ScheduleFormData>, Yup.AnyObject>
}

export { getValidations }
