import { useMemo } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { useAppSelector } from 'store'
import { getIsSettingsModeSelector, getUserLoggedInSelector, getUserProfileSelector } from 'store/slices/profile-slice'
import { LoginPage } from './login'
import { MainLayout } from 'features/main-layout'
import { QuestList } from './quest/quest-list'
import StoreUser from 'features/store-user'
import * as nav from './nav'
import { UserList } from './users/user-list'
import { QuestCategoryList } from './quest-category/quest-category-list'
import { AddressList } from './address/address-list'
import { ServiceList } from './service/service-list'
import { UserEdit } from './users/user-edit'
import { ProfileEdit } from './profile/profile-edit'
import { AddressEdit } from './address/address-edit'
import { QuestCategoryEdit } from './quest-category/quest-category-edit'
import { ServiceEdit } from './service/service-edit'
import { QuestEdit } from './quest/quest-edit'
import { OrderList } from './orders/order-list'
import { OrderView } from './orders/order-view'
import { PageList } from './pages/page-list'
import { PageEdit } from './pages/page-edit'
import { MainSettingsPage } from './main-settings/main-settings'
import { ReservationSchedulerPage } from './reservation-scheduler/reservation-scheduler'
import { ClientList } from './clients/client-list'
import { QuestOrders } from './quest/quest-orders'

function Router() {
  const loggedIn = useAppSelector(getUserLoggedInSelector)
  const profile = useAppSelector(getUserProfileSelector)
  const isSettingsMode = useAppSelector(getIsSettingsModeSelector)

  const isAdmin = profile?.profile === 'Admin'

  const unloggedInRoutes = useMemo(
    () => [
      {
        path: nav.login(),
        element: <LoginPage />
      },
      {
        path: '*',
        element: <Navigate to={nav.login()} />
      }
    ],
    []
  )

  const settingsModeRoutes = useMemo(
    () => [
      {
        path: nav.profile(),
        element: <ProfileEdit />
      },
      {
        path: nav.questList(),
        element: <QuestList />
      },
      {
        path: nav.questOrders(),
        element: <QuestOrders />
      },
      {
        path: nav.questCreate(),
        element: <QuestEdit />
      },
      {
        path: nav.questEdit(':questId'),
        element: <QuestEdit />
      },
      {
        path: nav.questCategoryList(),
        element: <QuestCategoryList />
      },
      {
        path: nav.questCategoryCreate(),
        element: <QuestCategoryEdit />
      },
      {
        path: nav.questCategoryEdit(':categoryId'),
        element: <QuestCategoryEdit />
      },
      {
        path: nav.addressList(),
        element: <AddressList />
      },
      {
        path: nav.addressCreate(),
        element: <AddressEdit />
      },
      {
        path: nav.addressEdit(':addressId'),
        element: <AddressEdit />
      },
      {
        path: nav.userList(),
        element: <UserList />
      },
      {
        path: nav.userCreate(),
        element: <UserEdit />
      },
      {
        path: nav.userEdit(':userId'),
        element: <UserEdit />
      },
      {
        path: nav.serviceList(),
        element: <ServiceList />
      },
      {
        path: nav.serviceCreate(),
        element: <ServiceEdit />
      },
      {
        path: nav.serviceEdit(':serviceId'),
        element: <ServiceEdit />
      },
      {
        path: nav.pageList(),
        element: <PageList />
      },
      {
        path: nav.pageCreate(),
        element: <PageEdit />
      },
      {
        path: nav.pageEdit(':pageId'),
        element: <PageEdit />
      },
      {
        path: nav.mainSettings(),
        element: <MainSettingsPage />
      },
      {
        path: '',
        element: <Navigate to={nav.questList()} />
      }
    ],
    []
  )

  const crmRoutes = useMemo(
    () => [
      {
        path: nav.profile(),
        element: <ProfileEdit />
      },
      {
        path: nav.orderList(),
        element: <OrderList key="orders" />
      },
      {
        path: nav.cancelledOrderList(),
        element: <OrderList key="cancelled" cancelledOnly />
      },
      {
        path: nav.orderView(':orderId'),
        element: <OrderView />
      },
      {
        path: nav.reservationScheduler(),
        element: <ReservationSchedulerPage key="scheduler" />
      },
      {
        path: nav.reservationWeekdaysScheduler(),
        element: <ReservationSchedulerPage key="weekdays-scheduler" weekdaysMode />
      },
      {
        path: nav.clientList(),
        element: <ClientList />
      },
      {
        path: '',
        element: <Navigate to={nav.orderList()} />
      }
    ],
    []
  )

  const loggedInRoutes = useMemo(
    () => [
      {
        path: '/',
        element: <MainLayout />,
        children: isSettingsMode && isAdmin ? settingsModeRoutes : crmRoutes
      },
      {
        path: '*',
        element: <Navigate to={'/'} />
      }
    ],
    [isSettingsMode, profile]
  )

  const router = useMemo(
    () => createBrowserRouter(loggedIn ? loggedInRoutes : unloggedInRoutes),
    [loggedIn, isSettingsMode, profile]
  )

  return <RouterProvider router={router} />
}

export default StoreUser(Router)
