import { ImageGallery } from 'features/image-gallery/image-gallery'
import { GalleryTabProps, ImageItem } from 'features/image-gallery/types'
import { useState } from 'react'

function GalleryTab({ images, onChange }: GalleryTabProps) {
  const [galleryImages, setGalleryImages] = useState<ImageItem[]>(
    images.map((image, i) => ({ id: `${Date.now()}-${Math.floor(Math.random() * 1000)}-${i}`, url: image })) || []
  )

  function handleImageChange(value: ImageItem[]) {
    setGalleryImages(value)
    onChange(value.map((item) => item.url))
  }

  return <ImageGallery images={galleryImages} onChange={handleImageChange} />
}

export { GalleryTab }
