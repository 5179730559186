import { OrderJournalAction, OrderJournalActionInitiator } from 'shared/models'

const jounalActionText: Record<OrderJournalAction, string> = {
  OrderCreation: 'Создание заказа',
  OrderUpdate: 'Обновление заказа',
  OrderCancellation: 'Отмена заказа',
  OrderStatusChanged: 'Изменение статуса заказа',
  OrderStaffCommentChanged: 'Изменение комментария',
  PaymentRequested: 'Оплата запрошена',
  PaymentConfirmed: 'Оплата подтверждена',
  PaymentRejected: 'Оплата отклонена'
}

const jounalInitiatorText: Record<OrderJournalActionInitiator, string> = {
  Auto: 'Автоматически',
  Backoffice: 'Бэк-офис',
  Site: 'Сайт',
  MirKvestov: 'Мир Квестов',
  TopKvestov: 'Топ Квестов',
  KvestBattle: 'Квест Батл',
  KvestInfo: 'Квестинфо'
}

export { jounalActionText, jounalInitiatorText }
