import { useEffect, useState } from 'react'
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers'
import { TextField, TextFieldProps } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { TimePickerProps } from './types'

function TimePicker({
  disabled,
  fullWidth,
  id,
  label,
  onChange,
  style,
  value,
  autoComplete,
  ...props
}: TimePickerProps) {
  const [val, setValue] = useState<Dayjs | null>(null)

  useEffect(() => {
    if (value) {
      setValue(dayjs(value))
    }
  }, [])

  function handleChange(date: dayjs.Dayjs | null) {
    setValue(date)

    const dateU = date?.toDate()

    onChange?.(dateU ? dateU.getTime() : null)
  }

  function renderInput(params: TextFieldProps) {
    return (
      <TextField
        {...params}
        autoComplete={autoComplete}
        style={style}
        id={id}
        fullWidth={fullWidth}
        InputLabelProps={{ shrink: true }}
        error={props.error}
        variant='standard'
      />
    )
  }

  return (
    <MuiTimePicker
      renderInput={renderInput}
      ampm={false}
      disabled={disabled}
      label={
        <span style={{ display: 'flex' }}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{label}</div>
        </span>
      }
      value={val}
      onChange={handleChange}
    />
  )
}

export { TimePicker }
