import { ProfileModel, UserModel } from 'shared/models'

const fakeUsers: UserModel[] = [
  {
    id: 1,
    firstName: 'Сергей',
    lastName: 'Иванов',
    email: 'admin@insidequest.ru',
    profile: 'Admin'
  },
  {
    id: 2,
    firstName: 'Павел',
    lastName: 'Голубев',
    email: 'pavel.golub@insidequest.ru',
    profile: 'Manager'
  },
  {
    id: 3,
    firstName: 'Администратор',
    lastName: 'Офис 4',
    email: 'Office4@insidequest.ru',
    profile: 'Manager'
  },
  {
    id: 4,
    firstName: 'Администратор',
    lastName: 'Офис 1',
    email: 'office1@insidequest.ru',
    profile: 'Manager'
  }
]

const fakeUserProfile: ProfileModel = {
  id: 4,
  firstName: 'Сергей',
  lastName: 'Иванов',
  email: 'admin@insidequest.ru',
  profile: 'Admin'
}

export { fakeUserProfile, fakeUsers }
