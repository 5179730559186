import { QuestCreateModel, QuestModel } from 'shared/models'
import * as Yup from 'yup'

function getValidations() {
  return Yup.object().shape({
    name: Yup.string().required('Поле обязательно для заполнения'),
    addressId: Yup.number().required('Поле обязательно для заполнения'),
    mainServiceId: Yup.number().required('Поле обязательно для заполнения'),
    description: Yup.string(),
    ageRating: Yup.number().nullable()
  }) as Yup.ObjectSchema<NonNullable<QuestModel | QuestCreateModel>, Yup.AnyObject>
}

export { getValidations }
