import { ServiceModel } from 'shared/models'
import { fakeAddresses } from './fake-addresses'

const fakeServices: ServiceModel[] = [
  {
    id: 7,
    addressId: fakeAddresses[0].id,
    name: 'Квест Пятница 13',
    withReservation: true
  },
  {
    id: 9,
    addressId: fakeAddresses[1].id,
    name: 'Квест Гарри Поттер и Тайны Хогвартса',
    withReservation: true
  },
  {
    id: 10,
    addressId: fakeAddresses[0].id,
    name: 'Квест Алиса в стране чудес',
    withReservation: true
  },
  {
    id: 11,
    addressId: fakeAddresses[0].id,
    name: 'Фотограф',
    withReservation: false
  },
  {
    id: 12,
    addressId: fakeAddresses[0].id,
    name: 'Аниматор',
    withReservation: false
  },
  {
    id: 22,
    addressId: fakeAddresses[0].id,
    name: 'Квест Пираты Карибского Моря: Ацтекское золото',
    withReservation: true
  },
  {
    id: 23,
    addressId: fakeAddresses[0].id,
    name: 'Красная комната',
    withReservation: true
  },
  {
    id: 26,
    addressId: fakeAddresses[0].id,
    name: 'Квест Ограбление особняка Аддамсов',
    withReservation: true
  },
  {
    id: 27,
    addressId: fakeAddresses[0].id,
    name: 'Квест Кровавый цирк',
    withReservation: true
  },
  {
    id: 28,
    addressId: fakeAddresses[0].id,
    name: 'Комната для праздников',
    withReservation: true
  },
  {
    id: 29,
    addressId: fakeAddresses[0].id,
    name: 'Шерлок Холмс',
    withReservation: true
  },
  {
    id: 30,
    addressId: fakeAddresses[0].id,
    name: 'Зеленая комната',
    withReservation: true
  },
  {
    id: 31,
    addressId: fakeAddresses[0].id,
    name: 'Квест Побег из тюрьмы',
    withReservation: true
  },
  {
    id: 32,
    addressId: fakeAddresses[0].id,
    name: 'Квест Гробница Фараона',
    withReservation: true
  },
  {
    id: 33,
    addressId: fakeAddresses[0].id,
    name: 'Квест Звездные войны',
    withReservation: true
  },
  {
    id: 35,
    addressId: fakeAddresses[0].id,
    name: 'Серебряная комната',
    withReservation: true
  },
  {
    id: 36,
    addressId: fakeAddresses[0].id,
    name: 'Квест Ночной кошмар',
    withReservation: true
  },
  {
    id: 37,
    addressId: fakeAddresses[0].id,
    name: 'Квест Сайлент Хилл',
    withReservation: true
  },
  {
    id: 38,
    addressId: fakeAddresses[0].id,
    name: 'Дом енотов',
    withReservation: true
  },
  {
    id: 42,
    addressId: fakeAddresses[0].id,
    name: 'Квест Секретная лаборатория Грю',
    withReservation: true
  },
  {
    id: 43,
    addressId: fakeAddresses[0].id,
    name: 'Квест Летучий голландец',
    withReservation: true
  },
  {
    id: 44,
    addressId: fakeAddresses[0].id,
    name: 'Квест Псарня',
    withReservation: true
  },
  {
    id: 45,
    addressId: fakeAddresses[0].id,
    name: 'Банкетный зал Хогвартс',
    withReservation: true
  },
  {
    id: 46,
    addressId: fakeAddresses[0].id,
    name: 'Золотая комната',
    withReservation: true
  },
  {
    id: 47,
    addressId: fakeAddresses[0].id,
    name: 'Перфоманс Зомби-шоу',
    withReservation: true
  },
  {
    id: 48,
    addressId: fakeAddresses[0].id,
    name: 'Квест Морг',
    withReservation: true
  },
  {
    id: 49,
    addressId: fakeAddresses[0].id,
    name: 'Квест Нора Хоббита',
    withReservation: true
  },
  {
    id: 50,
    addressId: fakeAddresses[0].id,
    name: 'Квест Ведьма',
    withReservation: true
  }
]

export { fakeServices }
