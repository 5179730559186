import { adminService } from 'shared/api'

const storageKey = 'isAuth'

function login(email: string, password: string) {
  return adminService.user.login(email, password).then((res) => {
    setAuthorize(true)

    return res
  })
}

function setAuthorize(value: boolean) {
  localStorage.setItem(storageKey, value ? 'true' : 'false')
}

function logout() {
  setAuthorize(false)
  return adminService.user.logout()
}

function isAuthorized() {
  return localStorage.getItem(storageKey) === 'true'
}

export { login, logout, isAuthorized, setAuthorize }
