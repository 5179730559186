import { useEffect, useRef, useState } from 'react'
import css from './date-picker.module.scss'
import { DatePicker as MuiDatePicker, PickersDayProps, PickersDay } from '@mui/x-date-pickers'
import isBetween from 'dayjs/plugin/isBetween'
import { Box, IconButton, TextField } from '@mui/material'
import { CustomPickersDay } from './components/custom-day'
import dayjs, { Dayjs } from 'dayjs'
import { DatePickerProps, DateSelect } from './types'
import { isPositiveNumber } from 'shared/utils/string-test'
import CalendarIcon from '@mui/icons-material/Event'
import ClearIcon from '@mui/icons-material/Close'

function DatePicker<W>(props: DatePickerProps<W> & { useLocalTimeZone?: boolean }) {
  const {
    disablePast,
    disableFuture,
    style,
    id,
    attention,
    shouldDisableDate,
    placeholder,
    onChange,
    value: initValue,
    disabled,
    label,
    useLocalTimeZone
  } = props

  const [value, setValue] = useState<Dayjs | null>(null)
  const currValue = useRef<Date | null>(null)

  const [open, setOpen] = useState(false)

  function getValue() {
    if (!initValue) {
      return null
    }

    const isNumberDate = typeof initValue === 'string' && isPositiveNumber(initValue)
    const convertedValue = isNumberDate ? Number(initValue) : initValue

    const localDateOffset = new Date(convertedValue || '').getTimezoneOffset() * 60000
    const v = initValue ? new Date(convertedValue).getTime() : new Date().getTime()
    return new Date(useLocalTimeZone ? v : v + localDateOffset)
  }

  useEffect(() => {
    const convertedValue = getValue()

    if (currValue.current !== convertedValue) {
      currValue.current = convertedValue
      const val = convertedValue ? dayjs(convertedValue) : null
      setValue(val)
    }
  }, [initValue])

  function handleBlur(ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) {
    const value = getValue()
    setValue(value ? dayjs(value) : null)
    props.onBlur?.(ev)
  }

  function handleChange(date: dayjs.Dayjs | null) {
    setValue(date)

    if (!date?.toDate() || !date?.isValid()) {
      if (props.allowClear) {
        onChange(undefined as any)
      }
      return
    }

    const rawDate = date.toDate()

    const localDateOffset = new Date(rawDate || '').getTimezoneOffset() * 60000
    const localOffset = localDateOffset
    const dateUnOffset = new Date(rawDate.getTime() - localOffset)

    const result: DateSelect<undefined> = Date.UTC(
      dateUnOffset.getUTCFullYear(),
      dateUnOffset.getUTCMonth(),
      dateUnOffset.getUTCDate()
    )

    const localResult = new Date(rawDate.getTime()).setHours(0, 0, 0, 0)

    const startWeek = date.startOf('week').toDate()
    const endWeek = date.endOf('week').toDate()

    const weekResult: DateSelect<true> = {
      startDate: useLocalTimeZone ? startWeek.getTime() : startWeek.getTime() - localOffset,
      endDate: useLocalTimeZone ? endWeek.getTime() : endWeek.getTime() - localOffset,
      selectedDate: useLocalTimeZone ? localResult : result
    }

    const cahngeResult = props.weekMode === undefined ? weekResult.selectedDate : weekResult

    onChange(cahngeResult as DateSelect<W>)
  }

  function renderDay(date: Dayjs, selectedDate: Dayjs, pickersDayProps: PickersDayProps<Dayjs>): JSX.Element {
    if (!selectedDate || !date) {
      return <PickersDay {...pickersDayProps} />
    }

    if (!props.weekMode) {
      return <PickersDay {...pickersDayProps} />
    }

    const start = selectedDate.startOf('week')
    const end = selectedDate.endOf('week')

    dayjs.extend(isBetween)

    const dayIsBetween = date.isBetween(start, end, 'day', '[]')
    const isFirstDay = date.isSame(start, 'date')
    const isLastDay = date.isSame(end, 'date')

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    )
  }

  const labelView = (label || attention) && (
    <div style={{ display: 'flex' }}>
      {label} {attention && <div className={css.attention} />}
    </div>
  )

  function handleClear() {
    handleChange(null)
  }

  return (
    <MuiDatePicker
      disabled={disabled}
      InputProps={{ id: id }}
      label={labelView}
      inputFormat="DD.MM.YYYY"
      open={open}
      onClose={() => setOpen(false)}
      renderDay={(day, selectedDates, pickersDayProps) => {
        if (!day?.toDate) {
          return <></>
        }

        if (!selectedDates[0]) {
          return renderDay(day, dayjs(), pickersDayProps)
        }

        return renderDay(day, selectedDates[0], pickersDayProps)
      }}
      shouldDisableDate={(day) => {
        if (!day?.toDate) {
          return false
        }

        return shouldDisableDate?.(day?.toDate()) || false
      }}
      views={['year', 'month', 'day']}
      disablePast={disablePast}
      disableFuture={disableFuture}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          style={style}
          onBlur={handleBlur}
          InputLabelProps={{ shrink: props.shrink }}
          inputProps={{ ...params.inputProps, placeholder: placeholder || params.placeholder }}
          helperText={props.message}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {(!props.allowClear || !value) && (
                  <IconButton size="small" onClick={() => setOpen(true)}>
                    <CalendarIcon fontSize="small" />
                  </IconButton>
                )}
                {props.allowClear && !!value && (
                  <IconButton size="small" onClick={handleClear} disabled={!value}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            )
          }}
          FormHelperTextProps={{ style: { color: props.messageColor || '#00000042' } }}
        />
      )}
      onChange={handleChange}
    />
  )
}

export { DatePicker }
