import { AddressModel } from 'shared/models'

const fakeAddresses: AddressModel[] = [
  {
    id: 1,
    address: 'ул.Розы Люксембург, 77а',
    city: 'Екатеринбург',
    latitude: '56.822040',
    longitude: '60.616276',
    phone: '+7 (343) 213-09-99'
  },
  {
    id: 2,
    address: 'ул. Степана Разина, 95',
    city: 'Екатеринбург',
    latitude: '',
    longitude: '',
    phone: '+7 (343) 213-09-99'
  },
  {
    id: 3,
    address: 'ул. Хохрякова, 74',
    city: 'Екатеринбург',
    latitude: '',
    longitude: '',
    phone: '+7 (343) 213-09-99'
  },
  {
    id: 4,
    address: 'ул. Щорса, 20',
    city: 'Екатеринбург',
    latitude: '',
    longitude: '',
    phone: '+7 (343) 213-09-99'
  },
  {
    id: 5,
    address: 'ул. Чапаева, 72а',
    city: 'Екатеринбург',
    latitude: '',
    longitude: '',
    phone: '+7 (343) 213-09-99'
  }
]

export { fakeAddresses }
