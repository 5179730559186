import { http, HttpResponse, delay } from 'msw'
import { fakeOrders, fakeOrderView, fakeOrderEditForm, fakeSchedulerOrders } from '../fake-data/fake-orders'
import { fakeTimeSots } from '../fake-data/fake-timeslots'
import { fakePriceListForOrder } from '../fake-data/fake-price-list'
import { fakeOrderJournal } from '../fake-data/fake-order-journal'

export const orders = [
  http.get('/api/orders', async () => {
    await delay(900)

    return HttpResponse.json({ list: fakeOrders, total: 1180, page: 1, pageCount: 12 })
  }),

  http.get('/api/orders/getServicePrices', async () => {
    await delay(300)

    return HttpResponse.json(fakePriceListForOrder)
  }),

  http.get('/api/orders/getTimeslots', async () => {
    await delay(300)

    return HttpResponse.json(fakeTimeSots)
  }),

  http.get('/api/orders/getSchedulerOrders', async () => {
    await delay(300)

    return HttpResponse.json(fakeSchedulerOrders)
  }),

  http.get('/api/orders/:id', async ({ params }) => {
    await delay(300)

    const { id } = params

    const foundOrder = id ? fakeOrders.find((order) => order.id === id) : undefined

    if (!foundOrder) {
      return HttpResponse.json(undefined, { status: 404 })
    } else {
      return HttpResponse.json(fakeOrderView)
    }
  }),

  http.get('/api/orders/:id/editForm', async ({ params }) => {
    await delay(300)

    const { id } = params

    const foundOrder = id ? fakeOrders.find((order) => order.id === id) : undefined

    if (!foundOrder) {
      return HttpResponse.json(undefined, { status: 404 })
    } else {
      return HttpResponse.json(fakeOrderEditForm)
    }
  }),

  http.post('/api/orders', async () => {
    await delay(300)

    return HttpResponse.json({ id: '1231' })
  }),

  http.put('/api/orders/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.delete('/api/orders/:id', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.patch('/api/orders/:id/editStaffComment', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.patch('/api/orders/:id/editStatus', async () => {
    await delay(300)

    return HttpResponse.json(undefined, { status: 204 })
  }),

  http.get('/api/orders/:id/getPaymentLink', async () => {
    await delay(300)

    return HttpResponse.json({
      paymentLink:
        'https://pay.alfabank.ru/payment/merchants/ecom2/payment_ru.html?mdOrder=4db14ca5-ec33-7632-b778-b1ba04440ea3'
    })
  }),

  http.get('/api/orders/:id/journal', async () => {
    await delay(1200)

    return HttpResponse.json(fakeOrderJournal)
  })
]
