import { Theme } from '@mui/material'
import styled from '@emotion/styled'

const MoreItemsIndicator = styled.div`
  cursor: pointer;
  width: 21px;
  height: 21px;
  position: absolute;
  right: 2px;
  top: -0.75rem;
  background: #db214d;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    z-index: 9999;
  }
`

const CurrentTimeIndicator = styled.div`
  height: 1px;
  width: 100%;
  position: absolute;
  border-bottom: dashed 1px #000;
  z-index: 999;
`

const CalendarFullContainer = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
`

const CalendarContainer = styled.div`
  width: 100%;
  overflow: hidden;
`

const CalendarHeaderContainer = styled.div<{ theme?: Theme }>`
  display: grid;
  grid-template-columns: 60px 1fr;
  background: ${(props) => props.theme.palette.background.paper || '#fff'};
  border-bottom: solid 1px ${(props) => props.theme.palette.divider || '#ededed'};
  position: sticky;
  top: 0;
  z-index: 1001;
`

const CalendarHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, minmax(50px, 1fr));
  grid-template-rows: 40px;
  grid-auto-rows: 120px;
  overflow: auto;
  align-items: center;
  text-align: center;
  font-weight: 400;
  z-index: 999;
`
const CalendarContent = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, minmax(50px, 1fr));
  text-align: center;
  border-bottom: 1px solid rgba(166, 168, 179, 0.12);
`
const CalendarColumn = styled.div`
  display: grid;
  width: 100%;
  grid-auto-rows: 60px;
  position: relative;
`
const CalendarDataEl = styled.div<{ theme?: Theme }>`
  border-left: solid ${(props) => props.theme.palette.divider || '#ededed'} 1px;
  border-bottom: solid ${(props) => props.theme.palette.divider || '#ededed'} 1px;
  cursor: pointer;

  &:hover {
    background: #0000000d;
  }
`

const CalendarLeftHeader = styled.div<{ theme?: Theme }>`
  position: sticky;
  left: 0;
  background: ${(props) => props.theme.palette.background.paper || '#fff'};
  width: 60px;
  height: 40px;
  z-index: 1000;
`

const CalendarLeftEl = styled.div`
  text-align: right;
  padding-right: 0.5rem;
  font-weight: 300;
  font-size: 14px;
`

const CalendarLeftColumn = styled.div<{ theme?: Theme }>`
  display: grid;
  grid-auto-rows: 60px;
  background: ${(props) => props.theme.palette.background.paper || '#fff'};
  position: sticky;
  left: 0;
  z-index: 1000;
`

const CalendarDataAppointment = styled.div`
  cursor: pointer;
  position: absolute;
  width: -webkit-fill-available;
  border: solid 1px #0003;
  background: rgb(230, 230, 230);
  margin-left: -1px;
  text-align: start;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    min-height: 50px;
    z-index: 999;
  }
`

export {
  MoreItemsIndicator,
  CurrentTimeIndicator,
  CalendarFullContainer,
  CalendarContainer,
  CalendarHeaderContainer,
  CalendarHeader,
  CalendarColumn,
  CalendarContent,
  CalendarDataAppointment,
  CalendarDataEl,
  CalendarLeftColumn,
  CalendarLeftEl,
  CalendarLeftHeader
}
