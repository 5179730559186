import { CategoryModel } from 'shared/models'

const fakeCategories: CategoryModel[] = [
  {
    id: 1,
    name: 'Страшные'
  },
  {
    id: 2,
    name: 'Сложные'
  },
  {
    id: 3,
    name: 'С актерами'
  },
  {
    id: 4,
    name: 'Акшен'
  },
  {
    id: 5,
    name: 'Для детей'
  },
  {
    id: 6,
    name: 'Антуражные'
  },
  {
    id: 7,
    name: 'Для начинающих'
  }
]

export { fakeCategories }
