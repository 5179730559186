import { http, HttpResponse, delay } from 'msw'
import { fakeCustomers } from '../fake-data/fake-customers'

export const customers = [
  http.get('/api/customers', async () => {
    await delay(900)

    return HttpResponse.json(fakeCustomers)
  })
]
