import {
  Box,
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import TimeIcon from '@mui/icons-material/AccessTime'
import DateIcon from '@mui/icons-material/Event'
import { ServicePriceModel } from 'shared/models'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { useRef } from 'react'
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import type { Identifier } from 'dnd-core'

type DragItem = {
  id: number
}

function ServicePriceItem({
  price,
  onEdit,
  onSortChange,
  onDragEnd
}: {
  price: ServicePriceModel
  onEdit: () => void
  onSortChange: (dragId: number, hoverId: number) => void
  onDragEnd: () => void
}) {
  const dragRef = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'additionalServiceList',
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() }
    },
    hover(item: DragItem) {
      if (!dragRef.current) {
        return
      }

      const dragId = item.id
      const hoverId = price.id

      if (dragId === hoverId) {
        return
      }

      onSortChange(dragId, hoverId)
    },
    drop: () => {
      onDragEnd()
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'additionalServiceList',
    item: () => ({ id: price.id }),
    collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() })
  })

  preview(drop(dragRef))

  return (
    <>
      <Box paddingBottom="1rem" position="relative" sx={{ opacity: isDragging ? 0.2 : 1 }} ref={dragRef}>
        <Box position="absolute" bottom="1rem" right="1rem">
          <IconButton onClick={onEdit}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
        <ListItem>
          <ListItemText
            primaryTypographyProps={{ fontWeight: 500 }}
            primary={price.name}
            secondary={price.prices
              .map((p) =>
                [p.numberOfPersons ? `${p.numberOfPersons} чел.` : undefined, `${p.price} руб.`]
                  .filter(Boolean)
                  .join(' - ')
              )
              .join(', ')}
          />
          <ListItemSecondaryAction>
            <Box padding="0 0.25rem" sx={{ cursor: 'grab' }} ref={drag} data-handler-id={handlerId}>
              <DragIndicatorIcon fontSize="small" color="disabled" />
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
        <Box display="flex" flexDirection="column" gap="0.5rem" padding="0 1rem">
          {price.schedule?.map((schedule, i) => (
            <Box key={`schedule-${i}`} display="flex" flexDirection="column" gap="0.25rem" padding="0.25rem 0">
              <Box display="flex" gap="0.25rem">
                {schedule.date ? (
                  <Box display="flex" gap="0.5rem">
                    <DateIcon fontSize="small" />
                    <Typography variant="body2">
                      {Intl.DateTimeFormat('ru', { timeZone: 'UTC' }).format(schedule.date)}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" gap="0.25rem" marginBottom="0.25rem">
                    {schedule.weekdays
                      ?.map((w) => ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'][w])
                      .map((w, wi) => (
                        <Chip size="small" color="primary" label={w} key={`schedule-${i}-weekday-${wi}`} />
                      ))}
                  </Box>
                )}
              </Box>
              <Box display="flex" gap="0.5rem">
                <TimeIcon fontSize="small" />
                <Typography variant="body2">
                  {[
                    Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(schedule.startTime),
                    Intl.DateTimeFormat('ru', { hour: '2-digit', minute: '2-digit' }).format(schedule.endTime)
                  ].join(' - ')}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider />
    </>
  )
}

export { ServicePriceItem }
