function pluralizationRu(value: number, [one, two, five]: string[]) {
  const n = Math.abs(value) % 100

  const i = n % 10

  if (i === 1 && n !== 11) {
    return one
  }

  if (i >= 2 && i <= 4 && (n < 10 || n >= 20)) {
    return two
  }

  return five
}

export { pluralizationRu }
